import { RseStatus } from '@constant/rseStatus';
import { TYPE_RSE, TYPE_RELAY } from '@constant/rse';

// List of RSEs
export const ntfghJunctionRSEs = [
  {
    id: 'NTFGH_J1',
    name: 'Junction 1',
    description: 'Junction 1',
    hospital: 'NTFGH',
    type: 'junction',
    location: {
      longitude: 103.744189,
      latitude: 1.333668,
    },
    children: [
      {
        id: 'NTFGH_J1_RSE1',
        name: 'RSE 1',
        description: 'J1 RSE 1',
        type: TYPE_RELAY,
        status: 'online',
        location: {
          longitude: 103.74409,
          latitude: 1.333776,
        },
      },
      {
        id: 'NTFGH_J1_RSE2',
        name: 'RSE 2',
        description: 'J1 RSE 2',
        type: TYPE_RSE,
        status: 'online',
        location: {
          longitude: 103.744149,
          latitude: 1.333547,
        },
        directions: {
          1: [2],
          3: [0, 1],
        },
      },
    ],
  },
  {
    id: 'NTFGH_J2',
    name: 'Junction 2',
    description: 'Junction 2',
    hospital: 'NTFGH',
    type: 'junction',
    location: {
      longitude: 103.744796,
      latitude: 1.335066,
    },
    children: [
      {
        id: 'NTFGH_J2_RSE3',
        name: 'RSE 3',
        description: 'J2 RSE 3',
        type: TYPE_RSE,
        status: 'online',
        location: {
          longitude: 103.744796,
          latitude: 1.335066,
        },
        directions: {
          3: [1, 2],
        },
      },
    ],
  },
  {
    id: 'NTFGH_J3',
    name: 'Junction 3',
    description: 'Junction 3',
    hospital: 'NTFGH',
    type: 'junction',
    location: {
      longitude: 103.74764,
      latitude: 1.336543,
    },
    children: [
      {
        id: 'NTFGH_J3_RSE4',
        name: 'RSE 4',
        description: 'J3 RSE 4',
        type: TYPE_RSE,
        status: 'online',
        location: {
          longitude: 103.747754,
          latitude: 1.336577,
        },
        directions: {
          4: [1, 2],
        },
      },
      {
        id: 'NTFGH_J3_RSE5',
        name: 'RSE 5',
        description: 'J3 RSE 5',
        type: TYPE_RELAY,
        status: 'online',
        location: {
          longitude: 103.747514,
          latitude: 1.336585,
        },
      },
    ],
  },
  {
    id: 'NTFGH_J4',
    name: 'Junction 4',
    description: 'Junction 4',
    hospital: 'NTFGH',
    type: 'junction',
    location: {
      longitude: 103.746831,
      latitude: 1.331463,
    },
    children: [
      {
        id: 'NTFGH_J4_RSE6',
        name: 'RSE 6',
        description: 'J4 RSE 6',
        type: TYPE_RELAY,
        status: 'online',
        location: {
          longitude: 103.746763,
          latitude: 1.331281,
        },
      },
      {
        id: 'NTFGH_J4_RSE7',
        name: 'RSE 7',
        description: 'J4 RSE 7',
        type: TYPE_RSE,
        status: 'online',
        location: {
          longitude: 103.746981,
          latitude: 1.331258,
        },
        directions: {
          7: [1, 2],
        },
      },
    ],
  },
];

export const ntfghRSEs = [
  {
    id: 'NTFGH_J1_RSE1',
    name: 'J1 RSE 1',
    junctionId: 'NTFGH_J1',
    type: TYPE_RELAY,
    location: {
      longitude: 103.74409,
      latitude: 1.333776,
    },
    status: 'online',
    hospital: 'H9',
  },
  {
    id: 'NTFGH_J1_RSE2',
    name: 'J1 RSE 2',
    junctionId: 'NTFGH_J1',
    type: TYPE_RSE,
    location: {
      longitude: 103.744149,
      latitude: 1.333547,
    },
    status: 'online',
    hospital: 'H9',
    directions: {
      3: [1, 2],
    },
  },
  {
    id: 'NTFGH_J2_RSE3',
    name: 'J2 RSE 3',
    junctionId: 'NTFGH_J2',
    type: TYPE_RSE,
    location: {
      longitude: 103.744796,
      latitude: 1.335066,
    },
    status: 'online',
    hospital: 'H9',
    directions: {
      3: [1, 2],
    },
  },
  {
    id: 'NTFGH_J3_RSE4',
    name: 'J3 RSE 4',
    junctionId: 'NTFGH_J3',
    type: TYPE_RSE,
    location: {
      longitude: 103.747754,
      latitude: 1.336577,
    },
    status: 'online',
    hospital: 'H9',
    directions: {
      4: [1, 2],
    },
  },
  {
    id: 'NTFGH_J3_RSE5',
    name: 'J3 RSE 5',
    junctionId: 'NTFGH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.747514,
      latitude: 1.336585,
    },
    status: 'online',
    hospital: 'H9',
  },
  {
    id: 'NTFGH_J4_RSE6',
    name: 'J4 RSE 6',
    junctionId: 'NTFGH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.746763,
      latitude: 1.331281,
    },
    status: 'online',
    hospital: 'H9',
  },
  {
    id: 'NTFGH_J4_RSE7',
    name: 'J4 RSE 7',
    junctionId: 'NTFGH_J4',
    type: TYPE_RSE,
    location: {
      longitude: 103.746981,
      latitude: 1.331258,
    },
    status: 'online',
    hospital: 'H9',
    directions: {
      7: [1, 2],
    },
  },
];

export const ktphRSEs = [
  {
    id: 'KTPH_J1_RSE1',
    name: 'J1 RSE 1',
    junctionId: 'KTPH_J1',
    type: TYPE_RSE,
    location: {
      longitude: 103.835197,
      latitude: 1.424617,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      4: [1],
    },
  },
  {
    id: 'KTPH_J1_RSE2',
    name: 'J1 RSE 2',
    junctionId: 'KTPH_J1',
    type: TYPE_RELAY,
    location: {
      longitude: 103.835018,
      latitude: 1.424869,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J2_RSE3',
    name: 'J2 RSE 3',
    junctionId: 'KTPH_J2',
    type: TYPE_RSE,
    location: {
      longitude: 103.83488,
      latitude: 1.422808,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      0: [2],
      3: [1],
    },
  },
  {
    id: 'KTPH_J2_RSE4',
    name: 'J2 RSE 4',
    junctionId: 'KTPH_J2',
    type: TYPE_RELAY,
    location: {
      longitude: 103.835177,
      latitude: 1.423491,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J2_RSE5',
    name: 'J2 RSE 5',
    junctionId: 'KTPH_J2',
    type: TYPE_RELAY,
    location: {
      longitude: 103.83445,
      latitude: 1.423017,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J3_RSE6',
    name: 'J3 RSE 6',
    junctionId: 'KTPH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.833186,
      latitude: 1.41842,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J3_RSE7',
    name: 'J3 RSE 7',
    junctionId: 'KTPH_J3',
    type: TYPE_RSE,
    location: {
      longitude: 103.833818,
      latitude: 1.418639,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      1: [1],
    },
  },
  {
    id: 'KTPH_J3_RSE8',
    name: 'J3 RSE 8',
    junctionId: 'KTPH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.83396,
      latitude: 1.418344,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J3_RSE9',
    name: 'J3 RSE 9',
    junctionId: 'KTPH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.834032,
      latitude: 1.418157,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J4_RSE10',
    name: 'J4 RSE 10',
    junctionId: 'KTPH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.836536,
      latitude: 1.422829,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J4_RSE11',
    name: 'J4 RSE 11',
    junctionId: 'KTPH_J4',
    type: TYPE_RSE,
    location: {
      longitude: 103.836789,
      latitude: 1.423071,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      2: [1],
    },
  },
  {
    id: 'KTPH_J4_RSE12',
    name: 'J4 RSE 12',
    junctionId: 'KTPH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.836745,
      latitude: 1.422314,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J5_RSE13',
    name: 'J5 RSE 13',
    junctionId: 'KTPH_J5',
    type: TYPE_RSE,
    location: {
      longitude: 103.838587,
      latitude: 1.423309,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      5: [1, 2],
    },
  },
  {
    id: 'KTPH_J6_RSE14',
    name: 'J6 RSE 14',
    junctionId: 'KTPH_J6',
    type: TYPE_RSE,
    location: {
      longitude: 103.839853,
      latitude: 1.424302,
    },
    status: RseStatus.offline,
    hospital: 'H2',
    directions: {
      5: [1],
      7: [0],
    },
  },
  {
    id: 'KTPH_J6_RSE15',
    name: 'J6 RSE 15',
    junctionId: 'KTPH_J6',
    type: TYPE_RELAY,
    location: {
      longitude: 103.840026,
      latitude: 1.424479,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
  {
    id: 'KTPH_J6_RSE16',
    name: 'J6 RSE 16',
    junctionId: 'KTPH_J6',
    type: TYPE_RELAY,
    location: {
      longitude: 103.84009,
      latitude: 1.424353,
    },
    status: RseStatus.offline,
    hospital: 'H2',
  },
];

export const cghRSEs = [
  {
    id: 'CGH_J1_RSE1',
    name: 'J1 RSE 1',
    junctionId: 'CGH_J1',
    type: TYPE_RELAY,
    location: {
      longitude: 103.952134,
      latitude: 1.34124,
    },
    status: RseStatus.offline,
    hospital: 'H5',
  },
  {
    id: 'CGH_J1_RSE2',
    name: 'J1 RSE 2',
    junctionId: 'CGH_J1',
    type: TYPE_RELAY,
    location: {
      longitude: 103.952392,
      latitude: 1.341357,
    },
    status: RseStatus.offline,
    hospital: 'H5',
  },
  {
    id: 'CGH_J1_RSE3',
    name: 'J1 RSE 3',
    junctionId: 'CGH_J1',
    type: TYPE_RSE,
    location: {
      longitude: 103.952324,
      latitude: 1.341073,
    },
    status: RseStatus.offline,
    hospital: 'H5',
    directions: {
      5: [1],
    },
  },
  {
    id: 'CGH_J2_RSE4',
    name: 'J2 RSE 4',
    junctionId: 'CGH_J2',
    type: TYPE_RSE,
    location: {
      longitude: 103.951068,
      latitude: 1.340015,
    },
    status: RseStatus.offline,
    hospital: 'H5',
    directions: {
      5: [1, 2],
    },
  },
  {
    id: 'CGH_J3_RSE5',
    name: 'J3 RSE 5',
    junctionId: 'CGH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.949292,
      latitude: 1.338906,
    },
    status: RseStatus.offline,
    hospital: 'H5',
  },
  {
    id: 'CGH_J3_RSE6',
    name: 'J3 RSE 6',
    junctionId: 'CGH_J3',
    type: TYPE_RSE,
    location: {
      longitude: 103.949182,
      latitude: 1.338838,
    },
    status: RseStatus.offline,
    hospital: 'H5',
    directions: {
      1: [1],
    },
  },
  {
    id: 'CGH_J4_RSE7',
    name: 'J4 RSE 7',
    junctionId: 'CGH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.952615,
      latitude: 1.335054,
    },
    status: RseStatus.offline,
    hospital: 'H5',
  },
  {
    id: 'CGH_J4_RSE8',
    name: 'J4 RSE 8',
    junctionId: 'CGH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.952746,
      latitude: 1.335117,
    },
    status: RseStatus.offline,
    hospital: 'H5',
  },
  {
    id: 'CGH_J4_RSE9',
    name: 'J4 RSE 9',
    junctionId: 'CGH_J4',
    type: TYPE_RSE,
    location: {
      longitude: 103.952195,
      latitude: 1.335106,
    },
    status: RseStatus.offline,
    hospital: 'H5',
    directions: {
      7: [1],
    },
  },
];

const nuhRSEs = [
  {
    id: 'NUH_J1_RSE1',
    name: 'J1 RSE 1',
    junctionId: 'NUH_J1',
    type: TYPE_RELAY,
    location: {
      longitude: 103.783758,
      latitude: 1.291542,
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J1_RSE2',
    name: 'J1 RSE 2',
    junctionId: 'NUH_J1',
    type: TYPE_RSE,
    location: {
      longitude: 103.783569,
      latitude: 1.291735,
    },
    status: RseStatus.offline,
    hospital: 'H6',
    directions: {
      1: [1],
    },
  },
  {
    id: 'NUH_J2_RSE3',
    name: 'J2 RSE 3',
    junctionId: 'NUH_J2',
    type: TYPE_RSE,
    location: {
      longitude: 103.784318,
      latitude: 1.292133,
    },
    directions: {
      0: [0, 1],
      7: [1, 2],
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J3_RSE4',
    name: 'J3 RSE 4',
    junctionId: 'NUH_J3',
    type: TYPE_RELAY,
    location: {
      longitude: 103.785296,
      latitude: 1.293075,
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J3_RSE5',
    name: 'J3 RSE 5',
    junctionId: 'NUH_J3',
    type: TYPE_RSE,
    location: {
      longitude: 103.785497,
      latitude: 1.293167,
    },
    directions: {
      7: [0, 1],
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J4_RSE6',
    name: 'J4 RSE 6',
    junctionId: 'NUH_J4',
    type: TYPE_RSE,
    location: {
      longitude: 103.78601,
      latitude: 1.295339,
    },
    directions: {
      2: [2],
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J4_RSE7',
    name: 'J4 RSE 7',
    junctionId: 'NUH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.785849,
      latitude: 1.295391,
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
  {
    id: 'NUH_J4_RSE8',
    name: 'J4 RSE 8',
    junctionId: 'NUH_J4',
    type: TYPE_RELAY,
    location: {
      longitude: 103.785547,
      latitude: 1.295747,
    },
    status: RseStatus.offline,
    hospital: 'H6',
  },
];

export const allRSEsList = [...ntfghRSEs, ...ktphRSEs, ...cghRSEs, ...nuhRSEs];

export const rseHospitalList = [
  {
    name: 'Ng Teng Fong General Hospital',
    code: 'ntfgh',
    junctions: ntfghJunctionRSEs,
  },
];
