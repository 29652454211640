import Feature from 'ol/Feature';

/**
 * Updates a feature's property
 * @param mapFeature OL Map Feature
 * @param property feature property name
 * @param value updated value
 * @param override force set value even if null
 * @returns true if value is changed, false if value was not updated
 */
export function updateFeatureProperty(
  mapFeature: Feature,
  property: string,
  value: unknown,
  override?: boolean
) {
  if (!mapFeature) return false;
  if (value !== null) {
    mapFeature.set(property, value);
    return true;
  } else if (override) {
    mapFeature.set(property, value);
  }
  return false;
}
