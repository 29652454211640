import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
// import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
// import * as fakeIamsUserData from 'app/shared/fakeData/userIamsData.json';
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent implements OnInit {
  constructor(
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<AddAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      role: new UntypedFormControl('', [Validators.required]),
      userId: new UntypedFormControl('', [Validators.required]),
    });
  }

  running = false;
  form: UntypedFormGroup;
  accountData = this.dialogData.data.accountData;
  iamsData: any;
  isHide = true;

  systemRoleList = [];

  iamsDataList = [];

  selectedRole: string;
  title = 'Add User';
  isEdit = false;

  ngOnInit() {
    this.getSystemRoles();

    // get iamsDataList - temp
    // this.iamsDataList = (fakeIamsUserData as any).default;
    this.iamsDataList = [];

    if (this.accountData) {
      this.isEdit = true;

      this.form = this.formBuilder.group({
        role: new UntypedFormControl('', [Validators.required]),
        userId: new UntypedFormControl(
          { value: this.accountData.userId, disabled: true },
          [Validators.required]
        ),
      });
      this.selectedRole = this.accountData.roleName;
      this.title = 'Modify User Role';
      this.form.get('role').setValue(this.accountData.roleId);
      this.onRetrieveUserIamsData();
    }
  }

  getSystemRoles() {
    this.systemRoleList = [
      {
        roleId: 1,
        roleName: 'Super Administrator',
      },
      {
        roleId: 2,
        roleName: 'Ops User',
      },
      {
        roleId: 3,
        roleName: 'Division Commander',
      },
      {
        roleId: 4,
        roleName: 'Commander Fire Station',
      },
      {
        roleId: 5,
        roleName: 'Rota Commander',
      },
    ];
  }

  onRetrieveUserIamsData() {
    const currentId = Number(this.form.getRawValue().userId);
    const userData = this.iamsDataList.find(data => data.userId === currentId);
    if (userData) {
      this.iamsData = userData;
    } else {
      this.iamsData = undefined;
      this._snackBar.open('Invalid ID', null, {
        duration: 3000,
      });
    }
  }

  onSaveRole() {
    let returnData: any = {};
    if (!this.isEdit && this.iamsData.registered) {
      this._snackBar.open('User already registered', null, {
        duration: 3000,
      });
    } else {
      const userData = this.iamsDataList.find(
        data => data.userId === this.iamsData.userId
      );
      userData.registered = true;
      returnData = {
        roleName: this.form.value.role,
        userId: this.form.value.userId,
        ...this.iamsData,
      };
      this.addNewUser(returnData);
    }
  }

  addNewUser(returnData) {
    if (this.isEdit) {
      this._snackBar.open('User successfully edited', null, {
        duration: 5000,
      });
    } else {
      this._snackBar.open('User successfully added', null, {
        duration: 5000,
      });
    }

    returnData.registered = true;
    this.dialogRef.close(returnData);
    // var dataBody = [
    //   {
    //       "email": returnData.userEmail,
    //       "agency": returnData.agency,
    //       "displayName": returnData.displayName,
    //   }
    // ];

    // var urlAction = environment.apiBaseUri + '/v1/addUsers';
    // this._commonService.commonPostFullUrlAction(urlAction, dataBody).subscribe(roles => {

    //   let isExist = roles.response.includes('already exists');

    //   if(isExist) {
    //     this._snackBar.open(roles.response, null, {
    //       duration: 5000,
    //     });
    //   }
    //   else {
    //     this._snackBar.open(roles.response, null, {
    //       duration: 5000,
    //     });
    //     this.dialogRef.close(returnData);
    //   }
    // },
    // (errorResp: HttpErrorResponse) => {
    //   this.dialogRef.close(returnData);
    //   this._commonService.errorHandling(errorResp);
    // });
  }
}
