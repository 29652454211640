import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-timeout-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss'],
})
export class TimeoutDialogComponent implements OnInit, OnDestroy {
  public confirmMessage: string;
  countdown$: Observable<number>;
  countdownSub: Subscription;
  counter = environment.sessionExpiry;
  count = 60;
  tick = 1000;
  /**
   * Constructor
   *
   * @param {MatDialogRef<TimeoutDialogComponent>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {}

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.count = this.counter;
    this.countdown$ = timer(0, this.tick).pipe(
      take(this.counter),
      map(() => --this.count)
    );
    this.countdownSub = this.countdown$.subscribe({
      complete: () => {
        console.log('Countdown finished');
        this.dialogRef.close(false);
      },
    });
  }
  ngOnDestroy() {
    this.countdownSub = null;
  }
}
