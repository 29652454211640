<h1 matDialogTitle>{{ dataDialog.header }}</h1>
<div mat-dialog-content>{{ dataDialog.message }}</div>
<div mat-dialog-actions class="pt-24 f-right">
  <button
    mat-button
    (click)="dialogRef.close(false)"
    class="action-button mr-16">
    {{ dataDialog.textCancel }}
  </button>
  <button
    mat-raised-button
    class="action-button"
    [color]="dataDialog?.mode === 'warn' ? 'warn' : 'primary'"
    (click)="dialogRef.close(true)">
    {{ dataDialog.textConfirm }}
  </button>
</div>
