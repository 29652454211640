function createSquareFill(text: string) {
  if (text) {
    const trimmedText = text.trim();
    const squareText = '\u25A0'.repeat(trimmedText.length);
    return squareText;
  }
  return '';
}

export default createSquareFill;
