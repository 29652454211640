export const incidentTypes = {
  1: 'Bomb-Explosion',
  2: 'Bomb-Unexploded',
  3: 'CBRE-CE Iodine',
  4: 'CBRE-Chemical Agent',
  5: 'CBRE-HAZMAT',
  6: 'CBRE-White Powder',
  7: 'Environmental-RTA Spillage',
  8: 'Fire-DECAMS',
  9: 'Fire-Flammables and Chemicals',
  10: 'Fire-Large',
  11: 'Fire-Large Oil Tank',
  12: 'Fire-Minor',
  13: 'Fire-Moderate',
  14: 'Fire-Motor Vehicles',
  15: 'Fire-Ops Aircrash',
  16: 'Medical-Abdominal Pain',
  17: 'Medical-Allergies/Stings',
  18: 'Medical-Animal Bites/Attack',
  19: 'Medical-Assault/Rape',
  20: 'Medical-Back Pain',
  21: 'Medical-Bleeding/Lacerations',
  22: 'Medical-Breathing Problems',
  23: 'Medical-Burns',
  24: 'Medical-Chest Injuries',
  25: 'Medical-Chest Pain',
  26: 'Medical-Childbirth Obstetrics',
  27: 'Medical-Choking',
  28: 'Medical-Convulsions/Seizures',
  29: 'Medical-Diabetic Problems',
  30: 'Medical-Diving/Drowning',
  31: 'Medical-Electrocution',
  32: 'Medical-Eye Problem',
  33: 'Medical-Falls/Back Injury',
  34: 'Medical-Headache',
  35: 'Medical-Heart Problems',
  36: 'Medical-Heat/Cold Exposure',
  37: 'Medical-Inhalation',
  38: 'Medical-Machinery/Industrial',
  39: 'Medical-Motor Vehicle Accident',
  40: 'Medical-Poisoning/Ingestion',
  41: 'Medical-Psychiatric/Behavioral',
  42: 'Medical-Sick Person',
  43: 'Medical-Stab/Gunshot Injury',
  44: 'Medical-Stroke/CVA',
  45: 'Medical-Traumatic Injuries',
  46: 'Medical-Unconscious/Fainting',
  47: 'Medical-Unknown',
  48: 'Rescue-Collapsed Structures',
  49: 'Rescue-Height Rescue',
  50: 'Rescue-Industrial/Domestic',
  51: 'Rescue-MRT/LRT',
  52: 'Rescue-RTA',
  53: 'Rescue-Water Mishap',
  54: 'Shooting/Hostage',
  55: 'Support-Airport Emergency',
  56: 'Support-Cableway Contingency',
  57: 'Support-Ferry ETA<1 (DLP)',
  58: 'Support-Ferry ETA<1 (SLP)',
  59: 'Support-Ferry ETA>1 (DLP)',
  60: 'Support-Ferrry ETA>1 (SLP)',
  61: 'Support-Hijack',
  62: 'SPF Incident',
  63: 'Rescue-Suicide',
  64: 'Marine-Fire',
  65: 'Marine-Rescue',
  66: 'Marine-Non Rescue',
  68: 'Marine-Collision',
  69: 'Rescue-Flood',
  70: 'Rescue-Basement',
  71: 'Rescue-Amusement Rides',
  72: 'Rescue-MRT Detrainment',
  73: 'Medical-Cardiac Arrest P1+',
  74: 'Medical-Breathing Problems P1+',
  75: 'Medical-Chest Pain P1+',
  76: 'Medical-Convul/Seizure P1+',
  77: 'Medical-Traumatic Injuries P1+',
  78: 'Medical-Uncons/Faint P1+',
  79: 'Fire-Tunnel',
  80: 'Rescue-Lock Door',
  81: 'Rescue-Distress Alarm',
  82: 'Rescue-Gas Leak',
  83: 'Rescue-Lift Rescue',
  84: 'Rescue-Foul Smell',
  85: 'Rescue-Minor',
  86: 'Fire-Minor',
  87: 'Marine-Fire Minor',
  88: 'Marine-Fire Large',
  89: 'Marine-Medical MSI',
  90: 'Marine-Medical MCI',
  91: 'Marine-HAZMAT',
  92: 'Marine-CBRE',
  93: 'Rescue-Body Retrieval',
  94: 'Fire-Vegetation Expressway',
  95: 'Fire-Vegetation Others',
  96: 'Fire-Minor Rubbish',
  97: 'Fire-Investigation',
  98: 'Fire-HTVTS Violation',
  99: 'Feedback/Enquiry',
  100: 'Miscellaneous',
  101: 'SPF Assistance Required',
  102: 'Fire-Large MRP',
  103: 'Fire-Inland Water',
  104: 'Marine-CBRE TIC',
  105: 'Medical-Abdominal Pain P1+',
  106: 'Medical-Allergies/Stings P1+',
  107: 'Medical-Animal Bites/Attack P1+',
  108: 'Medical-Assault/Rape P1+',
  109: 'Medical-Back Pain P1+',
  110: 'Medical-Bleeding/Lacerations P1+',
  111: 'Medical-Burns P1+',
  112: 'Medical-Cardiac Arrest',
  113: 'Medical-Childbirth Obstetrics P1+',
  114: 'Medical-Choking P1+',
  115: 'Medical-Diabetic Problems P1+',
  116: 'Medical-Diving/Drowning P1+',
  117: 'Medical-Electrocution P1+',
  118: 'Medical-Eye Problem P1+',
  119: 'Medical-Falls/Back Injury P1+',
  120: 'Medical-Headache P1+',
  121: 'Medical-Heat/Cold Exposure P1+',
  122: 'Medical-Inhalation P1+',
  123: 'Medical-Motor Vehicle Accident P1+',
  124: 'Medical-Poisoning/Ingestion P1+',
  125: 'Medical-Psychiatric/Behavioral P1+',
  126: 'Medical-Sick Person P1+',
  127: 'Medical-Stab/Gunshot Injury P1+',
  128: 'Medical-Stroke/CVA P1+',
  129: 'Medical-Unknown P1+',
  130: 'Fire-Large Oil Tank (<=76m)',
  131: 'Fire-Large Oil Tank (>76m)',
};

export const incidentPriority = {
  '1': 'Public Concerned Calls',
  '2': 'SPF Incidents',
  '3': 'Confirmed DECAMS Alams',
  '4': 'Fire Incidents 8:00 SLA',
  '5': 'Medical Incidents 11:00 SLA',
  '5A': 'Medical Inc P1+ 11:00 SLA',
  '5B': 'Medical Inc P1 11:00 SLA',
  '5C': 'Medical Inc P2 11:00 SLA',
  '5D': 'Medical Inc P3 11:00 SLA',
  '5E': 'Medical Inc P4 11:00 SLA',
  '1+': 'Medical Incident Priority',
};

export const medicalPriority = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '5A': 'P1+',
  '5B': 'P1',
  '5C': 'P2',
  '5D': 'P3',
  '5E': 'P4',
  '1+': 'P1+',
  P5: '5',
  P5A: 'P1+',
  P5B: 'P1',
  P5C: 'P2',
  P5D: 'P3',
  P5E: 'P4',
  'P1+': 'P1+',
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
};

export const priorityList: PriorityValues[] = [
  'P1+',
  'P1',
  'P2',
  'P3',
  'P4',
  '1',
  '2',
  '3',
  '4',
  '5',
];

export const priorityFilterList = ['P1+', '-'];

export const priorityFilterOptions = [
  {
    label: 'P1+',
    value: 'P1+',
  },
  {
    label: 'No Priority',
    value: '-',
  },
];

export type PriorityValues =
  | 'P1+'
  | 'P1'
  | 'P2'
  | 'P3'
  | 'P4'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5';

export const SUCCESS = 'success';
export const FAIL = 'fail';
export const ALL = 'all';
export const OPEN = 'open';
export const CLOSED = 'closed';

export type IncidentStatus = typeof ALL | typeof OPEN | typeof CLOSED;

export const fullMedicalPriority = {
  1: '1-Public Concerned Calls',
  2: '2-SPF Incidents',
  3: '3-Confirmed DECAMS Alams',
  4: '4-Fire Incidents 8:00 SLA',
  5: '5-Medical Incidents 11:00 SLA',
  '5A': '5A-Medical Inc P1+ 11:00 SLA',
  '5B': '5B-Medical Inc P1 11:00 SLA',
  '5C': '5C-Medical Inc P2 11:00 SLA',
  '5D': '5D-Medical Inc P3 11:00 SLA',
  '5E': '5E-Medical Inc P4 11:00 SLA',
};

export const medicalPriorityDescription = {
  1: '1: Public Concerned Calls',
  2: '2: SPF Incidents',
  3: '3: Confirmed DECAMS Alarms',
  4: '4: Fire Incidents 8:00 SLA',
  5: '5: Medical Incidents 11:00 SLA',
  '5A': '5A: Medical Inc P1+ 11:00 SLA',
  '5B': '5B: Medical Inc P1 11:00 SLA',
  '5C': '5C: Medical Inc P2 11:00 SLA',
  '5D': '5D: Medical Inc P3 11:00 SLA',
  '5E': '5E: Medical Inc P4 11:00 SLA',
  'P1+': '5A: Medical Inc P1+ 11:00 SLA',
  P1: '5B: Medical Inc P1 11:00 SLA',
  P2: '5C: Medical Inc P2 11:00 SLA',
  P3: '5D: Medical Inc P3 11:00 SLA',
  P4: '5E: Medical Inc P4 11:00 SLA',
};

export const incidentCloseReason = {
  CC: 'Call Cancelled',
  CO: 'Call Completed',
  DC: 'Duplicate Call',
  FA: 'False Alarm',
  FC: 'Feedback Call',
  NC: 'Nuisance Call',
  OO: 'Offline Operations',
  RE: 'Reassign',
  TC: 'Test Call',
  TO: 'Timed Out',
};

export const nuisanceCall = {
  '00': 'Not Applicable',
  BI: 'Obscene / Insane',
  BK: 'Obscene / Kids',
  BN: 'Obscene / Adult',
  BU: 'Obscene / Unknown',
  SI: 'Silent / Insane',
  SK: 'Silent / Kids',
  SN: 'Silent / Adult',
  SU: 'Silent / Unknown',
  TI: 'Harrassment Threat / Insane',
  TK: 'Harrassment Threat / Kids',
  TN: 'Harrassment Threat / Adult',
  TU: 'Harrassment Threat / Unkn',
  UI: 'Unintentional / Insane',
  UK: 'Unintentional / Kids',
  UA: 'Unintentional / Adult',
  UU: 'Unintentional / Unknown',
};
