import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { environment } from 'environments/environment';

@Pipe({
  name: 'momentPipe',
  pure: true,
})
export class MomentPipe implements PipeTransform {
  transform(value: string, dateFormat = 'DD/MM/YYYY HH:mm:ss'): string {
    if (value?.length > 0) {
      return moment(value).utcOffset(environment.timezone).format(dateFormat);
    }
    return '';
  }
}
