export enum DeviceHistoryEventType {
  ACES_INCIDENT_REOPEN = 'ACES_INCIDENT_REOPEN',
  ACES_INCIDENT_PROMOTION = 'ACES_INCIDENT_PROMOTION',
  ACES_INCIDENT = 'ACES_INCIDENT',
  ACES_CALLSIGN = 'ACES_CALLSIGN',
  ACES_CLOSE = 'ACES_CLOSE',
  ACES_CLOSE_TIMEOUT = 'ACES_CLOSE_TIMEOUT',
  ACES_CLOSE_DEMOTION = 'ACES_CLOSE_DEMOTION',
  DEVICE_LOCATION = 'DEVICE_LOCATION',
  DEVICE_STATUS = 'DEVICE_STATUS',
  DEVICE_OFFLINE = 'DEVICE_OFFLINE',
  DEVICE_ONLINE = 'DEVICE_ONLINE',
  PRIORITY_REQUEST = 'PRIORITY_REQUEST',
  PRIORITY_REQUEST_RESULT = 'PRIORITY_REQUEST_RESULT',
  PRIORITY_SESSION = 'PRIORITY_SESSION',
  PRIORITY_OPERATION_UPDATE = 'PRIORITY_OPERATION_UPDATE',
  PRIORITY_OPERATION = 'PRIORITY_OPERATION',
  GEOZONE_TRIGGERED = 'GEOZONE_TRIGGERED',
  DEVICEMANAGEMENT_OFFBOARD = 'DEVICEMANAGEMENT_OFFBOARD',
  DEVICEMANAGEMENT_ONBOARD = 'DEVICEMANAGEMENT_ONBOARD',
  DEVICEMANAGEMENT_UPDATE = 'DEVICEMANAGEMENT_UPDATE',
}

export const deviceHistoryEventTypes = [
  'ACES_INCIDENT_REOPEN',
  'ACES_INCIDENT_PROMOTION',
  'ACES_CLOSE_DEMOTION',
  'ACES_CALLSIGN',
  'ACES_CLOSE_TIMEOUT',
  'ACES_CLOSE',
  'ACES_INCIDENT_REOPEN',
  'ACES_INCIDENT',
  'DEVICE_LOCATION',
  'DEVICE_OFFLINE',
  'DEVICE_ONLINE',
  'DEVICE_STATUS',
  'GEOZONE_TRIGGERED',
  'PRIORITY_OPERATION_UPDATE',
  'PRIORITY_OPERATION',
  'PRIORITY_REQUEST_RESULT',
  'PRIORITY_REQUEST',
  'PRIORITY_SESSION',
  'DEVICEMANAGEMENT_OFFBOARD',
  'DEVICEMANAGEMENT_ONBOARD',
  'DEVICEMANAGEMENT_UPDATE',
];

export const deviceHistoryEventTypeMapping = {
  ACES_INCIDENT_REOPEN: 'ACES: Reopen',
  ACES_INCIDENT_PROMOTION: 'ACES: Promotion',
  ACES_INCIDENT: 'ACES: Incident',
  ACES_CALLSIGN: 'ACES: Callsign',
  ACES_CLOSE: 'ACES: Close',
  ACES_CLOSE_TIMEOUT: 'ACES: Timeout',
  ACES_CLOSE_DEMOTION: 'ACES: Downgrade',
  DEVICE_LOCATION: 'Device: Location',
  DEVICE_STATUS: 'Device: Status',
  DEVICE_OFFLINE: 'Device: Offline',
  DEVICE_ONLINE: 'Device: Online',
  PRIORITY_REQUEST: 'Priority Request',
  PRIORITY_REQUEST_RESULT: 'Priority Request Result',
  PRIORITY_SESSION: 'Priority Session',
  PRIORITY_OPERATION_UPDATE: 'Priority Operation Update',
  PRIORITY_OPERATION: 'Priority Operation',
  GEOZONE_TRIGGERED: 'Geozone Triggered',
  DEVICEMANAGEMENT_OFFBOARD: 'Device: Offboard',
  DEVICEMANAGEMENT_ONBOARD: 'Device: Onboard',
  DEVICEMANAGEMENT_UPDATE: 'Device: Update',
};

export const deviceHistoryEventTypeLabels = [
  {
    label: 'ACES Callsign',
    value: 'ACES_CALLSIGN',
  },
  {
    label: 'ACES Close',
    value: 'ACES_CLOSE',
  },
  {
    label: 'ACES Timeout',
    value: 'ACES_CLOSE_TIMEOUT',
  },
  {
    label: 'ACES Incident',
    value: 'ACES_INCIDENT',
  },
  {
    label: 'ACES Upgrade',
    value: 'ACES_INCIDENT_PROMOTION',
  },
  {
    label: 'ACES Reopen',
    value: 'ACES_INCIDENT_REOPEN',
  },
  {
    label: 'ACES Downgrade',
    value: 'ACES_CLOSE_DEMOTION',
  },
  {
    label: 'Device Location',
    value: 'DEVICE_LOCATION',
  },
  {
    label: 'Device Offline',
    value: 'DEVICE_OFFLINE',
  },
  {
    label: 'Device Online',
    value: 'DEVICE_ONLINE',
  },
  {
    label: 'Device Status',
    value: 'DEVICE_STATUS',
  },
  {
    label: 'Geozone Triggered',
    value: 'GEOZONE_TRIGGERED',
  },
  {
    label: 'Priority Operation',
    value: 'PRIORITY_OPERATION',
  },
  {
    label: 'Priority Operation Update',
    value: 'PRIORITY_OPERATION_UPDATE',
  },
  {
    label: 'Priority Request',
    value: 'PRIORITY_REQUEST',
  },
  {
    label: 'Priority Request Result',
    value: 'PRIORITY_REQUEST_RESULT',
  },
  {
    label: 'Priority Session',
    value: 'PRIORITY_SESSION',
  },
  {
    label: 'Device Onboard',
    value: 'DEVICEMANAGEMENT_ONBOARD',
  },
  {
    label: 'Device Offboard',
    value: 'DEVICEMANAGEMENT_OFFBOARD',
  },
  {
    label: 'Device Update',
    value: 'DEVICEMANAGEMENT_UPDATE',
  },
];

export const deviceMgmtEventTypes = [
  'DEVICEMANAGEMENT_ONBOARD',
  'DEVICEMANAGEMENT_OFFBOARD',
  'DEVICEMANAGEMENT_UPDATE',
];

export const acesEventTypes = [
  'ACES_INCIDENT_REOPEN',
  'ACES_INCIDENT_PROMOTION',
  'ACES_INCIDENT',
  'ACES_CALLSIGN',
  'ACES_CLOSE',
  'ACES_CLOSE_TIMEOUT',
];

export const deviceEventTypes = ['DEVICE_LOCATION', 'DEVICE_STATUS'];

export const priorityOperationEventTypes = ['PRIORITY_OPERATION'];

export const junctionEventTypes = [
  'PRIORITY_REQUEST',
  'PRIORITY_REQUEST_RESULT',
  'PRIORITY_SESSION',
];
