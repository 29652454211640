<div
  class="navbar-header"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <img class="logo-icon" src="assets/images/logos/scdf-logo-white.png" />
    <span class="logo-text"></span>
    <!-- <div class="sub-title" *ngIf="!getSidebarFolded()">
      Traffic Priority System
    </div> -->
  </div>

  <!-- <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button> -->

  <button
    mat-icon-button
    class="toggle-sidebar-opened"
    (click)="toggleSidebarOpened()"
    fxHide.gt-md>
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div class="navbar-toggle fuse-navy-700" fxHide.lt-lg>
  <button
    mat-icon-button
    class="toggle-sidebar-folded"
    (click)="toggleSidebarFolded()">
    <mat-icon *ngIf="getSidebarFolded(); else unfolded">chevron_right</mat-icon>
    <ng-template #unfolded><mat-icon>chevron_left</mat-icon></ng-template>
  </button>
</div>

<div class="navbar-account fuse-navy-700">
  <div class="account-wrapper" matTooltip="Account" matTooltipPosition="right">
    <button
      [matMenuTriggerFor]="menu"
      class="user-container"
      matRipple
      role="button"
      tabIndex="0">
      <mat-icon class="account-icon" fontSet="material-icons-outlined">
        account_circle
      </mat-icon>
      <span class="display-name">{{ displayName }}</span>
    </button>
    <!-- <mat-icon class="divider">horizontal_rule</mat-icon> -->
  </div>
  <mat-menu #menu="matMenu" class="matMenu" xPosition="after">
    <!-- <button mat-menu-item class="account-name">{{displayName}}</button> -->
    <button mat-menu-item class="account-name" type="button">
      <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
      <span>{{ displayName }}</span>
    </button>
    <button mat-menu-item (click)="onChangePassword()" type="button">
      <mat-icon>password</mat-icon>
      <span>Change Password</span>
    </button>
    <button mat-menu-item (click)="onLogout()" type="button">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</div>

<div
  class="navbar-content"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
