import Icon, { Options as IconOptions } from 'ol/style/Icon';
import Text, { Options as TextOptions } from 'ol/style/Text';
import OlFeature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import { toRadians } from 'ol/math';
import { Direction, ArrowForm, ColorData } from '@fuse/others/constant';

export const ICONS_PATH = 'assets/images/icons/';

export const createCommonIconStyle = (
  icon: string,
  scale = 0.75,
  iconProps?: IconOptions
) => {
  return new Icon({
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    scale,
    opacity: 1,
    src: ICONS_PATH + icon + '.svg',
    ...iconProps,
  });
};

export function createTextStyle(feature: OlFeature) {
  const text = feature.get('description') ?? '';
  const color = feature.get('color');
  return new Text({
    offsetY: -30,
    text,
    fill: new Fill({
      color: '#fff',
    }),
    backgroundFill: new Fill({
      color,
    }),
    padding: [5, 7, 5, 7],
    font: 'bold 12px Muli, Helvetica Neue, Arial, sans-serif',
    backgroundStroke: new Stroke({
      color: 'rgba(0,0,0, 0.7)',
      width: 1,
    }),
  });
}

export const CreateCommonTextStyle = (
  text = '',
  color,
  TextOptions?: TextOptions
) =>
  new Text({
    text,
    offsetY: -25,
    fill: new Fill({
      color: '#fff',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0, 0.7)',
      width: 5,
    }),
    // backgroundFill: new Fill({
    //     color
    // }),
    padding: [5, 7, 5, 7],
    font: '12px Muli, Helvetica Neue, Arial, sans-serif',
    // backgroundStroke: new Stroke({
    //     color: 'rgba(0,0,0, 0.7)',
    //     width: 1
    // }),
    // overflow: true,
    ...TextOptions,
  });

export const createArrowIcons = (color = '#fff') => {
  const arrowIcon = {};
  for (let i = 0; i < 8; i++) {
    const arrowDirection = {};
    const currOffset: [number, number] = [0, 24];
    for (let j = 0; j < 3; j++) {
      const arrowStyle = createArrowIconStyle(
        `arrow-${j}`,
        45 * i,
        currOffset,
        color
      );
      arrowDirection[j] = arrowStyle;
    }
    arrowIcon[i] = arrowDirection;
  }
  return arrowIcon;
};

const createArrowIconStyle = (
  icon: string,
  rotation: number,
  displacement: [number, number],
  color = '#fff'
) => {
  return new Icon({
    color,
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    scale: 1,
    opacity: 1,
    src: ICONS_PATH + icon + '.svg',
    displacement,
    rotation: toRadians(rotation),
  });
};

export const mapApproachIcons = (color = '#fff') => {
  const currOffset: [number, number] = [-27, 0];
  const commonArrows = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
  };

  const northIngressArrows = {
    ...commonArrows,
    9: createArrowIconStyle(`arrow-su`, 0, currOffset, color),
    10: createArrowIconStyle(`arrow-se`, 0, currOffset, color),
    11: createArrowIconStyle(`arrow-s`, 0, currOffset, color),
    12: createArrowIconStyle(`arrow-sw`, 0, currOffset, color),
  };
  const eastIngressArrows = {
    ...commonArrows,
    9: createArrowIconStyle(`arrow-wn`, 0, currOffset, color),
    10: createArrowIconStyle(`arrow-wu`, 0, currOffset, color),
    11: createArrowIconStyle(`arrow-ws`, 0, currOffset, color),
    12: createArrowIconStyle(`arrow-w`, 0, currOffset, color),
  };
  const southIngressArrows = {
    ...commonArrows,
    9: createArrowIconStyle(`arrow-n`, 0, currOffset, color),
    10: createArrowIconStyle(`arrow-ne`, 0, currOffset, color),
    11: createArrowIconStyle(`arrow-nu`, 0, currOffset, color),
    12: createArrowIconStyle(`arrow-nw`, 0, currOffset, color),
  };
  const westIngressArrows = {
    ...commonArrows,
    9: createArrowIconStyle(`arrow-en`, 0, currOffset, color),
    10: createArrowIconStyle(`arrow-e`, 0, currOffset, color),
    11: createArrowIconStyle(`arrow-es`, 0, currOffset, color),
    12: createArrowIconStyle(`arrow-eu`, 0, currOffset, color),
  };

  const arrowIcons = {
    0: commonArrows,
    1: northIngressArrows,
    2: northIngressArrows,
    3: eastIngressArrows,
    4: eastIngressArrows,
    5: southIngressArrows,
    6: southIngressArrows,
    7: westIngressArrows,
    8: westIngressArrows,
    9: commonArrows,
    10: commonArrows,
    11: commonArrows,
    12: commonArrows,
  };

  return arrowIcons;
};

export const ingressEgressIcons = mapApproachIcons(ColorData.eGreen);

export const AmbulanceText = new Style({
  text: new Text({
    offsetY: -29,
    text: '',
    fill: new Fill({
      color: '#fff',
    }),
    padding: [5, 5, 5, 5],
    font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
  }),
});

export const AmbulanceLabelShape = new Style({
  text: new Text({
    text: '',
    offsetY: -30,
    fill: new Fill({
      color: '#c9c9c9',
    }),
    textAlign: 'center',
    justify: 'center',
    textBaseline: 'middle',
    font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
    stroke: new Stroke({
      color: '#c9c9c9',
      width: 19,
    }),
  }),
});

export const AmbulanceLabelBorder = new Style({
  text: new Text({
    text: '',
    offsetY: -30,
    fill: new Fill({
      color: '#000',
    }),
    textAlign: 'center',
    justify: 'center',
    textBaseline: 'middle',
    font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
    stroke: new Stroke({
      color: '#000',
      width: 21,
    }),
  }),
});

// const mapArrowIcon = (inboundApproach: number, outboundApproach: number) => {
//   const arrowObject = {
//     direction: '',
//     arrow: '',
//   };

//   return arrowObject;
// };
