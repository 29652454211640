import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { environment } from 'environments/environment';

/**
 * Formats Date String with timezone accounted for
 * @param value Date String or unix timestamp
 * @param dateFormat Desired Date Format
 * @returns Formatted Date Format
 */
export function momentFormat(
  value?: string | number,
  dateFormat = 'DD/MM/YYYY HH:mm:ss'
): string {
  if (!value) moment().utcOffset(environment.timezone).format(dateFormat);
  return moment(value).utcOffset(environment.timezone).format(dateFormat);
}

/**
 * Creates a moment object with UTC offset
 * @param value Date String
 * @returns Moment Object
 */
export function createMoment(value?: string): moment.Moment {
  if (!value) return moment().utcOffset(environment.timezone);
  return moment(value).utcOffset(environment.timezone);
}

/**
 * Creates a moment object with UTC offset from unix timestamp
 * @param value unix timestamp
 * @returns Moment Object
 */
export function unixToMoment(value: number): moment.Moment {
  return moment.unix(value).utcOffset(environment.timezone);
}

/**
 * Creates a unix timestamp from Date String using Moment
 * @param value Date String
 * @returns Unix timestamp
 */
export function momentUnix(value: string): number {
  return moment(value).utcOffset(environment.timezone).unix();
}

/**
 * Creates an ISO8601 string from Unix seconds timestamp
 * @param value Unix
 * @returns Date String
 */
export function momentUnixToIso(value: number): string {
  return moment(value, 'X').utcOffset(environment.timezone).format();
}

export function momentUnknownDateToIso(value: string | number) {
  if (typeof value === 'number') {
    return moment(value, 'X').utcOffset(environment.timezone).format();
  } else if (typeof value === 'string') {
    // assume already in ISO string format
    return value;
    // return momentToIso(value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
  }
  return momentToIso(value);
}
/**
 * Creates an ISO8601 string from an inferred value
 * @param value date like format
 * @returns Date String
 */
export function momentToIso(value: number | string, format?: string): string {
  return moment(value, format).utcOffset(environment.timezone).format();
}

/**
 * Creates an ISO8601 string from a Moment object
 * @param value Moment object
 * @returns Date String
 */
export function momentTypeToIso(value: moment.Moment): string {
  if (!value) return undefined;
  return value.utcOffset(environment.timezone).format();
}

/**
 * Try to parse IS0 8601 string using moment and convert to another format
 * @param value ISO 8601 duration string
 * @returns Formatted duration string
 */
export function momentDuration(value: string, format = 'HH:mm:ss'): string {
  return moment
    .utc(moment.duration('PT' + value.toUpperCase()).asMilliseconds())
    .format(format);
}

/**
 * Check if moment object is within a range
 * @param date Date to check
 * @param start Date start
 * @param end  Date end
 * @param unit Unit of comparison e.g. day, month, year
 * @returns boolean
 */
export function momentInRange(
  momentDate: moment.Moment,
  startDate: moment.Moment,
  endDate: moment.Moment,
  unit: unitOfTime.StartOf = 'day'
) {
  if (startDate) {
    const afterStart = momentDate.isSameOrAfter(startDate, unit);
    if (!afterStart) return false;
  }
  if (endDate) {
    const beforeEnd = momentDate.isSameOrBefore(endDate, unit);
    if (!beforeEnd) return false;
  }
  return true;
}

/**
 * Check if date string is within a range
 * @param date Date string to check
 * @param start Date string start
 * @param end  Date string end
 * @returns boolean
 */
export function dateStringinDayRange(
  date: string,
  start: string,
  end: string,
  unit: unitOfTime.StartOf = 'day'
) {
  const momentDate = createMoment(date);
  const startDate = createMoment(start);
  const endDate = createMoment(end);
  if (startDate) {
    const afterStart = momentDate.isSameOrAfter(startDate, unit);
    if (!afterStart) return false;
  }
  if (endDate) {
    const beforeEnd = momentDate.isSameOrBefore(endDate, unit);
    if (!beforeEnd) return false;
  }
  return true;
}

/**
 * compares two date strings
 * @param timeA ISO string
 * @param timeB ISO string
 * @param order desc | asc
 * @returns 0 | 1 | -1
 */
export function dateCompare(
  timeA: string,
  timeB: string,
  order = 'desc'
): 0 | 1 | -1 {
  // console.log('compare', timeA, timeB);
  if (timeA && !timeB) return -1;
  if (!timeA && timeB) return 1;
  const momentA = createMoment(timeA);
  const momentB = createMoment(timeB);
  if (momentA.isSame(momentB)) return 0;
  if (order === 'desc') {
    return momentA.isBefore(momentB) ? 1 : -1;
  }
  return momentA.isAfter(momentB) ? 1 : -1;
}
