export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: {
        TITLE: 'Applications',
        TRACKING: {
          TITLE: 'Tracking',
          BADGE: '',
        },
        REPORTS: {
          TITLE: 'Reports',
          BADGE: '',
        },
        DASHBOARD: {
          TITLE: 'TPS Dashboard',
          BADGE: '',
        },
        PLAYBACK: {
          TITLE: 'Playback',
          BADGE: '',
        },
      },
      DASHBOARD: {
        TITLE: 'TPS Dashboard',
        TRACKING: {
          TITLE: 'Tracking',
          BADGE: '',
        },
        REPORTS: {
          TITLE: 'Reports',
          BADGE: '',
        },
        PLAYBACK: {
          TITLE: 'Playback',
          BADGE: '',
        },
      },
      REPORT: {
        TITLE: 'Reports',
        TPSREPORTS: {
          TITLE: 'TPS Reports',
          BADGE: '',
        },
      },
      SYSTEMADMINISTRATION: {
        TITLE: 'System Administration',
        REPORTS: {
          TITLE: 'Reports',
          BADGE: '',
        },
        DEVICE: {
          TITLE: 'Device Monitoring',
          BADGE: '',
        },
        AUDIT_TRAIL: {
          TITLE: 'Audit Trail',
          BADGE: '',
        },
        ACCESS_CONTROL: {
          TITLE: 'Access Control',
          BADGE: '',
        },
        USER_ACCOUNTS: {
          TITLE: 'User Accounts',
          BADGE: '',
        },
      },
      MASTERDATA: {
        TITLE: 'MASTERDATA',
        'VEHICLE LIST': {
          TITLE: 'Vehicle List',
          BADGE: '',
        },
        'DRIVER LIST': {
          TITLE: 'Driver List',
          BADGE: '',
        },
      },
      VEHICLEMANAGEMENT: {
        TITLE: 'VEHICLE MANAGEMENT',
        DEVICE_MONITORING: {
          TITLE: 'Device Monitoring',
          BADGE: '',
        },
        REPORT_LOG: {
          TITLE: 'Report Log',
          BADGE: '',
        },
        KEY_PERFORMANCE: {
          TITLE: 'Key Performance',
          BADGE: '',
        },
        DRIVER_BEHEVIOR: {
          TITLE: 'Driver Behavior',
          BADGE: '',
        },
      },
      ACCOUNTMANAGEMENT: {
        TITLE: 'MANAGEMENT',
        USER_MANAGEMENT: {
          TITLE: 'User Management',
          BADGE: '',
        },
        ROLE_MANAGEMENT: {
          TITLE: 'Role Management',
          BADGE: '',
        },
        RIGHTS_MANAGEMENT: {
          TITLE: 'Rights Management',
          BADGE: '',
        },
        AUDIT_MANAGEMENT: {
          TITLE: 'Audit Trail',
          BADGE: '',
        },
      },
      AUDITMANAGEMENT: {
        TITLE: 'Audit Management',
        REPORTS: {
          TITLE: 'User Reports',
          BADGE: '',
        },
        PRIVILEGE: {
          TITLE: 'Audit Trail',
          BADGE: '',
        },
        ACCESS: {
          TITLE: 'Access Report',
          BADGE: '',
        },
      },
      SETTINGS: {
        TITLE: 'SETTINGS',
        PROFILE: {
          TITLE: 'Profiles',
          BADGE: '',
        },
      },
    },
  },
};
