export const locale = {
  lang: 'tr',
  data: {
    NAV: {
      APPLICATIONS: 'Programlar',
      DASHBOARD: 'Dashboard TR',
      SAMPLE: {
        TITLE: 'Örnek',
        BADGE: '15',
      },
      TRACKING: {
        TITLE: 'Tracking TR',
        BADGE: '',
      },
    },
  },
};
