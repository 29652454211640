import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
// import { getPath, LOGIN_PATH } from "../others/constant";

@Injectable()
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
    private _commonService: CommonService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkUserLogin(route, state);
    // if (this.auth.isAuthenticated()) {
    //   console.log('can activate', route, state);
    //   return true;
    // } else {
    //   // this.router.navigate(['/pages/auth/login']);
    //   this._commonService.authLogout();
    // }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isAuthenticated()) {
      this.checkUserRole(route);
    } else {
      this._commonService.authLogout();
      return false;
    }
  }

  checkUserRole(route: ActivatedRouteSnapshot) {
    const userRole = this.auth.getRole();
    if (route.data.role && route.data.role.indexOf(userRole) === -1) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
