import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertTypes } from 'app/shared/model/alert-types';
import { NotificationAlert } from 'app/shared/model/notification-alert';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NotificationPanelService {
  notificationAlerts: Observable<NotificationAlert>;
  private notificationAlerts$ = new Subject<NotificationAlert>();

  constructor() { 
    this.notificationAlerts = this.notificationAlerts$.asObservable();
  }

  showSuccessToast(title: string, message: string, date: Moment) {
    this.notificationAlerts$.next({
      title,
      message,
      date,
      type: AlertTypes.Success,
      autoHide: true,
    })
  }

  showInfoToast(title: string, message: string, date: Moment) {
    this.notificationAlerts$.next({
      title,
      message,
      date,
      type: AlertTypes.Info,
      autoHide: true,
    })
  }

  showWarningToast(title: string, message: string, date: Moment) {
    this.notificationAlerts$.next({
      title,
      message,
      date,
      type: AlertTypes.Warning,
      autoHide: false,
    })
  }

  showErrorToast(title: string, message: string, date: Moment) {
    this.notificationAlerts$.next({
      title,
      message,
      date,
      type: AlertTypes.Error,
      autoHide: false,
    })
  }

}
