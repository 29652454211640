import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NotificationPanelComponent } from './notification-panel.component';
import { NotificationAlertComponent } from './notification-alert/notification-alert.component';
import { NotificationPanelService } from './notification-panel.service';

@NgModule({
  declarations: [NotificationAlertComponent, NotificationPanelComponent],
  imports: [
    FuseSharedModule,
    BrowserAnimationsModule,
    BrowserModule,

    MatButtonModule,
    MatIconModule,
  ],
  exports: [NotificationPanelComponent],
  providers: [NotificationPanelService],
})
export class NotificationPanelModule {}
