import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'dashboard',
    title: 'TPS Dashboard',
    translate: 'NAV.DASHBOARD.TITLE',
    type: 'group',
    children: [
      {
        id: 'tracking',
        title: 'Tracking',
        translate: 'NAV.DASHBOARD.TRACKING.TITLE',
        type: 'item',
        icon: 'map',
        url: '/tracking',
      },
      {
        id: 'reports',
        title: 'Reports',
        translate: 'NAV.SYSTEMADMINISTRATION.REPORTS.TITLE',
        type: 'item',
        icon: 'description',
        url: '/system/reports',
      },

      // {
      //   id: 'playback',
      //   title: 'Playback',
      //   translate: 'NAV.APPLICATIONS.PLAYBACK.TITLE',
      //   type: 'item',
      //   icon: 'play_arrow',
      //   url: '/dashboard/playback',
      // },
      // {
      //   id: 'reports',
      //   title: 'Reports',
      //   translate: 'NAV.SYSTEMADMINISTRATION.REPORTS.TITLE',
      //   type: 'item',
      //   icon: 'description',
      //   url: '/dashboard/reports',
      // },
    ],
  },
  {
    id: 'device-monitoring',
    title: 'Device Monitoring',
    translate: 'NAV.SYSTEMADMINISTRATION.DEVICE.TITLE',
    type: 'item',
    icon: 'sensors',
    url: '/system/device-monitoring',
    // roles: ['admin'],
  },
  // {
  //   id: 'reports',
  //   title: 'Reports',
  //   translate: 'NAV.SYSTEMADMINISTRATION.REPORTS.TITLE',
  //   type: 'item',
  //   icon: 'description',
  //   url: '/system/reports',
  // },
  {
    id: 'system',
    title: 'System Administration',
    translate: 'NAV.SYSTEMADMINISTRATION.TITLE',
    roles: ['admin'],
    type: 'group',
    children: [
      {
        id: 'user-accounts',
        title: 'Users',
        translate: 'NAV.SYSTEMADMINISTRATION.USER_ACCOUNTS.TITLE',
        type: 'item',
        icon: 'supervisor_account',
        url: '/system/user-accounts',
        roles: ['admin'],
      },
    ],
  },
  // {
  //   id: 'system',
  //   title: 'System Administration',
  //   translate: 'NAV.SYSTEMADMINISTRATION.TITLE',
  //   type: 'group',
  //   children: [
  //     // {
  //     //   id: 'device-monitoring',
  //     //   title: 'Device Monitoring',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.DEVICE.TITLE',
  //     //   type: 'item',
  //     //   icon: 'sensors',
  //     //   url: '/system/device-monitoring',
  //     // },
  //     // {
  //     //   id: 'reports',
  //     //   title: 'Reports',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.REPORTS.TITLE',
  //     //   type: 'item',
  //     //   icon: 'description',
  //     //   url: '/system/reports',
  //     // },
  //     // {
  //     //   id: 'audit-trail',
  //     //   title: 'Audit Trail',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.AUDIT_TRAIL.TITLE',
  //     //   type: 'item',
  //     //   icon: 'receipt_long',
  //     //   url: '/system/audit-trail',
  //     // },
  //     // {
  //     //   id: 'access-control',
  //     //   title: 'Access Control',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.ACCESS_CONTROL.TITLE',
  //     //   type: 'item',
  //     //   icon: 'key',
  //     //   url: '/system/access-control',
  //     // },
  //     // {
  //     //   id: 'user-accounts',
  //     //   title: 'User Accounts',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.USER_ACCOUNTS.TITLE',
  //     //   type: 'item',
  //     //   icon: 'supervisor_account',
  //     //   url: '/system/user-accounts',
  //     // },
  //     // {
  //     //   id: 'audit-management',
  //     //   title: 'Audit Trail',
  //     //   translate: 'NAV.SYSTEMADMINISTRATION.AUDIT_TRAIL.TITLE',
  //     //   type: 'item',
  //     //   icon: 'receipt_long',
  //     //   url: '/account/audit-management',
  //     // },
  //   ],
  // },
  // {
  //   id: 'account',
  //   title: 'Account Management',
  //   translate: 'NAV.ACCOUNTMANAGEMENT.TITLE',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'user-management',
  //       title: 'User Management',
  //       translate: 'NAV.ACCOUNTMANAGEMENT.USER_MANAGEMENT.TITLE',
  //       type: 'item',
  //       icon: 'supervisor_account',
  //       url: '/account/user-management',
  //     },
  //   ],
  // },
  // {
  //     id       : 'vehicleManagement',
  //     title    : 'Vehicle Management',
  //     translate: 'NAV.VEHICLEMANAGEMENT.TITLE',
  //     type     : 'group',
  //     children : [
  //         // {
  //         //     id       : 'Vehicle List',
  //         //     title    : 'Vehicle List',
  //         //     translate: 'NAV.MASTERDATA.VEHICLE LIST.TITLE',
  //         //     type     : 'item',
  //         //     // icon     : 'email',
  //         //     url      : '/account/master-data/vehicles/1',
  //         //     // badge    : {
  //         //     //     title    : '25',
  //         //     //     translate: 'NAV.SAMPLE.BADGE',
  //         //     //     bg       : '#F44336',
  //         //     //     fg       : '#FFFFFF'
  //         //     // }
  //         // },
  //         // {
  //         //     id       : 'tracking',
  //         //     title    : 'Tracking',
  //         //     translate: 'NAV.MASTERDATA.DRIVER LIST.TITLE',
  //         //     type     : 'item',
  //         //     // icon     : 'map',
  //         //     url      : '/account/master-data/drivers/1',
  //         //     // badge    : {
  //         //     //     title    : '',
  //         //     //     translate: 'NAV.TRACKING.BADGE',
  //         //     //     bg       : '#2D323E',
  //         //     //     fg       : '#FFFFFF'
  //         //     // }
  //         // },
  //         // {
  //         //     id       : 'device-monitoring',
  //         //     title    : 'Device Monitoring',
  //         //     translate: 'NAV.VEHICLEMANAGEMENT.DEVICE_MONITORING.TITLE',
  //         //     type     : 'item',
  //         //     icon     : 'devices',
  //         //     url      : '/apps/device-monitoring',
  //         //     // badge    : {
  //         //     //     title    : '',
  //         //     //     translate: 'NAV.TRACKING.BADGE',
  //         //     //     bg       : '#2D323E',
  //         //     //     fg       : '#FFFFFF'
  //         //     // }
  //         // },
  //         // {
  //         //     id       : 'report-log',
  //         //     title    : 'Report log',
  //         //     translate: 'NAV.VEHICLEMANAGEMENT.REPORT_LOG.TITLE',
  //         //     type     : 'item',
  //         //     icon     : 'insert_drive_file',
  //         //     url      : '/apps/report-log',
  //         // },
  //         // {
  //         //     id       : 'key-performance',
  //         //     title    : 'Key Performance',
  //         //     translate: 'NAV.VEHICLEMANAGEMENT.KEY_PERFORMANCE.TITLE',
  //         //     type     : 'item',
  //         //     icon     : 'bar_chart',
  //         //     url      : '/apps/key-performance',
  //         // }
  //         // {
  //         //     id       : 'driver-behavior',
  //         //     title    : 'Driver Behavior',
  //         //     translate: 'NAV.VEHICLEMANAGEMENT.DRIVER_BEHEVIOR.TITLE',
  //         //     type     : 'item',
  //         //     icon     : 'person',
  //         //     url      : '/apps/driver-behavior',
  //         // },
  //     ]
  // },
  // {
  //     id       : 'setting',
  //     title    : 'Setting',
  //     translate: 'NAV.SETTINGS.TITLE',
  //     type     : 'group',
  //     children : [
  //         {
  //             id       : 'device-monitoring',
  //             title    : 'User Management',
  //             translate: 'NAV.SETTINGS.PROFILE.TITLE',
  //             type     : 'item',
  //             icon     : 'supervisor_account',
  //             url      : '/account/user-management',
  //         }
  //     ]
  // }
];
