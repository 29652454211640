export enum RseStatus {
  offline = 'offline',
  online = 'online',
  activated = 'activated',
  inactive = 'inactive',
  active = 'active',
  fail = 'fail',
}

export const RSE_ACTIVE = 'active';
export const RSE_INACTIVE = 'inactive';
export const RSE_FAIL = 'fail';
