import intersections from '@constant/intersections';

export function getIntersectionObject(id) {
  const intersectionObject = intersections[id] ?? undefined;
  if (intersectionObject) {
    return intersectionObject;
  }
  return undefined;
}

export function getIntersectionName(id) {
  const intersectionObject = intersections[id] ?? undefined;
  if (intersectionObject) {
    const { name } = intersectionObject;
    return name;
  }
  return undefined;
}

export function getIntersectionJunctionCode(id) {
  const intersectionObject = intersections[id] ?? undefined;
  if (intersectionObject) {
    const { junctionCode } = intersectionObject;
    if (junctionCode) {
      return junctionCode;
    }
    return undefined;
  }
  return undefined;
}

export function formatHospitalJunctionCode(id) {
  const intersectionObject = intersections[id] ?? undefined;
  if (intersectionObject) {
    const { junctionCode, hospitalCode } = intersectionObject;
    let text = '';
    if (hospitalCode) {
      text += `${hospitalCode} `;
    }
    if (junctionCode) {
      return `${text}J${junctionCode}`;
    }
    return undefined;
  }
  return undefined;
}
