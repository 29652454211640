<h1 matDialogTitle class="header">
  <mat-icon fontSet="material-icons-outlined">timer</mat-icon>
  <span class="ml-12">Session Timeout</span>
</h1>
<div mat-dialog-content class="content">
  <div class="message">
    You have been inactive for a while. For your security, you will be
    automatically signed out after a certain period of time.
  </div>

  <div class="timer">
    Your session expires in
    <b class="warning">{{ count }} seconds.</b>
  </div>
</div>

<div mat-dialog-actions class="footer">
  <button
    mat-stroked-button
    color="warn"
    (click)="dialogRef.close(false)"
    class="action-button mr-12">
    <b>Log Out</b>
  </button>
  <button
    mat-raised-button
    class="action-button"
    color="primary"
    (click)="dialogRef.close(true)">
    Continue Session
  </button>
</div>
