import { ApplicationRef, Injectable } from '@angular/core';
// import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { freeTextMessageData } from '../others/data-types';
// import { Subject, Observable, Subscription } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { Overlay } from '@angular/cdk/overlay';
// import { Subject } from 'rxjs';
// import { MatSpinner } from '@angular/material';
// import { ComponentPortal } from '@angular/cdk/portal';
// import projectConfig from '../../shared/others/project.config.json';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseInformDialogComponent } from '@fuse/components/inform-dialog/inform-dialog.component';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeoutDialogComponent } from '@components/timeout-dialog/timeout-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public mqttSource = new Subject<any>();
  public mqtt$ = this.mqttSource.asObservable();

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  idleEnd;
  idleTimeout;
  idleStart;
  idleTimeoutWarning;
  idleKeepAlive;

  constructor(
    private httpClient: HttpClient,
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _appRef: ApplicationRef,
    private idle: Idle,
    private keepalive: Keepalive,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    console.log('common service init');
    this.onIdle();
    // idle.watch();
    // // sets an idle timeout of 5 seconds, for testing purposes.
    // // idle.setIdle(900);
    // idle.setIdle(5);

    // // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    // idle.setTimeout(60);
    // // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => {
    //   // this.idleState = 'No longer idle11.'
    // });

    // idle.onTimeout.subscribe(() => {
    //     // this.idleState = 'Timed out!';
    //     this.timedOut = true;
    // });

    // idle.onIdleStart.subscribe(() => {
    //   // this.idleState = 'You\'ve gone idle!'
    // });

    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   // this.idleState = 'You will time out in ' + countdown + ' seconds!'
    // });

    // // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    // keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  // commonPostAction(urlAction, data) {
  //   let url = this.configService.apiServerUrl + urlAction;

  //   return this.httpClient.post(url, data).pipe(
  //     map((data: any) => {
  //       return data;
  //     })
  //   );
  // }

  commonPostFullUrlAction(urlAction, data) {
    const headerDict = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token') + '',
      'logged-in-user': localStorage.getItem('email'),
      'ctry-code': 'SG',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.post(urlAction, data, requestOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  commonGetFullUrlAction(urlAction) {
    const headerDict = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'logged-in-user': localStorage.getItem('email'),
      'ctry-code': 'SG',
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    // let url = this.configService.apiServerUrl + urlAction;
    return this.httpClient.get(urlAction, requestOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  commonDeleteFullUrlAction(urlAction, data) {
    const headerDict = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'logged-in-user': localStorage.getItem('email'),
      'ctry-code': 'SG',
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient
      .delete(urlAction, { body: data, ...requestOptions })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  commonNodePostFullUrlAction(urlAction, data) {
    // const headerDict = {
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer '+localStorage.getItem('access_token')+'',
    //   'logged-in-user': localStorage.getItem('email'),
    //   'ctry-code': 'SA'
    // }
    // const requestOptions = {
    //   headers: new HttpHeaders(headerDict),
    // };
    // return this.httpClient.post(urlAction, data, requestOptions).pipe(
    //   map((data: any) => {
    //     return data;
    //   })
    // );

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient.post(urlAction, data, { headers: headers }).pipe(
      map((data: any) => {
        return data;
      })
    );
    // .subscribe((response: any) => {
    //       return response;
    // })
  }

  onIdle() {
    this.idle.watch();
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idleTimer); // 30 mins
    // this.idle.setIdle(5);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(environment.sessionExpiry);
    // this.idle.setTimeout(21600); //6hrs

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idleTimeout = this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      this.authLogout();
    });

    this.idleEnd = this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this._dialog.closeAll();
      this.idle.stop();
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
      // this._appRef.tick();
    });

    this.idleStart = this.idle.onIdleStart.subscribe(() => {
      if (!this._auth.isAuthenticated()) {
        return false;
      }
      // disable interrupts - resume interrupt once we click continue
      this.idle.clearInterrupts();
      // this.idle.watch();
      this.idleState = "You've gone idle! onIdle func";
      this._dialog.closeAll();

      const dialogRef = this._dialog.open(TimeoutDialogComponent, {
        data: {},
        disableClose: true,
        // height: '400px',
        width: '350px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this.reset();
          this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
          this.idle.watch();
          this.idleState = 'Started.';
          this.timedOut = false;
          // clearTimeout(autoLogout);
          const dataBody = {
            username: localStorage.getItem('email'),
          };
          this.createAuthActivity(dataBody);
        } else if (result === false) {
          this.authLogout();
          this._dialog.open(FuseInformDialogComponent, {
            data: {
              header: 'Session Expired',
              message: 'You will be redirected to the login page.',
            },
            disableClose: true,
            // height: '400px',
            width: '250px',
          });
        }
      });
    });

    this.idleTimeoutWarning = this.idle.onTimeoutWarning.subscribe(
      countdown => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
      }
    );

    // sets the ping interval to 120 seconds
    if (!this.idleKeepAlive || this.idleKeepAlive?.closed) {
      this.keepalive.interval(environment.keepAliveTimer);

      this.idleKeepAlive = this.keepalive.onPing.subscribe(() => {
        if (this._auth.isAuthenticated()) {
          this.lastPing = new Date();
          // console.log('Keep alive: ', this.lastPing.toUTCString());
          this.createAuthActivity();
        }
      });

      this.keepalive.start();
    }
  }

  createAuthActivity(data?) {
    // var dataBody = {
    //     "userEmail" : data.username,
    //     "lastActivity": moment().format("DD/MM/YYYY HH:mm")
    // };

    // var urlAction = environment.nodeApiBaseUri + '/authActivity/findActivityByEmail';
    // var urlAction = environment.nodeApiBaseUri + '/authActivity/create';
    const urlAction = environment.apiUserUri + '/updateUserLastActiveDateTime';

    // const headers = new HttpHeaders().set(
    // 	"Content-Type",
    // 	"application/json"
    // );

    // this._httpClient
    //     .post(urlAction, dataBody, {
    //         headers: headers,
    //     })
    //     .subscribe((response: any) => {
    //         console.log(response);
    // })

    this.commonPostFullUrlAction(urlAction, '').subscribe(
      data => {
        // console.log(data);
      },
      error => {
        console.log(error);
      }
    );
  }

  isLogoutInProgress = false;
  authLogout() {
    // var dataBodyEmail = {
    //     "userEmail" : localStorage.getItem('email'),
    //     "isLogin": false
    // };

    // var urlAction = environment.nodeApiBaseUri + '/authActivity/update';
    const urlAction = environment.apiUserUri + '/logoutUser';

    const token = localStorage.getItem('access_token');
    if (token) {
      this.isLogoutInProgress = true;
      this.commonPostFullUrlAction(urlAction, '').subscribe(account => {
        // this.idleStart?.unsubscribe?.();
        // this.idleEnd?.unsubscribe?.();
        // this.idleTimeout?.unsubscribe?.();
        // this.idleTimeoutWarning?.unsubscribe?.();
        // this.idleKeepAlive?.unsubscribe?.();
        this._dialog.closeAll();
        // this._auth.logout();

        this.isLogoutInProgress = false;
      });
      this.idleStart?.unsubscribe?.();
      this.idleEnd?.unsubscribe?.();
      this.idleTimeout?.unsubscribe?.();
      this.idleTimeoutWarning?.unsubscribe?.();
      this.idleKeepAlive?.unsubscribe?.();
      this.keepalive.stop();
      this._dialog.closeAll();
      this._auth.logout();
    } else {
      this.idleStart?.unsubscribe?.();
      this.idleEnd?.unsubscribe?.();
      this.idleTimeout?.unsubscribe?.();
      this.idleTimeoutWarning?.unsubscribe?.();
      this.idleKeepAlive?.unsubscribe?.();
      this._auth.logout();
      this.keepalive.stop();
      this._dialog.closeAll();
      this.isLogoutInProgress = false;
    }
  }

  errorHandling(errorData) {
    if (errorData.status === 400) {
      return errorData.error.response;
    } else if (
      errorData.status === 401 &&
      errorData.url.split('/')[4] === 'oauth'
    ) {
      return errorData.error.error_description;
    }
    // else if(errorData.status === 401) {
    //   console.log('common', errorData);
    //   this._snackBar.open('Token expired, please relogin.', null, {
    //     duration: 5000,
    //     panelClass: 'custom-snack-bar-panel-error'
    //   });
    //   // this.authLogout();
    // }
    else if (errorData.status === 403) {
      this._snackBar.open(errorData.error.response, null, {
        duration: 5000,
        panelClass: 'custom-snack-bar-panel-error',
      });
      this.authLogout();
    } else {
      // if(errorData.status === 400) {
      this._snackBar.open(
        'Something went wrong, please try again later.',
        null,
        {
          duration: 5000,
          panelClass: 'custom-snack-bar-panel-error',
        }
      );
    }
  }

  isProjectName(data, currentProject): boolean {
    if (data === currentProject) {
      return true;
    }
    return false;
  }
}
