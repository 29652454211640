import { NgModule } from '@angular/core';
import { RouterModule, mapToCanActivate } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

// import { TrackingComponent } from './tracking.component';
// import { BusDetailsComponent } from './bus-details/bus-details.component';
// import { BusDetailsLinkerComponent } from './bus-details-linker/bus-details-linker.component';
// import { SwitchRouteComponent } from './switch/switch.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FuseSidebarModule } from '@fuse/components';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';//'ngx-mqtt/src/mqtt.module';
// import { IMqttServiceOptions } from 'ngx-mqtt/src/mqtt.model';
// import { environment } from 'environments/environment';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthGuard } from '@fuse/services/auth-guard.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
// import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UserManagementComponent } from './user-management/user-management.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddAccountComponent } from './user-management/add-account/add-account.component';
import { ChangePasswordComponent } from './user-management/change-password/change-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MassImportComponent } from './user-management/mass-import/mass-import.component';

const routes = [
  {
    path: 'account/user-management',
    canActivate: mapToCanActivate([AuthGuard]),
    component: UserManagementComponent,
  },
];

@NgModule({
  declarations: [
    UserManagementComponent,
    AddAccountComponent,
    ChangePasswordComponent,
    MassImportComponent,
    // BusDetailsComponent,
    // BusDetailsLinkerComponent,
    // SwitchRouteComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    TranslateModule,

    FuseSharedModule,
    FuseSidebarModule,

    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatExpansionModule,

    DragDropModule,

    // MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,

    MatTableExporterModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,

    // MatPasswordStrengthModule,
    MatPasswordStrengthModule.forRoot(),

    BrowserAnimationsModule,
    BrowserModule,

    MatDatepickerModule,
  ],
  exports: [UserManagementComponent],
})
export class AccountManagementModule {}
