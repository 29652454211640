export const ntfghRSEs = [
  {
    id: 'NTFGH_J1',
    displayName: 'Junction 1',
    description: 'Junction 1',
    hospital: 'NTFGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.744189,
      latitude: 1.333668,
    },
    children: [
      {
        id: 'NTFGH_J1_RSE1',
        displayName: 'RSE 1',
        description: 'J1 RSE 1',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.74409,
          latitude: 1.333776,
        },
      },
      {
        id: 'NTFGH_J1_RSE2',
        displayName: 'RSE 2',
        description: 'J1 RSE 2',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.744149,
          latitude: 1.333547,
        },
        directions: {
          1: [2],
          3: [0, 1],
        },
      },
    ],
  },
  {
    id: 'NTFGH_J2',
    displayName: 'Junction 2',
    description: 'Junction 2',
    hospital: 'NTFGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.744796,
      latitude: 1.335066,
    },
    children: [
      {
        id: 'NTFGH_J2_RSE3',
        displayName: 'RSE 3',
        description: 'J2 RSE 3',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.744796,
          latitude: 1.335066,
        },
        directions: {
          3: [1, 2],
        },
      },
    ],
  },
  {
    id: 'NTFGH_J3',
    displayName: 'Junction 3',
    description: 'Junction 3',
    hospital: 'NTFGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.74764,
      latitude: 1.336543,
    },
    children: [
      {
        id: 'NTFGH_J3_RSE4',
        displayName: 'RSE 4',
        description: 'J3 RSE 4',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.747754,
          latitude: 1.336577,
        },
        directions: {
          4: [1, 2],
        },
      },
      {
        id: 'NTFGH_J3_RSE5',
        displayName: 'RSE 5',
        description: 'J3 RSE 5',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.747514,
          latitude: 1.336585,
        },
      },
    ],
  },
  {
    id: 'NTFGH_J4',
    displayName: 'Junction 4',
    description: 'Junction 4',
    hospital: 'NTFGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.746831,
      latitude: 1.331463,
    },
    children: [
      {
        id: 'NTFGH_J4_RSE6',
        displayName: 'RSE 6',
        description: 'J4 RSE 6',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.746763,
          latitude: 1.331281,
        },
      },
      {
        id: 'NTFGH_J4_RSE7',
        displayName: 'RSE 7',
        description: 'J4 RSE 7',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.746981,
          latitude: 1.331258,
        },
        directions: {
          7: [1, 2],
        },
      },
    ],
  },
];

export const ktphRSEs = [
  {
    id: 'KTPH_J1',
    displayName: 'Junction 1',
    description: 'Junction 1',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.835126,
      latitude: 1.424723,
    },
    children: [
      {
        id: 'KTPH_J1_RSE1',
        displayName: 'RSE 1',
        description: 'J1 RSE 1',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.835197,
          latitude: 1.424617,
        },
        directions: {
          4: [1],
        },
      },
      {
        id: 'KTPH_J1_RSE2',
        displayName: 'RSE 2',
        description: 'J1 RSE 2',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.835018,
          latitude: 1.424869,
        },
      },
    ],
  },
  {
    id: 'KTPH_J2',
    displayName: 'Junction 2',
    description: 'Junction 2',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.834987,
      latitude: 1.422952,
    },
    children: [
      {
        id: 'KTPH_J2_RSE3',
        displayName: 'RSE 3',
        description: 'J2 RSE 3',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.83488,
          latitude: 1.422808,
        },
        directions: {
          0: [2],
          3: [1],
        },
      },
      {
        id: 'KTPH_J2_RSE4',
        displayName: 'RSE 4',
        description: 'J2 RSE 4',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.835177,
          latitude: 1.423491,
        },
      },
      {
        id: 'KTPH_J2_RSE5',
        displayName: 'RSE 5',
        description: 'J2 RSE 5',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.83445,
          latitude: 1.423017,
        },
      },
    ],
  },
  {
    id: 'KTPH_J3',
    displayName: 'Junction 3',
    description: 'Junction 3',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.833755,
      latitude: 1.418206,
    },
    children: [
      {
        id: 'KTPH_J3_RSE6',
        displayName: 'RSE 6',
        description: 'J3 RSE 6',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.833186,
          latitude: 1.41842,
        },
      },
      {
        id: 'KTPH_J3_RSE7',
        displayName: 'RSE 7',
        description: 'J3 RSE 7',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.833818,
          latitude: 1.418639,
        },
        directions: {
          1: [1],
        },
      },
      {
        id: 'KTPH_J3_RSE8',
        displayName: 'RSE 8',
        description: 'J3 RSE 8',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.83396,
          latitude: 1.418344,
        },
      },
      {
        id: 'KTPH_J3_RSE9',
        displayName: 'RSE 9',
        description: 'J3 RSE 9',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.834032,
          latitude: 1.418157,
        },
      },
    ],
  },
  {
    id: 'KTPH_J4',
    displayName: 'Junction 4',
    description: 'Junction 4',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.836757,
      latitude: 1.422934,
    },
    children: [
      {
        id: 'KTPH_J4_RSE10',
        displayName: 'RSE 10',
        description: 'J4 RSE 10',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.836536,
          latitude: 1.422829,
        },
      },
      {
        id: 'KTPH_J4_RSE11',
        displayName: 'RSE 11',
        description: 'J4 RSE 11',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.836789,
          latitude: 1.423071,
        },
        directions: {
          2: [1],
        },
      },
      {
        id: 'KTPH_J4_RSE12',
        displayName: 'RSE 12',
        description: 'J4 RSE 12',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.836745,
          latitude: 1.422314,
        },
      },
    ],
  },
  {
    id: 'KTPH_J5',
    displayName: 'Junction 5',
    description: 'Junction 5',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.83824,
      latitude: 1.423131,
    },
    children: [
      {
        id: 'KTPH_J5_RSE13',
        displayName: 'RSE 13',
        description: 'J5 RSE 13',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.838587,
          latitude: 1.423309,
        },
        directions: {
          5: [1, 2],
        },
      },
    ],
  },
  {
    id: 'KTPH_J6',
    displayName: 'Junction 6',
    description: 'Junction 6',
    hospital: 'KTPH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.839925,
      latitude: 1.424408,
    },
    children: [
      {
        id: 'KTPH_J6_RSE14',
        displayName: 'RSE 14',
        description: 'J6 RSE 14',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.839853,
          latitude: 1.424302,
        },
        directions: {
          5: [1],
          7: [0],
        },
      },
      {
        id: 'KTPH_J6_RSE15',
        displayName: 'RSE 15',
        description: 'J6 RSE 15',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.840026,
          latitude: 1.424479,
        },
      },
      {
        id: 'KTPH_J6_RSE16',
        displayName: 'RSE 16',
        description: 'J6 RSE 16',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.84009,
          latitude: 1.424353,
        },
      },
    ],
  },
];

export const cghRSEs = [
  {
    id: 'CGH_J1',
    displayName: 'Junction 1',
    description: 'Junction 1',
    hospital: 'CGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.952263,
      latitude: 1.341141,
    },
    children: [
      {
        id: 'CGH_J1_RSE1',
        displayName: 'RSE 1',
        description: 'J1 RSE 1',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.952134,
          latitude: 1.34124,
        },
      },
      {
        id: 'CGH_J1_RSE2',
        displayName: 'RSE 2',
        description: 'J1 RSE 2',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.952392,
          latitude: 1.341357,
        },
      },
      {
        id: 'CGH_J1_RSE3',
        displayName: 'RSE 3',
        description: 'J1 RSE 3',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.952324,
          latitude: 1.341073,
        },
        directions: {
          5: [1],
        },
      },
    ],
  },
  {
    id: 'CGH_J2',
    displayName: 'Junction 2',
    description: 'Junction 2',
    hospital: 'CGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.95099,
      latitude: 1.34009,
    },
    children: [
      {
        id: 'CGH_J2_RSE4',
        displayName: 'RSE 4',
        description: 'J2 RSE 4',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.951068,
          latitude: 1.340015,
        },
        directions: {
          5: [1, 2],
        },
      },
    ],
  },
  {
    id: 'CGH_J3',
    displayName: 'Junction 3',
    description: 'Junction 3',
    hospital: 'CGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.949261,
      latitude: 1.33874,
    },
    children: [
      {
        id: 'CGH_J3_RSE5',
        displayName: 'RSE 5',
        description: 'J3 RSE 5',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.949292,
          latitude: 1.338906,
        },
      },
      {
        id: 'CGH_J3_RSE6',
        displayName: 'RSE 6',
        description: 'J3 RSE 6',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.949182,
          latitude: 1.338838,
        },
        directions: {
          1: [1],
        },
      },
    ],
  },
  {
    id: 'CGH_J4',
    displayName: 'Junction 4',
    description: 'Junction 4',
    hospital: 'CGH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.952412,
      latitude: 1.335076,
    },
    children: [
      {
        id: 'CGH_J4_RSE7',
        displayName: 'RSE 7',
        description: 'J4 RSE 7',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.952615,
          latitude: 1.335054,
        },
      },
      {
        id: 'CGH_J4_RSE8',
        displayName: 'RSE 8',
        description: 'J4 RSE 8',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.952746,
          latitude: 1.335117,
        },
      },
      {
        id: 'CGH_J4_RSE9',
        displayName: 'RSE 9',
        description: 'J4 RSE 9',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.952195,
          latitude: 1.335106,
        },
        directions: {
          7: [1],
        },
      },
    ],
  },
];

export const nuhRSEs = [
  {
    id: 'NUH_J1',
    displayName: 'Junction 1',
    description: 'Junction 1',
    hospital: 'NUH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.783648,
      latitude: 1.291632,
    },
    children: [
      {
        id: 'NUH_J1_RSE1',
        displayName: 'RSE 1',
        description: 'J1 RSE 1',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.783758,
          latitude: 1.291542,
        },
      },
      {
        id: 'NUH_J1_RSE2',
        displayName: 'RSE 2',
        description: 'J1 RSE 2',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.783569,
          latitude: 1.291735,
        },
        directions: {
          1: [1],
        },
      },
    ],
  },
  {
    id: 'NUH_J2',
    displayName: 'Junction 2',
    description: 'Junction 2',
    hospital: 'NUH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.784199,
      latitude: 1.292138,
    },
    children: [
      {
        id: 'NUH_J2_RSE3',
        displayName: 'RSE 3',
        description: 'J2 RSE 3',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.784318,
          latitude: 1.292133,
        },
        directions: {
          0: [0, 1],
          7: [1, 2],
        },
      },
    ],
  },
  {
    id: 'NUH_J3',
    displayName: 'Junction 3',
    description: 'Junction 3',
    hospital: 'NUH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.785288,
      latitude: 1.293266,
    },
    children: [
      {
        id: 'NUH_J3_RSE4',
        displayName: 'RSE 4',
        description: 'J3 RSE 4',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.785296,
          latitude: 1.293075,
        },
      },
      {
        id: 'NUH_J3_RSE5',
        displayName: 'RSE 5',
        description: 'J3 RSE 5',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.785497,
          latitude: 1.293167,
        },
        directions: {
          7: [0, 1],
        },
      },
    ],
  },
  {
    id: 'NUH_J4',
    displayName: 'Junction 4',
    description: 'Junction 4',
    hospital: 'NUH',
    type: 'junction',
    isVisible: false,
    location: {
      longitude: 103.785924,
      latitude: 1.295463,
    },
    children: [
      {
        id: 'NUH_J4_RSE6',
        displayName: 'RSE 6',
        description: 'J4 RSE 6',
        isVisible: false,
        type: 'rse',
        location: {
          longitude: 103.78601,
          latitude: 1.295339,
        },
        directions: {
          2: [2],
        },
      },
      {
        id: 'NUH_J4_RSE7',
        displayName: 'RSE 7',
        description: 'J4 RSE 7',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.785849,
          latitude: 1.295391,
        },
      },
      {
        id: 'NUH_J4_RSE8',
        displayName: 'RSE 8',
        description: 'J4 RSE 8',
        isVisible: false,
        type: 'relay',
        location: {
          longitude: 103.785547,
          latitude: 1.295747,
        },
      },
    ],
  },
];

export const rseData = [
  {
    id: 'showHideAll',
    displayName: 'Show All RSE',
    description: 'Show All RSE',
    isVisible: false,
  },
  {
    id: 'ntfghAll',
    displayName: 'NTFGH',
    description: 'Ng Teng Fong General Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.74568,
      latitude: 1.333683,
    },
    children: ntfghRSEs,
  },
  {
    id: 'kkAll',
    displayName: 'KK',
    description: "Kk Women's And Children's Hospital",
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.847129,
      latitude: 1.30975,
    },
  },
  {
    id: 'ttshAll',
    displayName: 'TTSH',
    description: 'Tan Tock Seng Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.845812,
      latitude: 1.321364,
    },
  },
  {
    id: 'nuhAll',
    displayName: 'NUH',
    description: 'National University Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.78289,
      latitude: 1.29464,
    },
    children: nuhRSEs,
  },
  {
    id: 'cghAll',
    displayName: 'CGH',
    description: 'Changi General Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.949575,
      latitude: 1.340175,
    },
    children: cghRSEs,
  },
  {
    id: 'ktphAll',
    displayName: 'KTPH',
    description: 'Khoo Teck Puat Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.837815,
      latitude: 1.424067,
    },
    children: ktphRSEs,
  },
  {
    id: 'whcAll',
    displayName: 'WHC',
    description: 'Woodlands Health Campus',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.7935399,
      latitude: 1.4247337,
    },
  },
  {
    id: 'skghAll',
    displayName: 'SKGH',
    description: 'Sengkang General Hospital',
    isVisible: false,
    type: 'hospital',
    location: {
      longitude: 103.893582,
      latitude: 1.39565,
    },
  },
];
