import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  Ambulance = 'ambulance',
  RseCircle = 'rse-circle',
  RseIcon = 'rse-icon',
  RseCircleBrown = 'rse-circle-brown',
  RseCircleGray = 'rse-circle-gray',
  RseCircleGreen = 'rse-circle-green',
  RseMarker = 'rse-marker',
  RseMarkerBrown = 'rse-marker-brown',
  RseMarkerGray = 'rse-marker-gray',
  RseMarkerGreen = 'rse-marker-green',
  RelayCircle = 'relay-circle',
  RelayCircleBrown = 'relay-circle-brown',
  // RelayCircleGreen = 'relay-circle-green',
  RelayCircleGray = 'relay-circle-gray',
  RelayMarker = 'relay-marker',
  RelayMarkerBrown = 'relay-marker-brown',
  RelayMarkerGreen = 'relay-marker-green',
  RelayMarkerGray = 'relay-marker-gray',
  RelayBrown = 'relay-brown',
  RelayGray = 'relay-gray',
  RelayGreen = 'relay-green',
  Incident = 'incident',
  IncidentIcon = 'incident-icon',
  NavigationBlue = 'navigation-blue',
  NavigationGreen = 'navigation-green',
  NavigationRed = 'navigation-red',
  NavigationGray = 'navigation-gray',
  ArrowLeft = 'arrow-0',
  ArrowMid = 'arrow-1',
  ArrowRight = 'arrow-2',
  ArrowN = 'arrow-n',
  ArrowNE = 'arrow-ne',
  ArrowNW = 'arrow-nw',
  ArrowNU = 'arrow-nu',
  ArrowE = 'arrow-e',
  ArrowEN = 'arrow-en',
  ArrowES = 'arrow-es',
  ArrowEU = 'arrow-eu',
  ArrowS = 'arrow-s',
  ArrowSE = 'arrow-se',
  ArrowSW = 'arrow-sw',
  ArrowSU = 'arrow-su',
  ArrowW = 'arrow-w',
  ArrowWS = 'arrow-ws',
  ArrowWN = 'arrow-wn',
  ArrowWU = 'arrow-wu',
  SelectWindow = 'select_window',
  Hospital = 'hospital-pin',
  FireStation = 'firestation-pin',
  MarkerLocation = 'marker-location',
}

@Injectable({
  providedIn: 'root',
})
export class IconLoaderService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  private isInit = false;
  private imageRegistry = {};

  public get registry() {
    return this.imageRegistry;
  }

  public registerIcons(): void {
    if (!this.isInit) {
      this.loadIcons(Object.values(Icons), 'assets/images/icons');
      this.isInit = true;
    }
  }

  public getIcon(key: string) {
    return this.imageRegistry[key] ?? undefined;
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${iconUrl}/${key}.svg`
        )
      );

      // preload SVG assets and store in memory
      const img = new Image();
      img.src = `${iconUrl}/${key}.svg`;
      this.imageRegistry[key] = img;
    });
  }
}
