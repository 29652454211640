<div
  #notificationAlert
  class="alert-container mat-elevation-z2"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
  *ngIf="showAlert"
  [@fastFadeInOut]="showAlert ? 'open' : 'close'">
  <div class="alert-header" [ngClass]="type">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="mr-8" fontSet="material-icons-outlined">{{
        getIcon()
      }}</mat-icon>
      {{ title }}
    </div>
    <button
      mat-icon-button
      aria-label="Close Alert"
      class="close-icon-button"
      (click)="hide()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="date" class="date-text">
    {{ date | momentPipe: 'DD/MM/YYYY HH:mm' }}
  </div>
  <div class="alert-body">
    {{ message }}
  </div>
</div>
