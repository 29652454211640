export enum ColorData {
  eBlue = '#566fcf',
  eGreen = '#029002',
  eYellow = '#FFC000	',
  eRed = '#EE4B2B',
  eGray = '#9598a8',
  eOrange = '#df5E42',
  eTeal = '#009688',
  eBlack = '#000',
  ePink = '#f98182',
  eBrown = '#8C5115',
  eCobaltBlue = '#0047AB',
}

export enum MarkerColor {
  blue = 'blue',
  orange = 'orange',
  green = 'green',
  black = 'black',
  pink = 'pink',
  brown = 'brown',
  yellow = 'yellow',
  cobaltBlue = 'cobaltBlue',
  gray = 'gray',
}

export enum MarkerColorCode {
  red = '#EE4B2B',
  blue = '#566fcf',
  orange = '#f77f4d',
  green = '#029002',
  black = '#000',
  pink = '#f98182',
  brown = '#8C5115',
  yellow = '#FFC000',
  cobaltBlue = '#0047AB',
  gray = '#9598a8',
}

export enum MapType {
  Navigation = 'ArcGIS:Navigation',
  'OSM-ArcGIS' = 'OSM:Standard',
  'OSM-Static' = 'OSM-Static',
  Satellite = 'ArcGIS:Imagery:Standard',
}

export enum StaticMapType {
  'OSM-Static' = 'OSM-Static',
}

export const DateFormatSG = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export enum MqttType {
  eVehiclePosition = 'vehiclePosition',
  ePredefinedMessages = 'preDefinedMessage',
  eOffRoute = 'offRoute',
  etimeTable = 'timeTable',
}

export enum Direction {
  NORTH = 1,
  EAST = 2,
  SOUTH = 3,
  WEST = 4,
}

export enum ArrowForm {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2,
}
