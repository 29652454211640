<div class="notification-container" fxLayout="column-reverse">
  <!-- <div class="action">
        <button mat-raised-button (click)="addNotification()" class="mat-elevation-z2">Add notification</button>
    </div> -->
  <ng-container *ngFor="let notification of currentNotifications; index as i">
    <app-notification-alert
      [type]="notification.type"
      [title]="notification.title"
      [message]="notification.message"
      [date]="notification.date"
      [autoHide]="notification.autoHide"
      (disposeEvent)="dispose(i)">
    </app-notification-alert>
  </ng-container>
</div>
