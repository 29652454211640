<div
  class="user-mgt-group page-layout blank pl-24 pr-24 pb-24"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{
    suppressScrollX: true,
    updateOnRouteChange: true
  }">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>User Management</h1>
    <div>
      <button mat-raised-button (click)="onMassImport()">Import</button>
      <button mat-raised-button class="ml-16" (click)="onAddAccount()">
        Add User
      </button>
    </div>
  </div>
  <div>
    <div class="left-aligned">
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="onFilter($event)" />
      </mat-form-field>

      <button
        mat-raised-button
        type="button"
        class="ml-12"
        (click)="onRefresh()">
        Refresh
      </button>
    </div>

    <!-- <perfect-scrollbar #chatPS class="device-list" [config]="config" [scrollIndicators]="false"
        > -->
    <!-- <table mat-table [dataSource]="dataSource" matTableExporter matSort #exporter="matTableExporter"> -->

    <table
      matTableExporter
      mat-table
      [dataSource]="dataSource"
      class="common-table table-striped width-100 table-hover"
      #exporter="matTableExporter">
      <ng-container matColumnDef="nric">
        <th mat-header-cell *matHeaderCellDef>NRIC</th>
        <td mat-cell *matCellDef="let element">{{ element.nric }}</td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
      </ng-container>

      <ng-container matColumnDef="userEmail">
        <th mat-header-cell *matHeaderCellDef>Email Address</th>
        <td mat-cell *matCellDef="let element">{{ element.userEmail }}</td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef>Rank / Grade / Designation</th>
        <td mat-cell *matCellDef="let element">{{ element.designation }}</td>
      </ng-container>

      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef>
          Organization / Division / Unit
        </th>
        <td mat-cell *matCellDef="let element">{{ element.agency }}</td>
      </ng-container>

      <ng-container matColumnDef="isAccountDisabled">
        <th mat-header-cell *matHeaderCellDef>Account Disabled</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            type="checkbox"
            [checked]="element.isAccountDisabled"
            (change)="onCheckChange($event.checked, element, 'enabled')">
            {{ element.isAccountDisabled ? 'Yes' : 'No' }}
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">
          {{ element.roleName | titlecase }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="isAccountDeactivated">
            <th mat-header-cell *matHeaderCellDef >Account Deactivated</th>
            <td mat-cell *matCellDef="let element"> 
              <mat-checkbox type="checkbox" [checked]="element.isAccountDeactivated" (change)="onCheckChange($event.checked, element, 'activated')"> 
                {{element.isAccountDeactivated ? 'Yes' : 'No'}}
              </mat-checkbox>
            </td>
          </ng-container> -->

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button
            matTooltip="Reset Password"
            (click)="onResetPassword(element)"
            mat-icon-button>
            <mat-icon>fingerprint</mat-icon>
          </button>
          <button
            matTooltip="Edit Role"
            (click)="onAddAccount(element)"
            mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
          <button
            matTooltip="Delete User"
            (click)="onDelete(element)"
            mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <!-- </perfect-scrollbar> -->

    <mat-paginator
      [pageSize]="25"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
