import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { NotificationPanelService } from './notification-panel.service';
import { NotificationAlert } from 'app/shared/model/notification-alert';
import { AlertTypes } from 'app/shared/model/alert-types';


const audioSource = 'assets/sounds/alert.ogg';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPanelComponent {
  currentNotifications: NotificationAlert[] = [];
  counter: number = 1;
  constructor(
    private notificationPanelService: NotificationPanelService,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.notificationPanelService.notificationAlerts.subscribe((notification) => {
      const newNotification: NotificationAlert = {
        title: notification.title,
        type: notification.type,
        message: notification.message,
        date: notification.date,
        autoHide: notification.autoHide
      };
      this.currentNotifications.push(newNotification);
      this.cdr.detectChanges();
    });
  }

  addNotification() {
    const audioAlert = new Audio(audioSource);
    audioAlert.play();
    // this.currentNotifications.push({ 
    //   title: 'Title', 
    //   date: moment(), 
    //   message: 'Test Notification' + this.counter, 
    //   type: AlertTypes.Info,
    //   autoHide: true
    // });
    this.notificationPanelService.showInfoToast('title', 'Test Notification ' + this.counter, moment());

    this.counter++;
  }

  dispose(index: number) {
    this.currentNotifications.splice(index, 1);
    this.cdr.detectChanges();
  }
}
