import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import * as goodCaseData from 'app/shared/fakeData/goodCase.json';

const errorsList = [
  {
    id: 'registered',
    value: 'User Account already exists',
  },
  {
    id: 'duplicate',
    value: 'Duplicate IAMS User ID',
  },
  {
    id: 'invalidId',
    value: 'Invalid IAMS User ID',
  },
  {
    id: 'invalidRole',
    value: 'Invalid System Role',
  },
];

@Component({
  selector: 'app-mass-import',
  templateUrl: './mass-import.component.html',
  styleUrls: ['./mass-import.component.scss'],
})
export class MassImportComponent {
  constructor(
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<MassImportComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _snackBar: MatSnackBar
  ) {}

  files: any[] = [];
  csvFile: any;
  step = 0;
  records = 0;
  csvErrors: any[] = [];
  showErrors = false;
  results: any;
  running = false;

  onFileDropped($event) {
    this.prepareFile($event);
  }

  fileBrowseHandler(files) {
    this.prepareFile(files);
  }

  deleteFile() {
    this.csvFile = null;
  }

  prepareFile(files: Array<any>) {
    if (files.length > 1) {
      this.deleteFile();
      this._snackBar.open('Uploading more than one file is not allowed', null, {
        duration: 5000,
      });
    } else {
      const currentFile = files[0];
      if (currentFile.type !== 'text/csv') {
        this.deleteFile();
        this._snackBar.open('Please upload in CSV format', null, {
          duration: 5000,
        });
      } else {
        this.csvFile = files[0];
        this.running = true;
        this.processFile();
      }
    }
  }

  processFile() {
    this.step = 1;
    console.log('Current File', this.csvFile);

    // mock csv processing
    // actual csv upload API will replace this part of code
    // this part should be CSV validation first
    const csvName = this.csvFile.name;
    if (csvName === 'goodCase.csv') {
      setTimeout(() => {
        this.running = false;
        this.records = 0;
        this.results = [];
        // this.results = (goodCaseData as any).default;
      }, 2000);
    } else {
      // CSV has errors
      this.running = false;
      this.records = 8;
      this.csvErrors = [
        `${errorsList[0].value} at row 2`,
        `${errorsList[1].value} at row 4`,
        `${errorsList[3].value} at row 7`,
        `${errorsList[2].value} at row 10`,
        `${errorsList[2].value} at row 11`,
        `${errorsList[3].value} at row 11`,
      ];
      this.step = 3;
    }
  }

  onImport() {
    // call import API here
    this.step = 2;
    setTimeout(() => {
      this._snackBar.open('File uploaded', null, {
        duration: 5000,
      });
      this.dialogRef.close(this.results);
    }, 2000);
  }

  onShowErrors() {
    this.showErrors = true;
  }

  onRetry() {
    this.deleteFile();
    this.step = 0;
    this.records = 0;
    this.csvErrors = [];
    this.showErrors = false;
  }
}
