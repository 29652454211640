import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { RemoveWhiteSpacePipe } from './removeWhiteSpace.pipe';
import { MomentPipe } from './moment.pipe';
import { MomentUnixPipe } from './momentUnix.pipe';
import { GetPropertyPipe } from './getProperty.pipe';
import { MomentDurationPipe } from './momentDuration.pipe';
import { AddressPipe } from './address.pipe';
import { CallSignStatusPipe } from './callSignStatus.pipe';

@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    RemoveWhiteSpacePipe,
    MomentPipe,
    MomentDurationPipe,
    MomentUnixPipe,
    GetPropertyPipe,
    AddressPipe,
    CallSignStatusPipe,
  ],
  imports: [],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    RemoveWhiteSpacePipe,
    MomentPipe,
    MomentDurationPipe,
    MomentUnixPipe,
    GetPropertyPipe,
    AddressPipe,
    CallSignStatusPipe,
  ],
})
export class FusePipesModule {}
