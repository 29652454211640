import { hospitalList } from '@constant/hospitals';

export function getHospital(hospitalCode: string): string {
  if (!hospitalCode) return hospitalCode;
  const currHospital =
    hospitalList?.find(hospital => hospital.hospitalCode === hospitalCode)
      .code ?? hospitalCode;
  return currHospital;
}

export function getHospitalObject(hospitalCode: string) {
  return hospitalList?.find(hospital => hospital.hospitalCode === hospitalCode);
}
