import { ambulanceStatusCodes } from 'app/shared/constant/ambulanceStatus';
import { ColorData } from '@fuse/others/constant';
import { AmbulanceStatus } from 'app/shared/constant/ambulanceStatus';

export function getAmbulanceStatus(ambulanceStatusCode: number) {
  console.log(ambulanceStatusCode, ambulanceStatusCodes[ambulanceStatusCode]);
  if (!ambulanceStatusCode) return '';
  return ambulanceStatusCodes[ambulanceStatusCode] ?? ambulanceStatusCode;
}

export function getAmbulanceStyle(status) {
  // console.log('status', status);
  let statusColor = ColorData.eBlue;
  let icon = 'navigation-blue';
  switch (status) {
    // case 'activated':
    //   statusColor = ColorData.eGreen;
    //   icon = 'navigation-green';
    //   break;
    case 'tracking':
    case 'online':
    case AmbulanceStatus.base:
    case AmbulanceStatus.movingOut:
    case AmbulanceStatus.return:
      statusColor = ColorData.eBlue;
      icon = 'navigation-blue';
      break;
    case AmbulanceStatus.offDuty:
    case AmbulanceStatus.outOfService:
    case AmbulanceStatus.offline:
      statusColor = ColorData.eGray;
      icon = 'navigation-gray';
      break;
    case AmbulanceStatus.atHospital:
    case AmbulanceStatus.dispatched:
    case AmbulanceStatus.patient:
    case AmbulanceStatus.post:
    case AmbulanceStatus.responding:
    case AmbulanceStatus.scene:
    case AmbulanceStatus.toHospital:
    case 'activated':
    case 'auto':
    case 'manual':
      statusColor = ColorData.eRed;
      icon = 'navigation-red';
      break;
    default:
      statusColor = ColorData.eGray;
      icon = 'navigation-gray';
  }
  return { statusColor, icon };
}
