import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import OlFeature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import { allRSEsList } from 'app/shared/fakeData/rseListData';
import { location } from 'app/shared/constant/location';
import { ColorData } from '@fuse/others/constant';
import { RSE_ACTIVE, RseStatus } from 'app/shared/constant/rseStatus';
import { TrackingService, VECTOR_LAYER } from './tracking.service';
import {
  ICONS_PATH,
  createArrowIcons,
  ingressEgressIcons,
} from './trackingStyles';
import { IconLoaderService } from '@fuse/services/icon-loader.service';
import { CommsNotificationService } from 'app/shared/services/monitoring/comms-notification.service';
import {
  getHospitalObject,
  getIntersectionJunctionCode,
  updateFeatureProperty,
} from '@helpers';
import {
  TrackingRseMap,
  TrackingService as OpenApiTrackingService,
  SessionStatus,
  OperationStatus,
} from '@openapi/tps';
import { TYPE_RSE, TYPE_RELAY, ICON_RELAY, ICON_RSE } from '@constant/rse';
import { containsCoordinate } from 'ol/extent';
import { RELAY_LAYER_INDEX, RSE_LAYER_INDEX } from '@constant/layers';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Fill, Stroke, Text } from 'ol/style';
import createSquareFill from 'app/shared/helpers/squareFill';
import { environment } from 'environments/environment';

export const RSE_LAYER = TYPE_RSE;
export const RELAY_LAYER = TYPE_RELAY;

export type JunctionData = {
  id: string;
  name: string;
  description: string;
  hospital: string;
  type: string;
  location: location;
  children: RseData[];
};

export type RseData = {
  id: string;
  name: string;
  junctionId?: string;
  hospital?: string;
  description?: string;
  type: string | typeof TYPE_RELAY | typeof TYPE_RSE;
  location: location;
  status: RseStatus | string;
  directions?: {
    [key: number]: number[];
  };
};

export type RseStatusFilter = 'online' | 'offline' | 'all' | 'hide';

export type RseStatusObject = {
  junctionId: string;
  intersectionId?: string;
  rseId: string;
  timeStamp: string;
  direction?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | number | undefined;
  arrow?: 0 | 1 | 2 | undefined;
  status: 'online' | 'activated' | 'offline';
  type: string | typeof TYPE_RELAY | typeof TYPE_RSE;
  inboundApproach?: number;
  outboundApproach?: number;
};

@Injectable({
  providedIn: 'root',
})
export class RseTrackingService {
  arrowIcons;
  constructor(
    private _trackingService: TrackingService,
    private _iconLoaderService: IconLoaderService,
    private _commsNotificationService: CommsNotificationService,
    private _openApiTrackingService: OpenApiTrackingService,
    private _fuseSidebarService: FuseSidebarService
  ) {
    this.arrowIcons = createArrowIcons(ColorData.eGreen);
  }

  rseSource: VectorSource;
  relaySource: VectorSource;
  rseHospitalMarkers: any[] = [];
  relayHospitalMarkers: any[] = [];
  private rseHospitalMarkersSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  rseHospitalMarkers$: Observable<any> =
    this.rseHospitalMarkersSource.asObservable();
  rseLayer: VectorLayer<any>;
  rseLabelVisible = false;
  relayLayer: VectorLayer<any>;
  relayVisible = false;
  shadowStyle;

  rseStatusFilter: RseStatusFilter = 'all';

  async initRseLayer() {
    this.clearRseLayer();

    if (environment.allowSimulation) {
      allRSEsList.forEach((rseData: RseData) => {
        // [].forEach((rseData: RseData) => {
        const { junctionId, hospital, type } = rseData;
        const rseMarker = this.createRseMarker(rseData, hospital, junctionId);
        if (type === TYPE_RSE) {
          this.rseHospitalMarkers.push(rseMarker);
        } else {
          this.relayHospitalMarkers.push(rseMarker);
        }
      });
    }

    // rseHospitalList.forEach((hospital) => {
    //   const { name, code, junctions } = hospital;
    //   if (junctions && junctions?.length > 0) {
    //     junctions.forEach((junction: JunctionData) => {
    //       const { children, id: junctionId } = junction;
    //       // add junctions

    //       // add rses
    //       if (children && children?.length > 0) {
    //         children.forEach((rse: RseData) => {
    //           const rseMarker = this.createRseMarker(rse, code, junctionId);
    //           this.rseHospitalMarkers.push(rseMarker);
    //         });
    //       }
    //     });
    //   }
    // });

    const me = this;

    const rseList = await this.getRseTrackingMap();

    if (rseList?.length > 0) {
      rseList.forEach(rse => {
        const { hospital, type, intersectionId } = rse;
        const rseMarker = this.createRseMarker(rse, hospital, intersectionId);
        if (type === TYPE_RSE) {
          this.rseHospitalMarkers.push(rseMarker);
        } else {
          this.relayHospitalMarkers.push(rseMarker);
        }
      });
    }

    this.sendUpdateRseMarkers();

    this.shadowStyle = this._trackingService.getMarkerShadowStyle();
    this.rseSource = new VectorSource({
      features: me.rseHospitalMarkers,
    });

    this.rseLayer = new VectorLayer({
      source: me.rseSource,
      style: function (feature, resolution) {
        const isVisible = feature.get('visible');
        const featureName = feature.get('rseId') ?? feature.get('description');
        const junctionCode = feature.get('junctionCode');
        const featureColor = feature.get('color') ?? ColorData.eGray;
        const featureStatus: OperationStatus =
          feature.get('status')?.toLowerCase() ?? 'offline';
        const featureActivation: SessionStatus =
          feature.get('activationStatus')?.toLowerCase() ?? 'off';
        if (isVisible) {
          // special handler for online/offline status
          if (
            me.rseStatusFilter === 'online' &&
            featureStatus === RseStatus.offline
          )
            return [];
          if (
            (me.rseStatusFilter === 'offline' &&
              featureStatus === OperationStatus.Online) ||
            me.rseStatusFilter === 'hide'
          )
            return [];
          const rseStyle = new Style({
            image: new Icon({
              // src: ICONS_PATH + feature.get('icon') + '.svg',
              img: me._iconLoaderService.getIcon(feature.get('icon')),
              imgSize: [48, 48],
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              opacity: 1,
              scale: feature.get('scale'),
            }),
          });

          const labelTextContent = junctionCode
            ? `J${junctionCode}: ${featureName}`
            : featureName;

          const labelText = new Style({
            text: new Text({
              offsetY: -33,
              text: labelTextContent,
              fill: new Fill({
                color: '#fff',
              }),
              padding: [5, 5, 5, 5],
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
            }),
          });
          const squareText = createSquareFill(labelTextContent);
          const labelStyle = new Style({
            text: new Text({
              text: squareText,
              offsetY: -34,
              fill: new Fill({
                color: featureColor,
              }),
              textAlign: 'center',
              justify: 'center',
              textBaseline: 'middle',
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
              stroke: new Stroke({
                color: featureColor,
                width: 19,
              }),
            }),
          });
          const labelBorderStyle = new Style({
            text: new Text({
              text: squareText,
              offsetY: -34,
              fill: new Fill({
                color: '#000',
              }),
              textAlign: 'center',
              justify: 'center',
              textBaseline: 'middle',
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
              stroke: new Stroke({
                color: '#000',
                width: 21,
              }),
            }),
          });

          if (
            featureStatus === OperationStatus.Online &&
            featureActivation === SessionStatus.On
          ) {
            // const direction = feature.get('direction');
            // const arrow = feature.get('arrow');
            // const featureName = feature.get('name');
            const ingress = feature.get('inboundApproach');
            const egress = feature.get('outboundApproach');
            const approachArrow =
              ingressEgressIcons?.[ingress]?.[egress] ?? null;
            // console.log('rse arrow', featureName, approachArrow);
            // const arrowElement = me.arrowIcons[direction][arrow];
            const arrowStyle = new Style({
              // image: arrowElement,
              image: approachArrow,
              zIndex: 4,
            });

            if (!me.rseLabelVisible) {
              return [rseStyle, arrowStyle];
            }

            return [
              labelBorderStyle,
              labelStyle,
              labelText,
              rseStyle,
              arrowStyle,
            ];
          }
          if (!me.rseLabelVisible) {
            return [rseStyle];
          }
          return [labelBorderStyle, labelStyle, labelText, rseStyle];
        }
        return undefined;
      },
      renderBuffer: 16,
      zIndex: RSE_LAYER_INDEX,
      className: VECTOR_LAYER + '-' + RSE_LAYER + '-markers',
    });

    this.relaySource = new VectorSource({
      features: me.relayHospitalMarkers,
    });

    this.relayLayer = new VectorLayer({
      source: me.relaySource,
      style: function (feature, resolution) {
        const isVisible = feature.get('visible');
        if (isVisible) {
          const rseStyle = new Style({
            image: new Icon({
              // src: ICONS_PATH + feature.get('icon') + '.svg',
              img: me._iconLoaderService.getIcon(feature.get('icon')),
              imgSize: [48, 48],
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              opacity: 1,
              scale: feature.get('scale'),
            }),
          });
          return [rseStyle];
        }
        return undefined;
      },
      renderBuffer: 16,
      zIndex: RELAY_LAYER_INDEX,
      className: VECTOR_LAYER + '-' + RELAY_LAYER + '-markers',
      minZoom: 15,
    });

    this.rseLayer.setZIndex(RSE_LAYER_INDEX);
    this.rseLayer.setVisible(true);
    this.rseLayer.changed();

    this.relayLayer.setZIndex(RELAY_LAYER_INDEX);
    this.relayLayer.setVisible(this.relayVisible);

    this.listenRse();
    // return this.rseLayer;
  }

  sendUpdateRseMarkers() {
    this.rseHospitalMarkersSource.next([
      ...this.rseHospitalMarkers,
      ...this.relayHospitalMarkers,
    ]);
  }

  async getRseTrackingMap() {
    try {
      const rseList = await this._openApiTrackingService
        .trackingRseMapGet()
        .toPromise();

      if (rseList?.length > 0) {
        const filteredList = rseList.filter(rse => !!rse.deviceId);
        // TEMPORARY fill up RSE info IF NULL
        const transformRse = filteredList.map(rse => ({
          ...rse,
          id: rse.deviceId,
          // hospital: rse?.hospital ? rse.hospital : 'H9',
          // type: rse?.type ? rse.type : 'RSE',
          intersectionId: rse?.intersectionId
            ? rse.intersectionId
            : 'Unassigned',
        }));

        console.log('RSE list transform: ', rseList, transformRse);
        return [...transformRse];
      } else {
        return [];
      }
    } catch (e) {
      console.error('Error trackingRseMapGet: ', e);
      return [];
    }
  }

  clearRseLayer() {
    this.rseSource?.clear();
    this.relaySource?.clear();
    if (
      this.relayHospitalMarkers?.length > 0 ||
      this.rseHospitalMarkers?.length > 0
    ) {
      this.rseHospitalMarkers = [];
      this.relayHospitalMarkers = [];
      this.rseHospitalMarkersSource.next([]);
    }
  }

  getRseStatusStyle(
    status: OperationStatus,
    icon: string,
    activationStatus?: SessionStatus
  ) {
    const newStatus = status?.toLowerCase() ?? 'offline';
    const newActivationStatus = activationStatus?.toLowerCase() ?? 'off';
    let statusColor = ColorData.eBrown;
    let rseIcon = icon;

    // console.log('RSE status style', status, activationStatus);

    switch (newStatus) {
      case 'online':
        if (newActivationStatus === 'on') {
          statusColor = ColorData.eGreen;
          rseIcon += '-green';
        } else {
          statusColor = ColorData.eBrown;
          rseIcon += '-brown';
        }
        break;
      case 'offline':
        statusColor = ColorData.eGray;
        rseIcon += '-gray';
        break;
      // case RseStatus.activated:
      //   statusColor = ColorData.eGreen;
      //   rseIcon += '-green';
      //   break;
      default:
        statusColor = ColorData.eGray;
        rseIcon += '-gray';
    }
    return {
      statusColor,
      rseIcon,
    };
  }

  createRseMarker(rseData: any, hospital: string, junctionId: string) {
    const {
      id,
      location,
      description,
      name,
      type,
      status,
      activationStatus,
      approach,
    } = rseData || {};
    const { latitude, longitude } = location || {};
    const { inboundApproach, outboundApproach } = approach || {};
    const junctionCode = getIntersectionJunctionCode(junctionId) ?? null;
    const visible = true;
    let iconType = 'rse-marker';
    if (type === TYPE_RELAY || type === 'RSE-Slave') {
      iconType = 'relay-marker';
    }

    const { rseIcon, statusColor } = this.getRseStatusStyle(
      status,
      iconType,
      activationStatus
    );

    const properties = {
      rseId: id,
      deviceId: id,
      rseType: type,
      status,
      activationStatus,
      hospital,
      junctionId,
      junctionCode,
      visible,
      rseName: name,
      inboundApproach,
      outboundApproach,
    };
    let featureName;
    if (type === TYPE_RSE) {
      featureName = VECTOR_LAYER + '-' + RSE_LAYER + '-' + id;
    } else if (type === TYPE_RELAY || type === 'RSE-Slave') {
      featureName = VECTOR_LAYER + '-' + RELAY_LAYER + '-' + id;
    } else {
      featureName = VECTOR_LAYER + '-' + id;
    }
    // const featureName = VECTOR_LAYER + '-' + id;
    const rseMarker = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')
      ),
      name: featureName,
      description: id,
      color: statusColor,
      icon: rseIcon,
      scale: 0.85,
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      longitude,
      latitude,
      visible: true,
      ...properties,
    });
    return rseMarker;
  }

  findRseFeature(rseId: string) {
    const features = this.rseLayer.getSource().getFeatures();

    const currFeature =
      features.find(feature => {
        const featureName = feature.get('name');
        const featureDescription = feature.get('description');
        // console.log('find rse', rseId, featureName);
        return featureName === rseId || featureDescription === rseId;
      }) ?? null;

    return currFeature;
  }

  findRelayFeature(relayId: string) {
    const features = this.relayLayer.getSource().getFeatures();

    const currFeature =
      features.find(feature => {
        const featureName = feature.get('name');
        // console.log('find rse', relayId, featureName);
        return featureName === relayId;
      }) ?? null;

    return currFeature;
  }

  findFeatureInRseRelayLayers(featureId: string) {
    const rseFeature = this.findRseFeature(featureId);
    const relayFeature = this.findRelayFeature(featureId);
    return rseFeature ?? relayFeature ?? undefined;
  }

  rseSubscription: Subscription;
  rseMqttSubscription: Subscription;
  /**
   * Listener Subscription function to get RSE updates from MQTT
   */
  listenRse() {
    // mock rse
    this.rseSubscription = this._trackingService
      .getRseActivation()
      .subscribe((rse: RseStatusObject) => {
        const {
          rseId,
          junctionId,
          direction,
          arrow,
          status,
          type,
          timeStamp,
          inboundApproach,
          outboundApproach,
        } = rse;

        if (!rseId) return;

        let rseFeature: OlFeature;
        let iconType = 'rse-marker';
        const featureId =
          type === TYPE_RSE
            ? VECTOR_LAYER + '-' + RSE_LAYER + '-' + rseId
            : VECTOR_LAYER + '-' + RELAY_LAYER + '-' + rseId;
        // const featureId = VECTOR_LAYER + '-' + rseId;

        console.log('listen rse', type, rse);

        if (type === TYPE_RSE) {
          rseFeature = this.findRseFeature(featureId);
          iconType = 'rse-marker';
        } else if (type === TYPE_RELAY || type === 'RSE-Slave') {
          rseFeature = this.findRelayFeature(featureId);
          iconType = 'relay-marker';
        }
        if (rseFeature) {
          console.log('rse', rseFeature);
          if (type === TYPE_RSE) {
            rseFeature.set('direction', direction);
            rseFeature.set('arrow', arrow);
            rseFeature.set('inboundApproach', inboundApproach);
            rseFeature.set('outboundApproach', outboundApproach);
          }
          const { rseIcon, statusColor } = this.getRseStatusStyle(
            status as OperationStatus,
            iconType
          );
          rseFeature.set('color', statusColor);
          rseFeature.set('icon', rseIcon);
          rseFeature.set('status', status);

          if (status === 'offline') {
            if (rseFeature) {
              // console.log(rseFeature);
              const hospitalCode = rseFeature.get('hospital');
              const hospitalName =
                getHospitalObject(hospitalCode)?.code ?? hospitalCode;
              const snackbarRef =
                this._commsNotificationService.rseOfflineAlert(
                  rseId,
                  hospitalName,
                  timeStamp
                );
              const me = this;
              snackbarRef.onAction().subscribe(() => {
                me.pantoRse(featureId);
              });
            }
          }
        }
      });

    // mqtt / websocket rse
    this.rseMqttSubscription = this._trackingService.rseUpdate$.subscribe(
      (data: TrackingRseMap) => {
        console.log('listen rse service', data);
        this.processRse(data);
      }
    );
  }

  // add "any" as workaround for RSE-Slave issue
  processRse(rse: TrackingRseMap) {
    const {
      approach,
      boxNo,
      deviceId,
      hospital,
      intersectionId,
      location,
      status = 'online',
      sessionStatus,
      type,
    } = rse;
    const { latitude, longitude } = location || {};
    const { inboundApproach, outboundApproach } = approach || {};
    let rseFeature;

    const featureId =
      type === TYPE_RELAY
        ? VECTOR_LAYER + '-' + RELAY_LAYER + '-' + deviceId
        : VECTOR_LAYER + '-' + RSE_LAYER + '-' + deviceId;
    // const featureId = VECTOR_LAYER + '-' + deviceId;

    let iconType;
    if (type === TYPE_RELAY) {
      rseFeature = this.findRelayFeature(featureId);
      iconType = ICON_RELAY;
    } else {
      rseFeature = this.findRseFeature(featureId);
      iconType = ICON_RSE;
    }
    // console.log('rse', rseFeature, featureId);
    if (rseFeature) {
      console.log('rse exist', featureId, rseFeature);
      // RSE exists
      const prevSessionStatus = rseFeature.get('activationStatus') ?? null;
      const prevOnlineStatus = rseFeature.get('status') ?? null;
      const transformActivationStatus =
        (sessionStatus !== null ? sessionStatus : prevSessionStatus) ?? 'off';

      const transformStatus: OperationStatus =
        (status !== null ? status : prevOnlineStatus) ?? 'offline';
      if (type === TYPE_RSE) {
        if (
          sessionStatus !== null &&
          inboundApproach !== null &&
          outboundApproach !== null
        ) {
          rseFeature.set('inboundApproach', inboundApproach);
          rseFeature.set('outboundApproach', outboundApproach);
        }

        rseFeature.set('activationStatus', transformActivationStatus);
      }

      if (location) {
        rseFeature.setGeometry(
          new Point(
            transform(
              [Number(longitude), Number(latitude)],
              'EPSG:4326',
              'EPSG:3857'
            )
          )
        );
        updateFeatureProperty(rseFeature, 'latitude', latitude);
        updateFeatureProperty(rseFeature, 'longitude', longitude);
      }

      const { rseIcon, statusColor } = this.getRseStatusStyle(
        transformStatus,
        iconType,
        transformActivationStatus
      );
      rseFeature.set('color', statusColor);
      rseFeature.set('icon', rseIcon);
      rseFeature.set('status', transformStatus);

      console.log('rse exist', deviceId, rseIcon, rse, rseFeature);

      if (
        prevOnlineStatus?.toLowerCase() === 'online' &&
        transformStatus?.toLowerCase() === 'offline'
      ) {
        const hospitalCode = rseFeature.get('hospital');
        const hospitalName =
          getHospitalObject(hospitalCode)?.code ?? hospitalCode;
        const snackbarRef = this._commsNotificationService.rseOfflineAlert(
          deviceId,
          hospitalName
        );
        const me = this;
        snackbarRef.onAction().subscribe(() => {
          me.pantoRse(featureId);
        });
      }
    } else {
      console.log('rse does not exist', featureId, rseFeature);
      const transformActivationStatus: SessionStatus = sessionStatus ?? 'off';
      const transformStatus: OperationStatus = status ?? 'offline';
      const newRseObject = {
        id: deviceId,
        location,
        name: deviceId,
        type,
        status: transformStatus,
        activationStatus: transformActivationStatus,
        inboundApproach,
        outboundApproach,
      };
      const newRseMarker = this.createRseMarker(
        newRseObject,
        hospital,
        intersectionId
      );

      console.log('rse new', deviceId, newRseObject, newRseMarker);

      if (type === TYPE_RSE) {
        this.rseHospitalMarkers.push(newRseMarker);
        this.rseSource.addFeature(newRseMarker);
        // update activation if RSE
      } else {
        this.relayHospitalMarkers.push(newRseMarker);
        this.relaySource.addFeature(newRseMarker);
      }

      this.sendUpdateRseMarkers();
    }
  }

  pantoRse(id: string) {
    const rseFeature = this.findRseFeature(id);
    console.log('rse pan feature', id, rseFeature);
    if (rseFeature) {
      const rseCoord = rseFeature.getGeometry().getCoordinates();
      const currExtent = this._trackingService.mapBoundingExtent;
      if (containsCoordinate(currExtent, rseCoord)) {
        const mapView = this._trackingService.getMapView;
        const point = new Point(rseCoord);
        const isIncidentListPanelOpen =
          this._fuseSidebarService.getSidebar('trackingPanel')?.opened ?? false;
        const padding = isIncidentListPanelOpen ? 700 : 0;
        mapView.fit(point, {
          padding: [0, 0, 0, padding],
          maxZoom: 18,
          duration: 2000,
        });
      } else {
        console.log(
          'Error: RSE is not within map boundaries',
          rseFeature,
          rseCoord
        );
      }
    }
  }

  getRelayVisibility() {
    return this.relayVisible;
  }

  setRelayVisibility(value: boolean) {
    this.relayVisible = value;
    this.relayLayer.setVisible(value);
  }

  getRseLabelVisibility() {
    return this.rseLabelVisible;
  }

  setRseLabelVisible(value: boolean) {
    this.rseLabelVisible = value;
    this.rseLayer.changed();
  }

  setRseStatusFilter(value: RseStatusFilter) {
    this.rseStatusFilter = value;
    this.rseLayer.changed();
  }

  cleanup() {
    this.rseSubscription?.unsubscribe();
    this.rseSubscription = undefined;
    this.rseMqttSubscription?.unsubscribe();
    this.rseMqttSubscription = undefined;
  }
}
