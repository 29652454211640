export const ACES_FIRESTATIONS = {
  '10': '1st Division Headquarters',
  '11': 'Central',
  '12': 'Alexandra',
  '13': 'Clementi',
  '14': 'Jurong Island',
  '15': 'Bishan',
  '16': 'Banyan',
  '17': 'Marina',
  '18': 'Sentosa',
  '20': '2nd Division Headquarters',
  '21': 'Paya Lebar',
  '22': 'Changi',
  '23': 'Tampines',
  '24': 'Kallang',
  '30': '3rd Division Headquarters',
  '31': 'Yishun',
  '32': 'Ang Mo Kio',
  '33': 'Sengkang',
  '40': '4th Division Headquarters',
  '41': 'Jurong',
  '42': 'Tuas',
  '43': 'Woodlands',
  '44': 'Bukit Batok',
  '45': 'Tuas View',
  '80': 'Marine Division Headquarters',
  '91': 'DART',
  '99': 'Headquarters',
  '99H': 'HAZMAT',
  '50': 'CDA',
  '60': 'NSTI',
  Lentor: 'Lentor',
  'Unistrong Ambulance': 'Unistrong Ambulance',
  HOPE: 'HOPE',
  SPF: 'SPF',
  SYSTEM: 'SYSTEM',
  Training: 'Training',
  T10: 'Headquarters',
  T11: 'Central',
  T12: 'Alexandra',
  T13: 'Clementi',
  T14: 'Jurong Island',
  T15: 'Bishan',
  T16: 'Banyan',
  T18: 'Sentosa',
  T20: 'Headquarters',
  T21: 'Paya Lebar',
  T22: 'Changi',
  T23: 'Tampines',
  T30: 'Headquaters',
  T31: 'Yishun',
  T32: 'Ang Mo Kio',
  T33: 'Sengkang',
  T40: 'Headquarters',
  T41: 'Jurong',
  T42: 'Tuas',
  T43: 'Woodlands',
  T44: 'Bukit Batok',
};

export const ACES_INCIDENT_STATIONS_CODE = {
  '10-Headquarters': '10',
  '11-Central': '11',
  '12-Alexandra': '12',
  '13-Clementi': '13',
  '14-Jurong Island': '14',
  '15-Bishan': '15',
  '16-Banyan': '16',
  '17-Marina': '17',
  '18-Sentosa': '18',
  '20-Headquarters': '20',
  '21-Paya Lebar': '21',
  '22-Changi': '22',
  '23-Tampines': '23',
  '24-Kallang': '24',
  '30-Headquarters': '30',
  '31-Yishun': '31',
  '32-Ang Mo Kio': '32',
  '33-Sengkang': '33',
  '40-Headquarters': '40',
  '41-Jurong': '41',
  '42-Tuas': '42',
  '43-Woodlands': '43',
  '44-Bukit Batok': '44',
  '45-Tuas View': '45',
  '80-Headquarters': '80',
  '91-DART': '91',
  '99-Headquarters': '99',
  '99H-HAZMAT': '99H',
  '50-CDA': '50',
  '60-NSTI': '60',
  Lentor: 'Lentor',
  'Unistrong Ambulance': 'Unistrong Ambulance',
  HOPE: 'HOPE',
  SPF: 'SPF',
  SYSTEM: 'SYSTEM',
  Training: 'Training',
  'T10-Headquarters': 'T10',
  'T11-Central': 'T11',
  'T12-Alexandra': 'T12',
  'T13-Clementi': 'T13',
  'T14-Jurong Island': 'T14',
  'T15-Bishan': 'T15',
  'T16-Banyan': 'T16',
  'T18-Sentosa': 'T18',
  'T20-Headquarters': 'T20',
  'T21-Paya Lebar': 'T21',
  'T22-Changi': 'T22',
  'T23-Tampines': 'T23',
  'T30-Headquaters': 'T30',
  'T31-Yishun': 'T31',
  'T32-Ang Mo Kio': 'T32',
  'T33-Sengkang': 'T33',
  'T40-Headquarters': 'T40',
  'T41-Jurong': 'T41',
  'T42-Tuas': 'T42',
  'T43-Woodlands': 'T43',
  'T44-Bukit Batok': 'T44',
};

export enum FIRESTATION_CODES {
  CENTRAL = 'CENTRAL',
  MARINABAY = 'MARINABAY',
  ALEXANDRA = 'ALEXANDRA',
  BISHAN = 'BISHAN',
  CLEMENTI = 'CLEMENTI',
  JURONGISLAND = 'JURONGISLAND',
  BANYAN = 'BANYAN',
  SENTOSA = 'SENTOSA',
  TAMPINES = 'TAMPINES',
  KALLANG = 'KALLANG',
  CHANGI = 'CHANGI',
  PAYALEBAR = 'PAYALEBAR',
  YISHUN = 'YISHUN',
  ANGMOKIO = 'ANGMOKIO',
  SENGKANG = 'SENGKANG',
  PUNGGOL = 'PUNGGOL',
  BUKITBATOK = 'BUKITBATOK',
  JURONG = 'JURONG',
  TUASVIEW = 'TUASVIEW',
  TUAS = 'TUAS',
  WOODLANDS = 'WOODLANDS',
  BRANI = 'BRANI',
  WESTCOAST = 'WESTCOAST',
}

export enum FIRESTATION_NAMES {
  CENTRAL = 'Central',
  MARINABAY = 'Marina Bay',
  ALEXANDRA = 'Alexandra',
  BISHAN = 'Bishan',
  CLEMENTI = 'Clementi',
  JURONGISLAND = 'Jurong Island',
  BANYAN = 'Banyan',
  SENTOSA = 'Sentosa',
  TAMPINES = 'Tampines',
  KALLANG = 'Kallang',
  CHANGI = 'Changi',
  PAYALEBAR = 'Paya Lebar',
  YISHUN = 'Yishun',
  ANGMOKIO = 'Ang Mo Kio',
  SENGKANG = 'Sengkang',
  PUNGGOL = 'Punggol',
  BUKITBATOK = 'Bukit Batok',
  JURONG = 'Jurong',
  TUASVIEW = 'Tuas View',
  TUAS = 'Tuas',
  WOODLANDS = 'Woodlands',
  BRANI = 'Brani Marine',
  WESTCOAST = 'West Coast Marine',
}

export enum FIRESTATION_SHORTHAND {
  CENTRAL = 'Central',
  MARINABAY = 'Marina Bay',
  ALEXANDRA = 'Alexandra',
  BISHAN = 'Bishan',
  CLEMENTI = 'Clementi',
  JURONGISLAND = 'Jurong Island',
  BANYAN = 'Banyan',
  SENTOSA = 'Sentosa',
  TAMPINES = 'Tampines',
  KALLANG = 'Kallang',
  CHANGI = 'Changi',
  PAYALEBAR = 'Paya Lebar',
  YISHUN = 'Yishun',
  ANGMOKIO = 'Ang Mo Kio',
  SENGKANG = 'Sengkang',
  PUNGGOL = 'Punggol',
  BUKITBATOK = 'Bukit Batok',
  JURONG = 'Jurong',
  TUASVIEW = 'Tuas View',
  TUAS = 'Tuas',
  WOODLANDS = 'Woodlands',
  BRANI = 'Brani Marine',
  WESTCOAST = 'West Coast Marine',
}

export const DIVISION_CODES = {
  '10': '1st Division',
  '20': '2nd Division',
  '30': '3rd Division',
  '40': '4th Division',
  '80': 'Marine Division',
};

export const firstDivisionList = [
  {
    name: 'Central',
    visible: true,
    id: 'CENTRAL',
    code: '11',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '62 Hill St, Singapore 179367',
    postalCode: '179367',
    location: {
      latitude: 1.29218,
      longitude: 103.8493,
    },
  },
  {
    name: 'Marina Bay',
    visible: true,
    id: 'MARINABAY',
    code: '17',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '70 Marina View, Singapore 018962',
    postalCode: '018962',
    location: {
      latitude: 1.27463,
      longitude: 103.849088,
    },
  },
  {
    name: 'Alexandra',
    visible: true,
    id: 'ALEXANDRA',
    code: '12',
    division: '1st',
    hq: true,
    scdfHq: false,
    address: '3 Queensway, Singapore 149073',
    postalCode: '149073',
    location: {
      latitude: 1.28838,
      longitude: 103.803077,
    },
  },
  {
    name: 'Bishan',
    visible: true,
    id: 'BISHAN',
    code: '15',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '1 Marymount Ln, Singapore 574029',
    postalCode: '574029',
    location: {
      latitude: 1.3483497042110664,
      longitude: 103.83870290126997,
    },
  },
  {
    name: 'Clementi',
    visible: true,
    id: 'CLEMENTI',
    code: '13',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: 'Commonwealth Ave W, Singapore 129577',
    postalCode: '129577',
    location: {
      latitude: 1.32191789,
      longitude: 103.761605,
    },
  },
  {
    name: 'Jurong Island',
    visible: true,
    id: 'JURONGISLAND',
    code: '14',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '70 Jurong Island Hwy, 627880',
    postalCode: '627880',
    location: {
      latitude: 1.2719552,
      longitude: 103.70825,
    },
  },
  {
    name: 'Banyan',
    visible: true,
    id: 'BANYAN',
    code: '16',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '15 Banyan Rd, 627642',
    postalCode: '627642',
    location: {
      latitude: 1.2541869,
      longitude: 103.6741269,
    },
  },
  {
    name: 'Sentosa',
    visible: true,
    id: 'SENTOSA',
    code: '18',
    division: '1st',
    hq: false,
    scdfHq: false,
    address: '37 Artillery Ave, 099957',
    postalCode: '099957',
    location: {
      latitude: 1.250781,
      longitude: 103.827614,
    },
  },
];

export const secondDivisionList = [
  {
    name: 'Tampines',
    visible: true,
    id: 'TAMPINES',
    code: '23',
    division: '2nd',
    hq: true,
    scdfHq: false,
    address: '1 Tampines Industrial Ave 3, Singapore 528777',
    postalCode: '528777',
    location: {
      latitude: 1.3580335,
      longitude: 103.92935,
    },
  },
  {
    name: 'Kallang',
    visible: true,
    id: 'KALLANG',
    code: '24',
    division: '2nd',
    hq: false,
    scdfHq: false,
    address: '2 Guillemard Cl, S 397623',
    postalCode: '397623',
    location: {
      latitude: 1.30959867,
      longitude: 103.8792596,
    },
  },
  {
    name: 'Changi',
    visible: true,
    id: 'CHANGI',
    code: '22',
    division: '2nd',
    hq: false,
    scdfHq: false,
    address: '491 Upper Changi Rd, Singapore 486965',
    postalCode: '486965',
    location: {
      latitude: 1.33481,
      longitude: 103.95138,
    },
  },
  {
    name: 'Paya Lebar',
    visible: true,
    id: 'PAYALEBAR',
    code: '21',
    division: '2nd',
    hq: false,
    scdfHq: true,
    address: '91 Ubi Ave 4, SCDF HQ, Singapore 408827',
    postalCode: '408827',
    location: {
      latitude: 1.3340358,
      longitude: 103.893563,
    },
  },
];

export const thirdDivisionList = [
  {
    name: 'Yishun',
    visible: true,
    id: 'YISHUN',
    code: '31',
    division: '3rd',
    hq: true,
    scdfHq: false,
    address: '533 Yishun Industrial Park, A, Singapore 768774',
    postalCode: '768774',
    location: {
      latitude: 1.4447269,
      longitude: 103.836366,
    },
  },
  {
    name: 'Ang Mo Kio',
    visible: true,
    id: 'ANGMOKIO',
    code: '32',
    division: '3rd',
    hq: false,
    scdfHq: false,
    address: '2874 Ang Mo Kio Ave 9, Singapore 569783',
    postalCode: '569783',
    location: {
      latitude: 1.385044,
      longitude: 103.8459325,
    },
  },
  {
    name: 'Sengkang',
    visible: true,
    id: 'SENGKANG',
    code: '33',
    division: '3rd',
    hq: false,
    scdfHq: false,
    address: '50 Buangkok Dr, Singapore 545064',
    postalCode: '545064',
    location: {
      latitude: 1.3801799,
      longitude: 103.8954297,
    },
  },
  {
    name: 'Punggol',
    visible: true,
    id: 'PUNGGOL',
    code: '34',
    division: '3rd',
    hq: false,
    scdfHq: false,
    address: '151 Punggol Central, Singapore 828727',
    postalCode: '828727',
    location: {
      latitude: 1.397779,
      longitude: 103.91388789,
    },
  },
];

export const fourthDivisionList = [
  {
    name: 'Bukit Batok',
    visible: true,
    id: 'BUKITBATOK',
    code: '44',
    division: '4th',
    hq: true,
    scdfHq: false,
    address: '80 Bukit Batok Rd, Singapore 658072',
    postalCode: '658072',
    location: {
      latitude: 1.3734555,
      longitude: 103.752698,
    },
  },
  {
    name: 'Jurong',
    visible: true,
    id: 'JURONG',
    code: '41',
    division: '4th',
    hq: false,
    scdfHq: false,
    address: '22 Jurong West Street 26, Singapore 648126',
    postalCode: '648126',
    location: {
      latitude: 1.3480341,
      longitude: 103.705248,
    },
  },
  {
    name: 'Tuas View',
    visible: true,
    id: 'TUASVIEW',
    code: '45',
    division: '4th',
    hq: false,
    scdfHq: false,
    address: '130 Tuas South Ave 3, Singapore 637367',
    postalCode: '637367',
    location: {
      latitude: 1.28884,
      longitude: 103.627448,
    },
  },
  {
    name: 'Tuas',
    visible: true,
    id: 'TUAS',
    code: '42',
    division: '4th',
    hq: false,
    scdfHq: false,
    address: '7 Tuas Rd, Singapore 638483',
    postalCode: '638483',
    location: {
      latitude: 1.31946765,
      longitude: 103.661528,
    },
  },
  {
    name: 'Woodlands',
    visible: true,
    id: 'WOODLANDS',
    code: '43',
    division: '4th',
    hq: false,
    scdfHq: false,
    address: '1 Woodlands Industrial Park D St 2, Singapore 738782',
    postalCode: '738782',
    location: {
      latitude: 1.4306748,
      longitude: 103.76236,
    },
  },
];

export const marineDivisionList = [
  {
    name: 'West Coast Marine',
    visible: true,
    id: 'WESTCOAST',
    division: 'Marine',
    hq: false,
    scdfHq: false,
    address: '60-62 W Coast Ferry Rd, Singapore 126978',
    postalCode: '126978',
    location: {
      latitude: 1.2924555785,
      longitude: 103.76224,
    },
  },
  {
    name: 'Brani Marine',
    code: '80',
    visible: true,
    id: 'BRANI',
    division: 'Marine',
    hq: true,
    scdfHq: false,
    address: '19 Brani Way, 098002',
    postalCode: '098002',
    location: {
      latitude: 1.2566138,
      longitude: 103.838625,
    },
  },
];

export const fireStationList = [
  ...firstDivisionList,
  ...secondDivisionList,
  ...thirdDivisionList,
  ...fourthDivisionList,
  // ...marineDivisionList,
];

export const nestedFireStationList = [
  {
    division: 'First Division',
    list: firstDivisionList,
  },
  {
    division: 'Second Division',
    list: secondDivisionList,
  },
  {
    division: 'Third Division',
    list: thirdDivisionList,
  },
  {
    division: 'Fourth Division',
    list: fourthDivisionList,
  },
  {
    division: 'Marine Division',
    list: marineDivisionList,
  },
];
