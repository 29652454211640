export enum AmbulanceStatus {
  offDuty = 'Off Duty',
  return = 'Returning to Base',
  base = 'At Base',
  post = 'At Post',
  outOfService = 'Out of Service',
  dispatched = 'Dispatched',
  responding = 'Responding',
  scene = 'Arrive at Scene',
  patient = 'At Patient Side',
  toHospital = 'Enroute to Hospital',
  atHospital = 'Arrive at Hospital',
  movingOut = 'Moving Back',
  offline = 'Offline',
}

export const ambulanceStatusCodes = {
  99: 'Offline',
  0: 'Off Duty',
  1: 'Returning to Base',
  2: 'At Base',
  3: 'At Post',
  5: 'Out of Service',
  6: 'Dispatched',
  7: 'Responding',
  10: 'Arrive at Scene',
  11: 'At Patient Side',
  12: 'Enroute to Hospital',
  13: 'Arrive at Hospital',
  17: 'Moving Back',
};
