import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
// import { ConfigService } from "./config.service";
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from 'app/main/tracking/tracking.service';
import { LOGIN_PATH } from '@constant/routes';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { LOGIN_PATH } from "../others/constant";
// import { FuseConfigService } from '@fuse/services/config.service';

type UserLogoutParams = {
  userName?: string;
};

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    // private configService: ConfigService,
    // private _fuseConfigService: FuseConfigService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  private logoutEvent$: Subject<UserLogoutParams> =
    new Subject<UserLogoutParams>();
  logoutEvent: Observable<UserLogoutParams> = this.logoutEvent$.asObservable();

  isAuthenticated() {
    return (
      localStorage.getItem('access_token') != undefined &&
      localStorage.getItem('agency') != undefined
    );
  }

  login(loginData) {
    // console.log(loginData);
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('password', loginData.password)
      .set('username', loginData.username)
      .set('ctry_code', 'SG');

    const options = {
      headers: new HttpHeaders({
        Authorization: `Basic ` + btoa('USER_CLIENT_APP:password'),
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    // return this.httpClient.post(environment.apiBaseUri + '/api/oauth/token',
    return this.httpClient
      .post(environment.apiBaseUri + '/oauth', body, options)
      .pipe(
        catchError(errObj => {
          const { error } = errObj || {};
          console.log('AuthError', errObj, error);
          const { message, status } = error || {};
          if (status === 401) {
            this._snackBar.open(
              'The username or password is incorrect. Please try again.',
              null,
              {
                duration: 5000,
                panelClass: 'custom-snack-bar-panel-error',
              }
            );
          } else if (message) {
            this._snackBar.open(message, null, {
              duration: 5000,
              panelClass: 'custom-snack-bar-panel-error',
            });
          }

          // return throwError(error.error.error_description);
          return throwError(errObj);
        }),
        map((data: any) => {
          // console.log(data);
          if (data.response && data.response.includes('not found')) {
            return false;
          }

          // localStorage.setItem('access_token', data['access_token']);
          // localStorage.setItem('refresh_token', data['refresh_token']);
          // localStorage.setItem('email', data['email']);
          // localStorage.setItem('scope', data['scope']);
          // localStorage.setItem('displayName', data['displayname']);
          // localStorage.setItem('agency', data['agency']);
          return data;
        })
      );
  }

  logout() {
    const userName = localStorage.getItem('username') ?? undefined;
    this.logoutEvent$.next({
      userName,
    });

    localStorage.clear();
    this.router.navigate([LOGIN_PATH]);

    // const urlPath = this.router.url;
    // // console.log('url path', urlPath, urlPath.indexOf('tracking'));
    // if (urlPath === '/' || urlPath.indexOf('tracking') > -1) {
    //   this.router.navigate(['/pages/auth/login']);
    // } else {
    //   this.router.navigate(['/pages/auth/login'], {
    //     queryParams: { redirect: urlPath },
    //   });
    // }
  }

  refreshAccessToken(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Basic ` + btoa('USER_CLIENT_APP:password'),
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', localStorage.getItem('refresh_token'))
      .set('ctry_code', 'SG');

    return this.httpClient
      .post(environment.apiBaseUri + '/oauth', body, options)
      .pipe(
        map(data => {
          localStorage.setItem('access_token', data['access_token']);
          localStorage.setItem('refresh_token', data['refresh_token']);

          return data['access_token'];
        }),

        catchError(err => {
          this.logout();
          return err;
        })
      );
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  getUserName(): string {
    return localStorage.getItem('username');
  }

  // isAdmin(): boolean {
  //     return localStorage['scope'] === 'admin';
  // }

  listenStorageEvent() {
    const storageListener = event => {
      const { storageArea, key } = event;
      if (storageArea === localStorage && key !== 'ng2Idle.main.expiry') {
        const token = localStorage.getItem('access_token');
        // let agency = localStorage.getItem('agency');
        const redirect =
          new URLSearchParams(window.location.search)?.get('redirect') || '';
        // console.log('storage event', event);

        if (token) {
          if (this.isAuthenticated()) {
            if (redirect) {
              const isLogin = redirect?.includes('login') || redirect === '/';
              if (isLogin) {
                this.router.navigate(['/tracking']);
              } else {
                this.router.navigate([redirect]).catch(e => {
                  this.router.navigate(['/tracking']);
                });
              }
            } else if (this.router.url?.includes('login')) {
              this.router.navigate(['/tracking']);
            }
          }
        } else {
          this.dialog.closeAll();
          // const noRedirect =
          //   this.router.url?.includes('login') ||
          //   this.router.url === '/tracking' ||
          //   this.router.url === '/';
          // const urlPath = this.router.url;
          // this.router.navigate(['/pages/auth/login'], {
          //   queryParams: { redirect: noRedirect ? null : urlPath },
          // });
          this.router.navigate([LOGIN_PATH]);
        }
      }
    };
    window.removeEventListener('storage', storageListener);
    window.addEventListener('storage', storageListener);
  }

  getRole() {
    return localStorage['role'];
  }
}
