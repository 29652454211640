import { medicalPriority } from '@constant/incidentCode';

function getMedicalPriority(priority) {
  // console.log('get medical priority', priority);
  if (priority?.includes('-')) {
    const code = priority?.split('-');
    // console.log('split priority', code);
    const [priorityCode, priorityDescription] = code ?? ['', ''];
    if (medicalPriority[priorityCode]) return medicalPriority[priorityCode];
    return priorityCode;
  }
  if (medicalPriority[priority]) return medicalPriority[priority];
  return priority;

  // return 'P1+'; // hardcode P1+ for other priorities
}

export default getMedicalPriority;
