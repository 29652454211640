import { Address } from '@types';

export function AddressTransform(value: Address): string {
  const { blkNo, buildingName, postalCode, streetName, unit, floor } =
    value || {};

  return `${blkNo}${formatHasValue(streetName)}${formatFloorUnit(
    floor,
    unit,
    ', '
  )}${formatHasValue(buildingName)}${formatHasValue(postalCode, ', ')}`;
}

const formatFloorUnit = (floor, unit, separator = ' ') => {
  if (floor && unit) {
    return separator + floor + '-' + unit;
  }
  if (unit) {
    return separator + unit;
  }
  if (floor) {
    return separator + floor;
  }
};

const formatHasValue = (value: string | number, separator = ' '): string => {
  if (!value) return '';
  return separator + value;
};
