import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

import {
  TpsApiModule,
  TpsConfiguration,
  TpsConfigurationParameters,
} from '@openapi/tps';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { AccountManagementModule } from 'app/main/account-management/account-management.module';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AuthService } from '@fuse/services/auth.service';
import { AuthGuard } from '@fuse/services/auth-guard.service';
import { MatMenuModule } from '@angular/material/menu';

// import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
// import {Keepalive} from '@ng-idle/keepalive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthInterceptor } from '@fuse/services/helpers/auth.interceptor';
import { TrackingService } from './main/tracking/tracking.service';
import { WebsocketService } from '@services/websocket.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { LOGIN_PATH, LOGIN_PATHNAME } from '@constant/routes';
import { CommonPipesModule } from './shared/pipes/common-pipes.module';
import { APP_BASE_HREF } from '@angular/common';

// apiTpsUri
export function apiConfigFactory(): TpsConfiguration {
  const params: TpsConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.apiTpsUri,
  };
  return new TpsConfiguration(params);
}

const appRoutes: Routes = [
  {
    path: LOGIN_PATHNAME,
    title: 'TPS',
    loadChildren: () =>
      import('./main/login/login.module').then(m => m.LoginModule),
  },
  // {
  //   path: 'pages',
  //   title: 'TPS',
  //   loadChildren: () =>
  //     import('./main/pages/pages.module').then(m => m.PagesModule),
  // },
  // {
  //   path: 'dashboard',
  //   title: 'TPS',
  //   loadChildren: () =>
  //     import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
  // },
  {
    path: 'tracking',
    title: 'TPS',
    loadChildren: () =>
      import('./main/tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'system',
    title: 'TPS: System Administration',
    loadChildren: () =>
      import('./main/system-administration/system-administration.module').then(
        m => m.SystemAdministrationModule
      ),
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: LOGIN_PATH,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {}),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // Shared Modules
    CommonPipesModule,

    // App modules
    LayoutModule,

    //device monitoring

    //account management
    AccountManagementModule,
    // MqttModule.forRoot()

    NgIdleKeepaliveModule.forRoot(),

    TpsApiModule.forRoot(apiConfigFactory),

    HotkeyModule.forRoot({
      disableCheatSheet: true,
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthService,
    TrackingService,
    WebsocketService,
    AuthGuard,
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // idleState = 'Not started.';
  // timedOut = false;
  // lastPing?: Date = null;
  // constructor(private idle: Idle, private keepalive: Keepalive) {
  //     idle.watch();
  //     // sets an idle timeout of 5 seconds, for testing purposes.
  //     idle.setIdle(5);
  //     // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
  //     idle.setTimeout(5);
  //     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
  //     idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  //     idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
  //     idle.onTimeout.subscribe(() => {
  //         this.idleState = 'Timed out!';
  //         this.timedOut = true;
  //     });
  //     idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
  //     idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
  //     // sets the ping interval to 15 seconds
  //     keepalive.interval(15);
  //     keepalive.onPing.subscribe(() => this.lastPing = new Date());
  // }
}
