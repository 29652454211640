/**
 * Randomized an integer given an interval
 * @param min minimum generated integer
 * @param max maximum generated integer
 * @returns random integer
 */
export function generateRandomNumber(min = 0, max = 100) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default generateRandomNumber;
