import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subject } from 'rxjs';
import { AuthService } from '../auth.service';
import {
  catchError,
  filter,
  take,
  switchMap,
  map,
  concatMap,
  retryWhen,
  mergeMap,
} from 'rxjs/operators';
import { CommonService } from '../common.service';
// import { environment } from 'environments/environment';

// import { AuthenticationService } from '@/_services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private _refreshSubject: Subject<any> = new Subject<any>();

  constructor(
    public auth: AuthService // private _commonService: CommonService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/oauth')) {
      return next.handle(req);
    } else {
      const request = this.updateHeader(req);
      return next.handle(request).pipe(
        catchError((error, caught) => {
          // console.log('Error intercept: ', error, caught);
          if (error instanceof HttpErrorResponse) {
            if (this._checkTokenExpiryErr(error)) {
              return this._ifTokenExpired().pipe(
                switchMap(() => {
                  return next.handle(this.updateHeader(req));
                })
              );
            } else {
              if (this._checkUserDisabled(error)) {
                this.auth.logout();
              }
              return throwError(error);
            }
          }
          return caught;
        })
      );
    }
  }

  updateHeader(req) {
    const authToken = this.auth.getAccessToken();
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });
    return req;
  }

  /**
   * Checks API result for disabled user
   * Currently works only on USER api
   */
  private _checkUserDisabled(error: HttpErrorResponse): boolean {
    return (
      (error?.status === 403 && error.error?.response?.includes('disabled')) ||
      error.error?.message?.includes('disabled')
    );
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    //   return (
    //     error.status &&
    //     error.status === 403 &&
    //     error.error.message === "TokenExpired"
    //   );
    return error.status === 401;
  }

  private _ifTokenExpired() {
    this._refreshSubject.subscribe({
      complete: () => {
        this._refreshSubject = new Subject<any>();
      },
      // error: (error) => {
      //   console.log('error', error);
      // }
    });
    if (this._refreshSubject.observers.length >= 1) {
      this.auth.refreshAccessToken().subscribe(this._refreshSubject);
    }
    return this._refreshSubject;
  }
}
