/**
 * Checks if the sub string is found in the parent string
 * @param parentString string
 * @param subString string
 * @returns boolean
 */

export const isSubstring = function (parentString: string, subString: string) {
  return (
    parentString
      ?.toString()
      .trim()
      .toLowerCase()
      .includes(subString?.toLowerCase()) ?? false
  );
};

export default isSubstring;
