import { ColorData } from '@fuse/others/constant';
import { RseStatus } from '@constant/rseStatus';

export function getRseStatusStyle(
  status?: string,
  activationStatus?: string,
  type: 'rse' | 'relay' = 'rse'
) {
  let statusColor = ColorData.eBrown;
  let icon = type + '-marker';

  const statusString = status?.toLowerCase();
  switch (statusString) {
    case RseStatus.online:
      if (activationStatus?.toLowerCase() === RseStatus.active) {
        statusColor = ColorData.eGreen;
        icon += '-green';
      } else {
        statusColor = ColorData.eBrown;
        icon += '-brown';
      }
      break;
    case RseStatus.offline:
      statusColor = ColorData.eGray;
      icon += '-gray';
      break;
    case RseStatus.activated:
      statusColor = ColorData.eGreen;
      icon += '-green';
      break;
    default:
      statusColor = ColorData.eBrown;
      icon += '-brown';
  }
  return {
    statusColor,
    icon,
  };
}
