import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import * as moment from 'moment';
import { OSM, TileArcGISRest, Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import OlFeature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import Style from 'ol/style/Style';
import OlView from 'ol/View';
import OlMap from 'ol/Map';
import OlTileLayer from 'ol/layer/Tile';
import Icon, { Options as IconOptions } from 'ol/style/Icon';
import { ScaleLine, defaults as defaultOlControls } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Extent, boundingExtent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';

import { fireStationList } from 'app/shared/constant/fireStation';
import { hospitalList } from 'app/shared/constant/hospitals';
import { ColorData, MapType } from '@fuse/others/constant';
import { environment } from 'environments/environment';
import { createCommonIconStyle } from './trackingStyles';

export { AMBULANCE_LAYER } from './ambulance.tracking.service';
export {
  RSE_LAYER,
  RELAY_LAYER,
  RseStatusObject,
} from './rse.tracking.service';
export { INCIDENT_LAYER } from './incident.tracking.service';
import { RseStatusObject, RseTrackingService } from './rse.tracking.service';
import {
  rseData as fakeRseData,
  ntfghRSEs,
  ktphRSEs,
  cghRSEs,
  nuhRSEs,
} from 'app/shared/fakeData/proposedRseData';
// import { normalEventTimeline } from 'app/shared/fakeData/incidentCases';
// import { INCIDENT_TYPE } from 'app/shared/fakeData/incidentCases';
import { SIM_EVENT_TYPE } from '@constant/simulation';
import TileLayer from 'ol/layer/Tile';

import { ICONS_PATH } from './trackingStyles';
import { TrackingSimulationService } from './tracking-simulation.service';
import { CommsNotificationService } from 'app/shared/services/monitoring/comms-notification.service';
import {
  TrackingIncidentList,
  TrackingObdList,
  TrackingObdMap,
  TrackingRseMap,
} from '@openapi/tps';

import { WebsocketService } from '@services/websocket.service';
import { AuthService } from '@fuse/services/auth.service';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export type rseStatus = 'online' | 'activated' | 'offline';

export const VECTOR_LAYER = 'VectorLayer';
export const OVERLAY_LAYER = 'OverlayLayer';

export const FIRESTATION_LAYER = 'FIRESTATION';
export const HOSPITAL_LAYER = 'HOSPITAL';

export const statusArray: Array<rseStatus> = ['online', 'activated', 'offline'];

export type OverlayLayer = {
  name: string;
  layer: any;
  enabled: boolean;
  icon: string;
};

type WsMessageType = {
  message: string;
  type: string;
};

export const baseUrl =
  'https://basemaps-api.arcgis.com/arcgis/rest/services/styles';
export const getBaseMapUrl = (name, apiKey) =>
  `${baseUrl}/${name}?type=style&token=${apiKey}`;

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private trackingSimulationService: TrackingSimulationService,
    private commsNotificationService: CommsNotificationService,
    private authService: AuthService,
    private wsService: WebsocketService
  ) {
    this.watchLogout();
  }

  rseData = [ntfghRSEs, ktphRSEs, cghRSEs, nuhRSEs];
  mapView: OlView;
  get getMapView() {
    return this.mapView;
  }
  mapInstance: OlMap;
  osmLayer;
  get getMapInstance() {
    return this.mapInstance;
  }

  selectedBasemap: string = MapType['OSM-Static'];
  private rseActivation: Subject<RseStatusObject> =
    new Subject<RseStatusObject>();
  rseInterval: ReturnType<typeof setInterval>;

  // RSE update
  private rseUpdateSource: Subject<TrackingRseMap> =
    new Subject<TrackingRseMap>();
  rseUpdate$: Observable<TrackingRseMap> = this.rseUpdateSource.asObservable();

  // OBD update
  private ambulanceUpdateSource: Subject<any> = new Subject<any>();
  ambulanceUpdate$: Observable<any> = this.ambulanceUpdateSource.asObservable();

  // incident only
  private incidentUpdateSource: Subject<any> = new Subject<any>();
  incidentUpdate$: Observable<any> = this.incidentUpdateSource.asObservable();

  // incident-obd incident
  private incidentObdUpdateSource: Subject<TrackingObdList> =
    new Subject<TrackingObdList>();
  incidentObdUpdate$: Observable<TrackingObdList> =
    this.incidentObdUpdateSource.asObservable();

  mapBoundingExtent: Extent;

  watchLogout() {
    this.authService.logoutEvent.subscribe(() => {
      this.reset();
    });
  }

  reset() {
    this.selectedBasemap = MapType['OSM-Static'];
    console.log('Tracking Service Cleanup');
    this.cleanup();
  }

  initMapTracking() {
    const apiKey = environment.esriApiKey;

    const scaleLine = new ScaleLine({
      units: 'metric',
    });
    const minCoords: Coordinate = fromLonLat([103.59, 1.13]);
    const maxCoords: Coordinate = fromLonLat([104.1, 1.49]);
    this.mapBoundingExtent = boundingExtent([minCoords, maxCoords]);
    this.mapView = new OlView({
      center: fromLonLat([103.8198, 1.3521]), // whole SG map
      zoom: 12.5,
      // center: fromLonLat([103.755811, 1.326186]), // NTFGH location
      // zoom: 15,
      maxZoom: 17, // static map limits
      minZoom: 12,
      // minZoom: 5,
      extent: this.mapBoundingExtent, // map extent
    });
    this.mapInstance = new OlMap({
      controls: defaultOlControls().extend([scaleLine]),
      target: 'viewDiv',
      view: this.mapView,
      // projection: 'EPSG:900913',
    });

    // init static map
    this.osmLayer = this.initOsmMap();

    // const basemapId = "ArcGIS:Streets";
    const basemapURL = getBaseMapUrl(this.selectedBasemap, apiKey);

    return { map: this.mapInstance, basemap: basemapURL };
  }

  updateBaseMap(basemapId: string) {
    const apiKey = environment.esriApiKey;
    const baseMapUrl = getBaseMapUrl(basemapId, apiKey);
    this.selectedBasemap = basemapId;
    const layers = this.mapInstance.getLayers().getArray().slice();
    const mapBoxLayers: any = layers.filter(
      layer => layer.getClassName() === 'ol-layer'
    );
    const me = this;
    mapBoxLayers.forEach(layer => me.mapInstance.removeLayer(layer));
    return { map: this.mapInstance, basemap: baseMapUrl };
  }

  updateLocalMap() {
    // remove old layers
    const layers = this.mapInstance.getLayers().getArray().slice();
    const mapBoxLayers: any = layers.filter(
      layer => layer.getClassName() === 'ol-layer'
    );
    const me = this;
    mapBoxLayers.forEach(layer => me.mapInstance.removeLayer(layer));

    this.selectedBasemap = MapType['OSM-Static'];
    this.mapInstance.getLayers().insertAt(0, this.osmLayer);
  }

  initOsmMap() {
    // const bucket = 'map-tiles-v1';
    // const region = 'ap-southeast-1';
    // const mapUrl = `https://${bucket}.s3.${region}.amazonaws.com/{z}/{x}/{y}.png`;
    const mapUrl = `${environment.apiMapUri}/{z}/{x}/{y}.png`;
    // const mapRegion = 'manila';
    // const mapUrl = `${environment.nodeApiBaseUri}/map/${mapRegion}/{z}/{x}/{y}.png`;
    // const mapUrl = `${window.location.origin}/assets/map/osm/{z}/{x}/{y}.png`;
    const osmLayer = new TileLayer({
      source: new OSM({
        url: mapUrl,
        crossOrigin: null,
      }),
      className: 'ol-layer',
    });
    return osmLayer;
  }

  getRseActivation(): Observable<any> {
    return this.rseActivation.asObservable();
  }

  startData() {
    // this.dummyDataCall();
    if (this.authService.isAuthenticated()) {
      this.listenUpdates();
    } else {
      console.log('Tracking Service Error: User not authenticated.');
      this.reset();
    }
  }

  // websocketConfig = {
  //   url: `${environment.nodeUrlWs}?type=tracking`,
  //   // serializer: msg => JSON.stringify(msg),
  //   closeObserver: {
  //     next: () => {
  //       console.log('Websocket disconnected');
  //       this.socket$ = null;
  //       this.connection$.next(false);
  //     },
  //   },
  //   openObserver: {
  //     next: () => {
  //       console.log('Websocket connection established');
  //       this.connection$.next(true);
  //       // send subscription request
  //       const wsMessage = {
  //         action: 'subscribe',
  //         type: 'tracking',
  //       };

  //       const jsonMessage = JSON.stringify(wsMessage);

  //       this.socket$.next(jsonMessage);
  //     },
  //   },
  // };

  private updates$: Subject<any> = new Subject<any>();
  trackingUpdates: Observable<any> = this.updates$.asObservable();
  updateSubscription: Subscription;
  private socket$: WebSocketSubject<unknown>;
  private socketSubscription: Subscription;
  private messages$: Subscription;
  private status$: Subscription;
  wsPingIntervalId;
  /**
   *  should listen to websockets / mqtt. for now, listen to dummy subject.
   *  this will pass data to the related services based on event
   *  Events:
   *  - Incident List
   *  - Incident Map
   *  - OBD List
   *  - OBD Map
   *  - RSE Map
   */
  listenUpdates() {
    // create extent for sample
    const point1 = transform(
      [103.76247707097092, 1.31374359831375],
      'EPSG:4326',
      'EPSG:3857'
    );
    const point2 = transform(
      [103.74439891906654, 1.3366087357246292],
      'EPSG:4326',
      'EPSG:3857'
    );
    const sampleExtent = boundingExtent([point1, point2]);

    // client-side simulator listener
    this.updateSubscription = this.updates$?.subscribe(messageData => {
      const { type, data } = messageData;
      console.log('Tracking Updates: new message: ', messageData);
      switch (type) {
        case SIM_EVENT_TYPE.CALLSIGN: {
          const {
            incidentNo,
            callSign,
            vehicleNumber,
            callSignStatus,
            ehHospital,
            dateDispatch,
            dateStatus,
            location,
            station,
            status,
            dateClose,
          } = data;

          if (data?.incidentNo) {
            // const incidentData = {
            //   incidentNo,
            //   callSign,
            //   vehicleNumber,
            //   callSignStatus,
            //   hospital: ehHospital,
            //   dateDispatch,
            //   dateStatus,
            // };
            const incidentData: TrackingObdList = {
              incidentNo,
              callSign,
              callSignStatus,
              vehicleNumber,
              destination: ehHospital,
              dateDispatch,
              dateStatus,
              dateClosed: dateClose,
            };
            // this.incidentUpdateSource.next(incidentData);
            this.incidentObdUpdateSource.next(incidentData);
          }

          const transformedObdData: TrackingObdMap = {
            location,
            callSign,
            destination: ehHospital,
            incidentNo,
            vehicleNumber,
            operationStatus: status,
            fireStation: station,
            // division
          };

          this.ambulanceUpdateSource.next(transformedObdData);
          break;
        }
        case SIM_EVENT_TYPE.CREATE: {
          const {
            incidentNo,
            location,
            priority,
            incidentType,
            incidentStation,
            type: dataType,
          } = data;
          const transformedData: TrackingIncidentList = {
            incidentNo,
            incidentLocation: location,
            incidentPriority: priority,
            incidentType,
            incidentStation,
            dateCreated: moment().utcOffset(environment.timezone).toISOString(),
          };
          this.incidentUpdateSource.next({
            ...transformedData,
            type: dataType,
          });
          break;
        }
        case SIM_EVENT_TYPE.CLOSE: {
          const { incidentNo } = data;
          const incidentData = {
            incidentNo,
            dateClosed: moment().utcOffset(environment.timezone).toISOString(),
          };
          this.incidentUpdateSource.next(incidentData);
          // this.incidentObdUpdateSource.next(incidentData);
          break;
        }
        case SIM_EVENT_TYPE.RSE_ACTIVATION: {
          const { rse, ambulance } = data;
          const { junctionActivations, incidentNo } = ambulance;
          const incidentData = {
            incidentNo,
            junctionActivation: {
              ...junctionActivations[0],
              dateActivated: moment().toISOString(),
            },
          };

          const {
            vehicleNumber,
            callSign,
            ehHospital,
            station,
            location,
            status,
          } = ambulance;

          const ambulanceData = {
            vehicleNumber,
            callSign,
            destination: ehHospital,
            fireStation: station,
            location,
            operationStatus: status,
            incidentNo,
            // division:
          };

          this.rseActivation.next(rse);
          this.ambulanceUpdateSource.next(ambulanceData);
          // this.incidentUpdateSource.next(incidentData);
          this.incidentObdUpdateSource.next(incidentData);
          break;
        }
        case SIM_EVENT_TYPE.ZOOM:
          this.mapInstance.getView().fit(sampleExtent, {
            size: this.mapInstance.getSize(),
            maxZoom: 16,
            padding: [100, 100, 100, 100],
            duration: 1000,
          });
          break;
        default:
          console.log('Invalid type: ', type);
      }
    });

    // Websocket Service Message Listener
    this.messages$ = this.wsService.on().subscribe(returnData => {
      const { message, type } = returnData || {};
      try {
        const parsedMessage = JSON.parse(message);
        // console.log('MQTT Websocket message: ', type, parsedMessage);
        if (type === 'obd-map') {
          this.ambulanceUpdateSource.next(parsedMessage);
        }

        if (type === 'incident-list') {
          this.incidentUpdateSource.next(parsedMessage);
        }

        if (type === 'obd-list') {
          this.incidentObdUpdateSource.next(parsedMessage);
        }

        if (type === 'rse-map') {
          this.rseUpdateSource.next(parsedMessage);
        }
      } catch (e) {
        console.log('Failed to parse message', type, message);
        console.error(e);
      }
    });

    // monitor connection status. resend topic subscription on connect
    this.status$ = this.wsService.status.subscribe(connected => {
      if (connected) {
        // re-run REST API fetch on reconnect?
        this.wsService.send({
          action: 'subscribe',
          type: 'tracking',
        });
      }
    });

    // init subscription
    this.wsService.send({
      action: 'subscribe',
      type: 'tracking',
    });
  }

  unsubscribeWs() {
    // send unsubscription request
    if (this.messages$) {
      this.status$?.unsubscribe();
      this.messages$?.unsubscribe();
      this.status$ = undefined;
      this.messages$ = undefined;
      this.wsService.send({
        action: 'unsubscribe',
      });
    }

    if (this.socket$) {
      const wsMessage = {
        action: 'unsubscribe',
      };

      const jsonMessage = JSON.stringify(wsMessage);

      this.socket$.next(jsonMessage);

      this.socketSubscription?.unsubscribe();
      this.socket$?.unsubscribe();
      this.socket$ = null;
      this.socketSubscription = undefined;
      // clearInterval(this.wsPingIntervalId);
      // this.wsPingIntervalId = undefined;
    }
  }

  fakeDataIndex = -1;
  fakeDataInterval = undefined;
  runFakeData(newIndex) {
    // console.log(
    //   'Simulation# ',
    //   newIndex,
    //   '/',
    //   normalEventTimeline.length,
    //   ': ',
    //   normalEventTimeline[newIndex]
    // );
    // if (normalEventTimeline[newIndex]) {
    //   this.fakeDataIndex = newIndex;
    // } else {
    //   this.fakeDataIndex = 0;
    // }
    // this.updates$.next(normalEventTimeline[this.fakeDataIndex]);
  }

  onFakePrevious() {
    if (this.fakeDataIndex <= 0) {
      this.fakeDataIndex = 0;
      return;
    }
    // this.fakeDataIndex = this.fakeDataIndex - 2;
    this.runFakeData(this.fakeDataIndex - 1);
  }

  onFakeNext() {
    // console.log('fake', this.fakeDataIndex);
    this.runFakeData(this.fakeDataIndex + 1);
  }

  onFakePlay() {
    const me = this;
    if (!this.fakeDataInterval) {
      this.fakeDataInterval = setInterval(() => {
        me.onFakeNext();
      }, 1000);
    } else {
      clearInterval(this.fakeDataInterval);
      this.fakeDataInterval = undefined;
    }
  }

  onFakeInit() {
    this.fakeDataIndex = -1;
    this.fakeDataInterval = undefined;
    this.onFakeNext();
    this.onFakeNext();

    // run simulator until junction activation
    // for (let i = 0; i < 27; i++) {
    //   this.onFakeNext();
    // }
    // this.onFakeNext();
    // this.onFakeNext();
    // this.onFakeNext();

    // add temp incident data
    const finishedIncident =
      this.trackingSimulationService.getFinishedIncident();
    const failedIncident = this.trackingSimulationService.getFailedIncident();
    this.incidentUpdateSource.next(finishedIncident);
    this.incidentUpdateSource.next(failedIncident);
  }

  dummyDataCall() {
    this.rseInterval = setInterval(() => {
      const hospitalData = this.rseData[0];
      const junctionData = hospitalData[getRandomInt(0, 3)];
      const { id: junctionId, children }: { id: string; children: any } =
        junctionData;
      // const rseChildren: [] = children.filter((child) => child.type === 'rse') ?? [];
      const rseChildren: [] = children ?? [];
      const {
        id: rseId,
        type,
        directions,
      } = rseChildren[getRandomInt(0, rseChildren.length - 1)] ?? [];

      if (type === 'rse') {
        const directionKeys = Object.keys(directions) ?? [];
        const currDirection =
          directionKeys[Math.floor(Math.random() * directionKeys?.length)];
        const arrowForms: [] = directions[currDirection];
        const currArrowForm =
          arrowForms[Math.floor(Math.random() * arrowForms.length)];
        const rseStatusMessage: RseStatusObject = {
          junctionId,
          rseId,
          timeStamp: moment().format('DD-MM-YYYY HH:mm'),
          direction: +currDirection,
          arrow: currArrowForm,
          status: statusArray[getRandomInt(1, 2)],
          type,
        };

        this.rseActivation.next(rseStatusMessage);
        setTimeout(() => {
          const modifiedRseStatusMessage = {
            ...rseStatusMessage,
            direction: undefined,
            timeStamp: moment().format('DD-MM-YYYY HH:mm'),
            status: statusArray[0],
            type,
          };
          this.rseActivation.next(modifiedRseStatusMessage);
        }, 3000);
      } else {
        const rseStatusMessage: RseStatusObject = {
          junctionId,
          rseId,
          timeStamp: moment().format('DD-MM-YYYY HH:mm'),
          status: statusArray[2],
          type,
        };
        this.rseActivation.next(rseStatusMessage);
        setTimeout(() => {
          const modifiedRseStatusMessage = {
            ...rseStatusMessage,
            timeStamp: moment().format('DD-MM-YYYY HH:mm'),
            status: statusArray[0],
            type,
          };
          this.rseActivation.next(modifiedRseStatusMessage);
        }, 3000);
      }
    }, 3000);
  }

  shouldDisplayMarker = true;

  toggleDisplayMarker() {
    this.shouldDisplayMarker = !this.shouldDisplayMarker;
  }

  fireStationMarkers = [];
  fireStationSource: VectorSource<any>;
  fireStationVectorLayer: VectorLayer<any>;
  createFireStationLayer(): VectorLayer<any> {
    if (this.fireStationVectorLayer) return this.fireStationVectorLayer;
    this.fireStationMarkers = fireStationList.map(fireStation => {
      const { name, id, location, division, hq, scdfHq } = fireStation || {};
      const { latitude, longitude } = location || {};
      const firestationIcon = 'firestation-pin';
      const otherProperties = { division, hq, scdfHq, fireStationCode: id };
      const newFirestationMarker = this.addMarker(
        OVERLAY_LAYER + '-' + FIRESTATION_LAYER + '-' + id,
        name,
        longitude,
        latitude,
        ColorData.eRed,
        firestationIcon,
        0.85,
        otherProperties
      );
      return newFirestationMarker;
    });

    const me = this;
    // const shadowStyle = this.getMarkerShadowStyle();

    this.fireStationSource = new VectorSource({
      features: this.fireStationMarkers,
    });

    this.fireStationVectorLayer = new VectorLayer({
      source: me.fireStationSource,
      style: function (feature, resolution) {
        return [me.getStyle(feature.get('name'), feature)];
      },
      renderBuffer: 2,
      className:
        VECTOR_LAYER +
        '-' +
        OVERLAY_LAYER +
        '-' +
        FIRESTATION_LAYER +
        '-markers',
    });
    this.fireStationVectorLayer.setZIndex(4);
    this.fireStationVectorLayer.setVisible(true);
    return this.fireStationVectorLayer;
  }

  hospitalMarkers = [];
  hospitalSource: VectorSource<any>;
  hospitalVectorLayer: VectorLayer<any>;
  createHospitalLayer(): VectorLayer<any> {
    if (this.hospitalVectorLayer) return this.hospitalVectorLayer;
    this.hospitalMarkers = hospitalList.map(hospital => {
      const { name, location, hospitalCode, code } = hospital || {};
      const { latitude, longitude } = location || {};
      const hospitalIcon = 'hospital-pin';
      const otherProperties = { hospitalCode, code };
      const newHospitalMarker = this.addMarker(
        OVERLAY_LAYER + '-' + HOSPITAL_LAYER + '-' + name,
        name,
        longitude,
        latitude,
        ColorData.eCobaltBlue,
        hospitalIcon,
        0.85,
        otherProperties
      );
      return newHospitalMarker;
    });

    const me = this;
    // const shadowStyle = this.getMarkerShadowStyle();

    this.hospitalSource = new VectorSource({
      features: me.hospitalMarkers,
    });

    this.hospitalVectorLayer = new VectorLayer({
      source: me.hospitalSource,
      style: function (feature, resolution) {
        return [me.getStyle(feature.get('name'), feature)];
      },
      renderBuffer: 2,
      className:
        VECTOR_LAYER + '-' + OVERLAY_LAYER + '-' + HOSPITAL_LAYER + '-markers',
    });
    this.hospitalVectorLayer.setZIndex(4);
    this.hospitalVectorLayer.setVisible(true);
    return this.hospitalVectorLayer;
  }

  styleCache = {};
  createStyle(id, feature) {
    const icon = feature.get('icon');
    const scale = feature.get('scale');
    // const description = feature.get('description');
    // const color = feature.get('color');
    // const textStyle = CreateCommonTextStyle(description, color);
    const newStyle = new Style({
      image: this.getIconStyle(icon, scale),
      // text: textStyle
    });
    this.styleCache[id] = newStyle;
  }

  getStyle(id, feature) {
    if (!this.styleCache[id]) {
      this.createStyle(id, feature);
    }
    return this.styleCache[id];
  }

  addMarker(
    name: string,
    description: string,
    longitude: number,
    latitude: number,
    color: ColorData,
    icon: string,
    scale = 0.75,
    properties?: object
  ) {
    const marker = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')
      ),
      name,
      description,
      color,
      icon,
      scale,
      ...properties,
    });
    return marker;
  }

  iconStyles = {};
  getIconStyle(iconName: string, scale: number, iconProps?: IconOptions) {
    if (!this.iconStyles[iconName]) {
      const imageStyle = createCommonIconStyle(iconName, scale, iconProps);
      this.iconStyles[iconName] = imageStyle;
    }
    return this.iconStyles[iconName];
  }

  createTrafficLayer(): OlTileLayer<any> {
    const trafficServiceUrl =
      'https://traffic.arcgis.com/arcgis/rest/services/World/Traffic/MapServer/export';
    const apiKey = environment.esriApiKey;

    const trafficTileLayer = new OlTileLayer({
      source: new TileArcGISRest({
        // attributions: 'Traffic Data: Esri, HERE,',
        url: trafficServiceUrl,
        params: {
          token: apiKey,
          DPI: 224,
          size: '384,384',
          layers: 'show:41',
        },
        projection: 'EPSG:3857',
      }),
      opacity: 0.5,
      className: 'traffic-layer',
    });

    trafficTileLayer.setZIndex(2);
    trafficTileLayer.setVisible(false);

    return trafficTileLayer;
  }

  shadowStyle;

  getMarkerShadowStyle() {
    if (!this.shadowStyle) {
      this.shadowStyle = new Style({
        image: new Icon({
          src: ICONS_PATH + 'shadow.svg',
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          opacity: 1,
          scale: 0.85,
        }),
        zIndex: -10,
      });
    }
    return this.shadowStyle;
  }

  // mock notification
  isMockRseOffline = false;
  mockRseOffline() {
    this.isMockRseOffline = !this.isMockRseOffline;
    const rseActivationObject: RseStatusObject = {
      junctionId: 'NTFGH_J1',
      rseId: 'NTFGH_J1_RSE2',
      timeStamp: moment().toISOString(),
      status: this.isMockRseOffline ? 'offline' : 'online',
      type: 'rse',
    };
    this.rseActivation.next(rseActivationObject);
  }

  isMockObdOffline = false;
  lastMockObdStatus = '';
  mockObdOffline(vehicleNumber, ambulanceFeature) {
    this.isMockObdOffline = !this.isMockObdOffline;
    if (ambulanceFeature) {
      const featureProperties = ambulanceFeature.getProperties();
      const {
        latitude,
        longitude,
        rotation,
        callSign,
        destination,
        fireStation,
        incidentNo,
        status,
      } = featureProperties || {};
      const location = { latitude, longitude, rotation };
      console.log(vehicleNumber, ambulanceFeature, featureProperties);
      const obdObject = {
        vehicleNumber,
        location,
        callSign,
        destination,
        fireStation,
        incidentNo,
        operationStatus: this.isMockObdOffline
          ? 'offline'
          : this.lastMockObdStatus,
      };
      this.lastMockObdStatus = status;
      this.ambulanceUpdateSource.next(obdObject);
    }
  }

  mockAcesOffline() {
    const date = moment().toISOString();
    this.commsNotificationService.acesOfflineAlert(date);
  }

  cleanup() {
    // console.log('cleanup');
    // clearInterval(this.rseInterval);
    this.updateSubscription?.unsubscribe();
    this.updateSubscription = undefined;
    this.commsNotificationService.closeSnackbar();
    this.unsubscribeWs();
  }
}
