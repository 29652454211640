import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AlertTypes } from 'app/shared/model/alert-types';

import { fuseAnimations } from '@fuse/animations';
import { Moment } from 'moment';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
  animations: fuseAnimations
})
export class NotificationAlertComponent {
  @Output() disposeEvent = new EventEmitter();
  @Input() type!: AlertTypes;
  @Input() title!: string;
  @Input() message!: string;
  @Input() date!: Moment;
  @Input() autoHide!: boolean;

  showAlert: boolean = true;

  ngOnInit() {
    if (this.autoHide) {
      setTimeout(() => {
        this.hide();
      }, 5000);
    }
  }

  getIcon() {
    switch(this.type) {
      case AlertTypes.Error:
        return 'error';
      case AlertTypes.Info:
        return 'info';
      case AlertTypes.Success:
        return 'check_circle';
      case AlertTypes.Warning:
        return 'warning';
      default:
        return 'info';
    }
  }

  hide() {
    this.showAlert = false;
    setTimeout(() => {
      this.disposeEvent.emit();
    }, 300)
  }
}
