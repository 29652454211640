import { Component, HostBinding, Input } from '@angular/core';
import { AuthService } from '@fuse/services/auth.service';

@Component({
  selector: 'fuse-nav-horizontal-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class FuseNavHorizontalItemComponent {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: any;
  isAllowed = true;

  /**
   * Constructor
   */
  constructor(private _authService: AuthService) {}

  ngOnInit() {
    this.isAllowed = this.isRoleAllowed();
  }

  /**
   * check if accessible by role
   */
  isRoleAllowed() {
    const userRole = this._authService.getRole();
    if (this.item.roles && this.item.roles.indexOf(userRole) === -1) {
      return false;
    }
    return true;
  }
}
