import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector   : 'fuse-inform-dialog',
    templateUrl: './inform-dialog.component.html',
    styleUrls  : ['./inform-dialog.component.scss']
})
export class FuseInformDialogComponent
{
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseInformDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseInformDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dataDialog: any
    )
    {
        // console.log(this.dataDialog);
    }

}
