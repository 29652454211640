import {
  Component,
  ElementRef,
  Injectable,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CommonService } from '@fuse/services/common.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
// import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { environment } from 'environments/environment';
// import { AuthService } from '@fuse/services/auth.service';
// import * as moment from 'moment';
// import { WebSocketSubject } from 'rxjs/webSocket';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddAccountComponent } from './add-account/add-account.component';
import { MassImportComponent } from './mass-import/mass-import.component';
import { HttpErrorResponse } from '@angular/common/http';

// import * as fakeUserManagementData from 'app/shared/fakeData/userManagement.json';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  //gsmCellId, gsmAreaCode, activeGSMOperator, vehicleIdentificationNumber not existing
  displayedColumns: string[] = [
    'nric',
    'displayName',
    'userEmail',
    'designation',
    'agency',
    'isAccountDisabled',
    'roleName',
    'action',
  ];

  allAccountClone;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {FuseSidebarService} _fuseSidebarService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseSidebarService: FuseSidebarService,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {
    this._commonService.onIdle();
  }

  // public ngAfterViewInit() {
  // }

  ngOnInit() {
    // console.log('on init');
    this.initAccount();
  }

  initAccount() {
    const urlAction = environment.apiUserUri + '/v4/getAllUsers';

    this._commonService.commonPostFullUrlAction(urlAction, '').subscribe(
      accountsResp => {
        const allAccount = [];
        const accountData = {
          // ...(fakeUserManagementData as any).default,
          ...accountsResp,
        };
        // const accountData = accountsResp;
        for (const key in accountData) {
          if (Object.prototype.hasOwnProperty.call(accountData, key)) {
            const element = accountData[key];

            element.forEach(account => {
              if (!account.agency) {
                account.agency = '';
              }
              allAccount.push(account);
            });
          }
        }
        // console.log(allAccount);
        this.dataSource.data = allAccount;
        this.allAccountClone = JSON.parse(JSON.stringify(allAccount));

        this.dataSource.paginator = this.paginator;
        //   this._snackBar.dismiss();
      },
      (errorResp: HttpErrorResponse) => {
        this._commonService.errorHandling(errorResp);
      }
    );
  }

  onFilter(event: Event) {
    // console.log(data);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onRefresh() {
    // console.log('refresh');
    this.initAccount();
    this._snackBar.open('Account list refreshed.', null, {
      duration: 5000,
    });
  }

  onResetPassword(dataElem) {
    // console.log(dataElem);
    const dialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      data: {
        header: 'Reset Password',
        message: 'Are you sure to reset?',
        textConfirm: 'Confirm',
        textCancel: 'Cancel',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // console.log('reset password');
        const bodyData = {
          email: dataElem.userEmail,
        };

        this._snackBar.open('Password reset successful.', null, {
          duration: 5000,
        });

        // const urlAction = environment.apiBaseUri + '/v1/resetPassword';
        // this._commonService
        //   .commonPostFullUrlAction(urlAction, bodyData)
        //   .subscribe(
        //     resp => {
        //       // console.log(resp);
        //       this._snackBar.open(
        //         resp.response + ', ' + ' changed to default password.',
        //         null,
        //         {
        //           duration: 5000,
        //         }
        //       );
        //     },
        //     (errorResp: HttpErrorResponse) => {
        //       this._commonService.errorHandling(errorResp);
        //     }
        //   );
      }
    });
  }

  onAddAccount(data = null) {
    // console.log('on add');
    // console.log('add account', data);

    const dialogData = {
      data: {
        accountData: data,
      },
    };

    const dialogRef = this._dialog.open(AddAccountComponent, {
      data: dialogData,
      disableClose: true,
      // height: '400px',
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // result.userEmail = result.email;
        console.log('result', result);
        const accountIndex = this.allAccountClone.findIndex(
          x => x.userEmail === result.userEmail
        );
        if (accountIndex < 0) {
          // place to front
          this.allAccountClone.unshift(result);
          // this.allAccountClone.push(result);
        } else {
          this.allAccountClone[accountIndex] = result;
        }

        this.dataSource.data = this.allAccountClone;
        this.allAccountClone = JSON.parse(JSON.stringify(this.allAccountClone));
      }
    });
  }

  onMassImport() {
    const dialogRef = this._dialog.open(MassImportComponent, {
      disableClose: true,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
        result.forEach(userAccount => {
          const accountIndex = this.allAccountClone.findIndex(
            x => x.userEmail === userAccount.userEmail
          );
          if (accountIndex < 0) {
            this.allAccountClone.unshift(userAccount);
          } else {
            this.allAccountClone[accountIndex] = userAccount;
          }
        });
        this.dataSource.data = this.allAccountClone;
        this.allAccountClone = JSON.parse(JSON.stringify(this.allAccountClone));
      }
    });
  }

  onCheckChange(isChecked, data, action) {
    const bodyData: any = {
      userName: data.userEmail,
    };

    let urlAction = '';
    if (action === 'enabled') {
      urlAction = environment.apiUserUri + '/v3/enableDisableUser';
      bodyData.userDisableFlag = isChecked;
      data.isAccountDisabled = isChecked;
    }

    // else if(action === 'locked') {
    //   urlAction = environment.apiBaseUri + 'users/v1/lockUnlockUser';
    //   bodyData.userLockFlag = isChecked;
    // }
    // else if(action === 'expired') {
    //   this._snackBar.open('Credential Expiration API not available.', null, {
    //     duration: 5000,
    //   });
    //   return false;
    // }
    else if (action === 'activated') {
      urlAction = environment.apiBaseUri + '/v1/activateDeactivateUser';
      bodyData.deactivateFlag = isChecked;
      data.isAccountDeactivated = isChecked;
    }

    // let urlAction = environment.apiBaseUri + '/v1/resetPassword';
    // this._commonService.commonPostFullUrlAction(urlAction, bodyData).subscribe(resp => {
    this._commonService.commonPostFullUrlAction(urlAction, bodyData).subscribe(
      accounts => {
        const isValid = accounts.response.includes('Cannot process');
        // this.dataSource = accounts;
        if (isValid) {
          this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));
          this._snackBar.open(accounts.response + ', data reset', null, {
            duration: 5000,
          });
        } else {
          const accountIndex = this.allAccountClone.findIndex(
            x => x.userEmail === data.userEmail
          );
          this.allAccountClone[accountIndex] = data;

          this.dataSource.data = this.allAccountClone;
          this.allAccountClone = JSON.parse(
            JSON.stringify(this.allAccountClone)
          );

          this._snackBar.open(accounts.response, null, {
            duration: 5000,
          });
        }
      },
      (errorResp: HttpErrorResponse) => {
        this.dataSource = JSON.parse(JSON.stringify(this.allAccountClone));
        this._commonService.errorHandling(errorResp);
      }
    );
  }

  onDelete(data) {
    const dialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      data: {
        header: 'Delete Account',
        message: 'Are you sure to delete?',
        textConfirm: 'Confirm',
        textCancel: 'Cancel',
      },
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // Actual Deletion API
        // var urlAction =  environment.apiUserUri + '/v1/deleteUser';
        // var bodyData = {
        //   userName: data.userName
        // }
        // this._commonService.commonDeleteFullUrlAction(urlAction, bodyData).subscribe(retData => {
        //   this._snackBar.open(retData.response, null, {
        //     duration: 5000,
        //   });

        //   let filtered = this.allAccountClone.filter(x=>x.userName !== data.userName)
        //   this.dataSource.data = filtered;
        // },
        // err => {
        //   console.log(err);
        //   this._snackBar.open(err.error.errorType + ', try again later.', null, {
        //     duration: 5000,
        //   });
        // })

        // Temporarily show "Success" always. "Mock Delete"
        this._snackBar.open('Deleted Successfully', null, {
          duration: 5000,
        });
        const filtered = this.allAccountClone.filter(
          x => x.userName !== data.userName
        );
        this.dataSource.data = filtered;
      }
    });
  }
}
