import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { momentFormat } from '@helpers';

const audioSource = 'assets/sounds/alert.ogg';

@Injectable({
  providedIn: 'root',
})
export class CommsNotificationService {
  constructor(private snackBar: MatSnackBar) {}

  obdOfflineAlert(
    vehicleNumber: string,
    incidentNumber: string,
    date: string,
    hasAction = true,
    actionMessage = 'Close'
  ): MatSnackBarRef<unknown> {
    const dateFormatted = momentFormat(date);
    let message = '';
    if (incidentNumber) {
      message = `OBD: ${vehicleNumber} assigned to ${incidentNumber} has gone offline at ${dateFormatted}.`;
    } else {
      message = `OBD: ${vehicleNumber} has gone offline at ${dateFormatted}.`;
    }
    return this.playAlert(message, hasAction, actionMessage);
  }

  rseOfflineAlert(
    rseId: string,
    area: string,
    date?: string
  ): MatSnackBarRef<unknown> {
    const dateFormatted = momentFormat(date);
    let message = '';
    if (!area) {
      message = `RSE: ${rseId} has gone offline at ${dateFormatted}.`;
    } else {
      message = `RSE: ${rseId} at ${area} has gone offline at ${dateFormatted}.`;
    }
    return this.playAlert(message, true, 'Check');
  }

  acesOfflineAlert(date: string): MatSnackBarRef<unknown> {
    const dateFormatted = momentFormat(date);
    const message = `TPS system has lost communication with ACES at ${dateFormatted}.`;
    return this.playAlert(message, true, 'Close', 10000);
  }

  playAlert(
    message,
    hasAction = false,
    actionMessage = 'Close',
    duration = 5000
  ): MatSnackBarRef<unknown> {
    const audioAlert = new Audio(audioSource);
    audioAlert.play();

    return this.snackBar.open(message, hasAction ? actionMessage : undefined, {
      panelClass: 'custom-snack-bar-panel-error',
      duration,
    });
  }

  closeSnackbar() {
    this.snackBar.dismiss();
  }
}
