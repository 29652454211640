import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AuthService } from 'src/app/component/shared/services/auth.service';
// import { CommonService } from 'src/app/component/shared/services/common.service';
// import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@fuse/services/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-change-passowrd',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  // @Input() data:any;
  // @Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
  // @Output() okayAction: EventEmitter<any> = new EventEmitter<any>();
  // username: string = localStorage.getItem('displayname');
  // scope: string = localStorage.getItem('scope');
  // sipNumber: string = localStorage.getItem('sipNumber');
  isOldHide = true;
  isHide = true;
  isConfirmHide = true;
  form: UntypedFormGroup;
  // showDetails: boolean = true;
  isPasswordMatch = true;
  message = '';
  isPasswordStr = false;
  isSubmitLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private _formBuilder: UntypedFormBuilder,
    public cdRef: ChangeDetectorRef,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    });

    // let timer = 3;
    // let interval = setInterval(() => {
    //   if (timer != 0) {
    //     timer--
    //     console.log(timer);
    //     this.message = `Redirecting in ${timer}s...`;
    //   } else {
    //     clearInterval(interval)
    //     this.message = null
    //   }
    // }, 1000);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onCloseModal(): void {
    this.dialogRef.close();
  }

  onStrengthChanged(strength: number) {
    if (strength === 100) {
      this.isPasswordStr = true;
      //   this.form.get('password').setErrors({valid:true});
    } else {
      this.isPasswordStr = false;
    }
  }

  onPasswordConfirm(data) {
    const password = this.form.value.password;
    const confirmData = this.form.get('confirmPassword').value;
    if (password === confirmData) {
      this.isPasswordMatch = true;
      this.form.get('confirmPassword').setErrors(null);
    } else {
      this.isPasswordMatch = false;
      this.form.get('confirmPassword').setErrors({ valid: false });
    }
    // isPasswordMatch
  }

  onSave() {
    // var isMatch = this.onPasswordConfirm(this.form.get('confirmPassword').value);
    if (this.form.valid) {
      this.isSubmitLoading = true;
      const bodyData = {
        userName: localStorage.getItem('email'),
        oldPassword: this.form.get('oldPassword').value,
        newPassword: this.form.get('password').value,
      };
      // this._snackBar.dismiss(); //P@ssw0rd123

      const urlAction = environment.apiUserUri + '/v2/changePassword';
      this._commonService
        .commonPostFullUrlAction(urlAction, bodyData)
        .subscribe(
          resp => {
            const isExist =
              resp.response?.includes('does not match') ||
              resp.response?.includes('Cannot process request');
            if (!isExist) {
              // this.form.reset();
              this.dialogRef.close();
              setTimeout(() => {
                this.isSubmitLoading = false;
                this._commonService.authLogout();
              }, 200);
            } else {
              const { response } = resp || {};
              const defaultReason = `There was an error updating your password. Please try again`;
              const reason =
                response?.length > 0
                  ? 'Unable to change password. ' +
                      response.split('Reason: ')?.[1] ?? defaultReason
                  : defaultReason;

              this._snackBar.open(reason, null, {
                duration: 5000,
                panelClass: 'custom-snack-bar-panel-error',
              });
              this.isSubmitLoading = false;
              this.cdRef.detectChanges();
            }
          },
          (errorResp: HttpErrorResponse) => {
            const errorData = this._commonService.errorHandling(errorResp);
            this._snackBar.open(errorData, null, {
              duration: 5000,
              panelClass: 'custom-snack-bar-panel-error',
            });
            this.isSubmitLoading = false;
            this.cdRef.detectChanges();
          }
        );
    }
  }
}
