<div class="add-role-group">
  <div mat-dialog-title class="dialog-title">
    <h2 >{{title}}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  

  <mat-dialog-content>
    
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutAlign="space-around stretch" >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="inline-label">System Role</span>
          <mat-form-field appearance="outline" class="flex">
            <!-- <mat-label>System Role</mat-label> -->
            <mat-select formControlName="role" [(ngModel)]="selectedRole">
              <mat-option *ngFor="let role of systemRoleList" [value]="role.roleName">
                {{role.roleName | titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="inline-label">IAMS User ID</span>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="flex">
            <mat-form-field appearance="outline">
              <input matInput formControlName="userId">
            </mat-form-field>
            <div>
              <button 
                mat-button 
                class="mat-raised-button ml-8 field-margin" 
                [disabled]="running || isEdit"
                type="button" 
                (click)="onRetrieveUserIamsData()"
              >
                Retrieve
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="iamsData" fxLayout="column">
          <div fxLayout="row" class="field-margin">
            <div class="inline-label">Name</div>
            <div class="flex">{{ iamsData.displayName }}</div>
          </div>
          <div fxLayout="row" class="field-margin">
            <div class="inline-label">Rank / Order / Designation</div>
            <div class="flex">{{ iamsData.designation }}</div>
          </div>
          <div fxLayout="row" class="field-margin">
            <div class="inline-label">Organization / Division / Unit</div>
            <div class="flex">{{ iamsData.agency }}</div>
          </div>
          <div fxLayout="row" class="field-margin">
            <div class="inline-label">Email Address</div>
            <div class="flex">{{ iamsData.userEmail }}</div>
          </div>
        </div>

      </div>
    </form>
  </mat-dialog-content>
  
  <mat-dialog-actions class="pt-241" fxLayout="row" fxLayoutAlign="end center">
    <button class="mat-raised-button" mat-dialog-close>Cancel</button>
    <button 
      mat-button
      color="primary"
      class="mat-raised-button mat-primary ml-8" 
      (click)="onSaveRole()" 
      [disabled]="running || !iamsData || !selectedRole"
    >
      {{ isEdit ? "Update" : "Save" }}
    </button>
  </mat-dialog-actions>
</div>