<div>
    <div mat-dialog-title class="dialog-title">
        <h2 >Mass User Creation</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <ng-container [ngSwitch]="step">
            <ng-container *ngSwitchCase="0">
                <div class="upload-file-container active-container" (click)="fileInput.click()" appDragDrop (onFileDropped)="onFileDropped($event)">
                    <input hidden type="file" #fileInput id="fileDrop" (change)="fileBrowseHandler($event.target.files)" accept=".csv">
                    <div class="drag-drop">
                        <div class="centered">Drag and drop CSV file here</div>
                        <div class="centered">or click to upload a file.</div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="upload-file-container disabled-container">
                    <div>
                        <div class="centered" *ngIf="running; else runningDone">File Uploaded. Processing.</div>
                        <ng-template #runningDone>
                            <div class="centered">{{ records }} user records found.</div>
                            <div class="centered">No errors found and ready for import.</div>
                        </ng-template>
                        
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <div class="upload-file-container disabled-container">
                    <div>
                        <div class="centered">Importing records.</div>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class="upload-file-container disabled-container" *ngSwitchDefault fxLayout="column">
                    <div class="centered" *ngIf="records">{{ records }} user records found.</div>
                    <div class="centered">Errors found. Please correct and re-validate.</div>
                    <div class="centered" *ngIf="showErrors">
                        <ul *ngFor="let csvError of csvErrors">
                            <li>{{ csvError }}</li>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </mat-dialog-content>
    
    <mat-dialog-actions class="pt-241" fxLayout="row" fxLayoutAlign="end center">
        <button class="mat-raised-button" mat-dialog-close [disabled]="running || step === 2">Cancel</button>
        <button 
            mat-button
            color="primary"
            class="mat-raised-button mat-primary ml-8"
            [disabled]="!csvFile || running"
            *ngIf="step === 1 && !running"
            (click)="onImport()"
        >
            Import
        </button>
        <button 
            mat-button
            color="primary"
            class="mat-raised-button mat-primary ml-8"
            [disabled]="running"
            *ngIf="step > 2 && !showErrors"
            (click)="onShowErrors()"
        >
        Show Errors
        </button>
        <button 
            mat-button
            color="primary"
            class="mat-raised-button mat-primary ml-8"
            [disabled]="!csvFile || running"
            *ngIf="step > 2 && showErrors"
            (click)="onRetry()"
        >
            Retry
        </button>
    </mat-dialog-actions>
</div>