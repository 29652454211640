<div class="change-password-group">
  <h1 mat-dialog-title>{{ dataDialog.header }}</h1>
  <div mat-dialog-content class="body-content">
    <form [formGroup]="form" autocomplete="new-password">
      <!-- <mat-form-field appearance="outline"> -->
      <!-- <input matInput placeholder="Old Password" [type]="isOldHide ? 'password' : 'text'" formControlName="oldPassword" required>
        <mat-icon class="pointer" matSuffix (click)="isOldHide = !isOldHide">{{isOldHide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
      <!-- </mat-form-field> -->

      <mat-form-field appearance="outline" style="width: 100%" class="mt-8">
        <mat-pass-toggle-visibility
          #oldToggle
          matSuffix></mat-pass-toggle-visibility>
        <mat-label>Old Password</mat-label>
        <input
          matInput
          #oldPassword
          autocomplete="current-password"
          formControlName="oldPassword"
          [type]="oldToggle.type"
          name="password" />
      </mat-form-field>

      <!-- <mat-form-field appearance="outline">
        <input matInput placeholder="Password" [type]="isHide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon class="pointer" matSuffix (click)="isHide = !isHide">{{isHide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Confirm Password" [type]="isConfirmHide ? 'password' : 'text'" formControlName="confirmPassword" required>
        <mat-icon class="pointer" matSuffix (click)="isConfirmHide = !isConfirmHide">{{isConfirmHide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field> -->

      <div>
        <!--password input filed-->
        <mat-form-field
          appearance="outline"
          style="width: 100%"
          [color]="passwordComponent7.color">
          <mat-pass-toggle-visibility
            #toggle
            matSuffix></mat-pass-toggle-visibility>
          <mat-label>New Password</mat-label>
          <input
            matInput
            #password
            name="new-password"
            autocomplete="new-password"
            formControlName="password"
            (input)="onPasswordConfirm($event.target.value)"
            [type]="toggle.type"
            maxlength="30" />
          <mat-hint align="end" aria-live="polite">
            {{ password.value.length }} / {{ passwordComponent7.max }}
          </mat-hint>
        </mat-form-field>
      </div>
      <!--@angular-material-extensions/password-strength's main component-->
      <mat-password-strength
        #passwordComponent7
        class="green"
        (onStrengthChanged)="onStrengthChanged($event)"
        [password]="password.value"></mat-password-strength>

      <div class="mt-8">
        <!-- <mat-form-field appearance="outline" style="width: 100%">
          <mat-pass-toggle-visibility #conFirmPassword matSuffix></mat-pass-toggle-visibility>
          <mat-label>Confirm Password</mat-label>
          <input matInput #conFirmPassword formControlName="confirmPassword"
                  [type]="conFirmPassword.type"
                  placeholder="Confirm Password" name="confirmPassword">
        </mat-form-field> -->

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-pass-toggle-visibility
            #confirmToggle
            matSuffix></mat-pass-toggle-visibility>
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            autocomplete="new-password"
            formControlName="confirmPassword"
            (input)="onPasswordConfirm($event.target.value)"
            [type]="confirmToggle.type"
            name="confirm-password" />
          <mat-error *ngIf="!isPasswordMatch">Passwords do not match</mat-error>
        </mat-form-field>
      </div>

      <mat-slide-toggle [checked]="false" #toggle7>
        Show Password Requirements
      </mat-slide-toggle>

      <!--Password's strength info-->
      <mat-password-strength-info
        class="mt-8"
        *ngIf="toggle7.checked"
        [passwordComponent]="passwordComponent7"></mat-password-strength-info>

      <div class="mt-8">
        <small>You will need to login again after changing passwords</small>
      </div>
    </form>
    <div *ngIf="message" snack-bar-timer>{{ message }}</div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button
      mat-button
      (click)="onCloseModal()"
      cdkFocusInitial
      [disabled]="isSubmitLoading">
      Close
    </button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="onSave()"
      class="float-right"
      [disabled]="!form.valid || !isPasswordStr || isSubmitLoading">
      Submit
    </button>
  </div>
</div>
