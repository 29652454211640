import type * as CSS from 'csstype';

export const SUCCESS_BADGE: CSS.Properties = {
  color: '#fff',
  backgroundColor: '#0FFF5080',
};
export const WARN_BADGE: CSS.Properties = {
  color: '#fff',
  backgroundColor: '#FF313180',
};
export const DEFAULT_BADGE: CSS.Properties = {
  color: '#fff',
  backgroundColor: '#88888880',
};

export function getBadgeStyle(status: number | string) {
  switch (status) {
    case 1:
    case '1':
    case 'online':
      return SUCCESS_BADGE;
    case 2:
    case '2':
    case 'offline':
      return WARN_BADGE;
    default:
      return DEFAULT_BADGE;
  }
}
