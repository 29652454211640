import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

// import {
//   simData1,
//   simData1Positions,
//   simFailedIncident,
//   simFinishedIncident,
//   simIncidentDetails,
// } from 'app/shared/fakeData/trackingSimData';

@Injectable({
  providedIn: 'root',
})
export class TrackingSimulationService {
  trackingIndex = 0;

  private obdSubject: Subject<any> = new Subject<any>();
  obd$: Observable<any> = this.obdSubject.asObservable();

  trackingInterval;
  init() {
    const simData1Positions = [
      {
        latitude: 1.339665,
        longitude: 103.74622,
        heading: 0,
      },
    ];
    this.trackingInterval = setInterval(() => {
      if (this.trackingIndex >= simData1Positions.length) {
        this.trackingIndex = 0;
      } else {
        this.trackingIndex += 1;
      }
      this.obdSubject.next('next');
    }, 5000);
  }

  formatIncident(data) {
    const {
      incidentNo,
      callSign,
      callSignStatus,
      vehicleNumber,
      hospital,
      location,
      priority,
      incidentType,
      incidentStation,
      junctionActivations,
      dateCreated,
      dateStatus,
      dateDispatch,
      dateClose,
    } = data;
    const incidentData = {
      incidentNo,
      callSign,
      callSignStatus,
      vehicleNumber,
      destination: hospital,
      incidentLocation: location,
      incidentPriority: priority,
      incidentType,
      incidentStation,
      junctionActivation: junctionActivations?.[0] ?? undefined,
      dateCreated,
      dateStatus,
      dateDispatch,
      dateClosed: dateClose,
    };
    return incidentData;
  }

  getIncidentDetails(incidentNo: string) {
    // console.log('detail sim', incidentNo);
    // if (simIncidentDetails[incidentNo]) {
    //   return simIncidentDetails[incidentNo];
    // }
    return undefined;
  }

  getFinishedIncident() {
    const finishedIncidentFormattedData = {};
    // this.formatIncident(simFinishedIncident);

    return finishedIncidentFormattedData;
  }

  getFailedIncident() {
    // const failedFormattedData = this.formatIncident(simFailedIncident);
    const failedFormattedData = {};
    return failedFormattedData;
  }
}
