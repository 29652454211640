import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import {
  Observable,
  Observer,
  Subject,
  SubscriptionLike,
  interval,
} from 'rxjs';
import { distinctUntilChanged, map, share, takeWhile } from 'rxjs/operators';

export interface IWebsocketService {
  on<T>(event: string): Observable<T>;
  send(event: string, data: any): void;
  status: Observable<boolean>;
}

export interface WebSocketConfig {
  url: string;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

// based on https://github.com/AlexDaSoul/angular-websocket-starter
// modified to just use JSON strings as messages

@Injectable({
  providedIn: 'root',
})
export class WebsocketService implements OnDestroy {
  private config: WebSocketSubjectConfig<any>;

  private websocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;

  private reconnection$: Observable<number>;
  private websocket$: WebSocketSubject<any>;
  private connection$: Observer<boolean>;
  private wsMessages$: Subject<any>;

  private reconnectInterval = 5000;
  private reconnectAttempts = 10;
  private isConnected: boolean;

  public status: Observable<boolean>;

  constructor() {
    this.wsMessages$ = new Subject<any>();
    this.config = {
      url: `${environment.nodeUrlWs}`,
      // serializer: msg => JSON.stringify(msg),
      closeObserver: {
        next: () => {
          console.log('Websocket disconnected');
          this.websocket$ = null;
          this.connection$.next(false);
        },
      },
      openObserver: {
        next: () => {
          console.log('Websocket connection established');
          this.connection$.next(true);
          // send subscription request
          // const wsMessage = {
          //   action: 'subscribe',
          //   type: 'tracking',
          // };

          // const jsonMessage = JSON.stringify(wsMessage);

          // this.websocket$.next(jsonMessage);
        },
      },
    };

    // connection status
    this.status = new Observable<boolean>(observer => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    // run reconnect if not connection
    this.statusSub = this.status.subscribe(isConnected => {
      this.isConnected = isConnected;

      if (
        !this.reconnection$ &&
        typeof isConnected === 'boolean' &&
        !isConnected
      ) {
        this.reconnect();
      }
    });

    this.websocketSub = this.wsMessages$.subscribe({
      error: (error: ErrorEvent) => console.error('WebSocket error!', error),
    });

    this.connect();
  }

  ngOnDestroy() {
    this.websocketSub.unsubscribe();
    this.statusSub.unsubscribe();
  }

  /*
   * connect to WebSocked
   * */
  private connect(): void {
    this.websocket$ = new WebSocketSubject(this.config);

    this.websocket$.subscribe(
      message => {
        // console.log('ws message', message);
        this.wsMessages$.next(message);
      },
      (error: Event) => {
        if (!this.websocket$) {
          // run reconnect if errors
          this.reconnect();
        }
      }
    );
  }

  /*
   * reconnect if not connecting or errors
   * */
  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile(
        (v, index) => index < this.reconnectAttempts && !this.websocket$
      )
    );

    this.reconnection$.subscribe({
      next: () => this.connect(),
      error: e => console.error(e),
      complete: () => {
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (!this.websocket$) {
          this.wsMessages$.complete();
          this.connection$.complete();
        }
      },
    });
  }

  /*
   * on message event
   * */
  public on<T>(): Observable<any> {
    return this.wsMessages$.asObservable();
  }

  /*
   * on message to server
   * */
  public send(data: any = {}): void {
    if (this.isConnected) {
      this.websocket$.next(<any>JSON.stringify(data));
    } else {
      console.error('Websocket Service: Send error - ', data);
    }
  }
}
